/* Copyright */
import { DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { DateSchedule, Weekday, WEEKDAYS, WeekdaySchedule } from "../../../client/devices/SpeedLimitSignHW/SpeedLimitSignHWState";
import { SIDES, SignDirection } from "../../../client/devices/SpeedLimitSignHW/SpeedLimitSignHWStateProperties";
import { translations } from "../../../generated/translationHelper";
import { getDynamicTranslation } from "../../../locales/localizationUtils";
import { Maybe } from "../../../types/aliases";
import CustomButton from "../../ui/custom-button";
import DropdownSelection from "../../ui/dropdown-selection";

export enum SchedulingMode {
  Weekday = "weekday",
  Date = "date"
}

interface Props {
  mode: SchedulingMode;
  default?: WeekdaySchedule | DateSchedule;
  onDelete?: (schedule: WeekdaySchedule | DateSchedule) => void;
  onEdit?: (oldSchedule: WeekdaySchedule | DateSchedule, newSchedule: WeekdaySchedule | DateSchedule) => void;
  onAdd?: (schedule: WeekdaySchedule | DateSchedule) => void;
  onClose?: () => void;
}

export default function ScheduleEditor(props: Props): JSX.Element {

  const getDefaultWeekday = (): Maybe<Weekday> => {
    return props.default?.kind === "Weekday" ? props.default.weekday : undefined;
  };

  const getDefaultDate = (): Maybe<string> => {
    return props.default?.kind === "Date" ? props.default.date : undefined;
  };

  const [weekday, setWeekday] = useState<Maybe<Weekday>>(getDefaultWeekday());
  const [date, setDate] = useState<Maybe<string>>(getDefaultDate());
  const [time, setTime] = useState<Maybe<string>>(props.default?.time);
  const [side, setSide] = useState<Maybe<SignDirection>>(props.default?.side);

  const appendSeconds = (time: string): string => {
    return time.length === 5 ? time + ":00" : time;
  };

  const getSubmittedSchedule = (): DateSchedule | WeekdaySchedule => {
    if (!time || !side) {
      throw new Error("Missing scheduling details!");
    }

    if (props.mode === SchedulingMode.Date) {
      if (!date) {
        throw new Error("Missing scheduling details!");
      }
      return {
        kind: "Date",
        date,
        time,
        side,
      };
    } else {
      if (!weekday) {
        throw new Error("Missing scheduling details!");
      }
      return {
        kind: "Weekday",
        weekday,
        time,
        side,
      };
    }
  };

  const handleSubmit = (): void => {
    if (props.default) props.onEdit?.(props.default, getSubmittedSchedule());
    else props.onAdd?.(getSubmittedSchedule());
  };

  const handleDelete = (): void => {
    if (props.default) props.onDelete?.(props.default);
  };

  const isDateValid = (): boolean => {
    return !!date && !!date.match(/^(0\d|1\d|2\d|3[01])\.(0\d|1[0-2])$/) && moment(date, "DD.MM").isValid();
  };

  const isTimeValid = (): boolean => {
    return !!time && !!time.match(/^(0\d|1\d|2[0-3])(:[0-5]\d){2}$/);
  };

  const isSubmitDisabled = (): boolean => {
    return props.mode === SchedulingMode.Date ? !date || !isDateValid() || !time || !side : !weekday || !time || !side;
  };

  return (
    <Fragment>
      <DialogTitle>
        <Grid item={true} container={true} justifyContent="center">
          <Typography>{translations.status.texts.turnScheduleEditor()}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item={true} container={true} justifyContent="center" spacing={2}>
          <Grid item={true}>
            <DropdownSelection
              label={translations.common.texts.side()}
              variant="outlined"
              selectionList={SIDES.map((side) => ({ key: side, label: side }))}
              currentSelection={side ? SIDES.indexOf(side) : undefined}
              onSelect={(selection): void => setSide(selection !== undefined ? SIDES[selection] : undefined)}
              error={!side}
            />
          </Grid>
          { props.mode === SchedulingMode.Weekday &&
          <Grid item={true}>
            <DropdownSelection
              label={translations.common.texts.day()}
              variant="outlined"
              selectionList={WEEKDAYS.map((day) => ({ key: day, label: getDynamicTranslation(translations.common.data, day) }))}
              currentSelection={weekday ? WEEKDAYS.indexOf(weekday) : undefined}
              onSelect={(selection): void => setWeekday(selection !== undefined ? WEEKDAYS[selection] : undefined)}
              error={!weekday}
            />
          </Grid>
          }
          { props.mode === SchedulingMode.Date &&
          <Grid item={true}>
            <TextField
              label={translations.common.texts.date()}
              type="text"
              variant="outlined"
              error={!isDateValid()}
              value={date ?? ""}
              onChange={(event): void => setDate(event.currentTarget.value)}
              helperText={translations.status.texts.formatInDDMM()}
            />
          </Grid>
          }
          <Grid item={true}>
            <TextField
              label={translations.common.texts.time()}
              type="time"
              variant="outlined"
              error={!isTimeValid()}
              value={time ?? ""}
              onChange={(event): void => setTime(event.currentTarget.value ? appendSeconds(event.currentTarget.value) : undefined)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item={true} container={true} justifyContent="center" spacing={2}>
          <Grid item={true}>
            <CustomButton 
              variant="contained"
              color="secondary"
              disabled={isSubmitDisabled()}
              onClick={handleSubmit}
            >
              {translations.common.buttons.submit()}
            </CustomButton>
          </Grid>
          { !!props.default && 
          <Grid item={true}>
            <CustomButton
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              {translations.common.buttons.delete()}
            </CustomButton>
          </Grid>
          }
          <Grid item={true}>
            <CustomButton
              variant="contained"
              color="secondary"
              onClick={props.onClose}
            >
              {translations.common.buttons.close()}
            </CustomButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Fragment>
  );
}
