/* Copyright */
import CapsuleIconBlue from "../../../assets/Cyclope-1-Blue-24x24.svg";
import CapsuleIconYellow from "../../../assets/Cyclope-1-Yellow-24x24.svg";
import CapsuleIconRed from "../../../assets/Cyclope-1-Red-24x24.svg";
import { CapsuleHWState } from "./CapsuleHWState";
import { CapsuleHWStateProperties } from "./CapsuleHWStateProperties";
import { ResourceStatus } from "../../ResourceStatus";
import { BatteryOperated, RailroadDevice } from "../RailroadDevice/RailroadDevice";
import { CapsuleHWData } from "./CapsuleHWData";
import { Maybe } from "../../../types/aliases";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";
import TimePeriod from "../../../data/utils/TimePeriod";

export class CapsuleHW extends RailroadDevice<CapsuleHWData, CapsuleHWState> {
  public static type = "Capsule";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(CapsuleHW.type, backend, params);
  }

  public getIcon(): string {
    return CapsuleIconBlue;
  }

  public async getStatusIcon(timePeriod: TimePeriod): Promise<string> {
    const status = await this.getStatus(timePeriod);

    switch (status) {
      case ResourceStatus.WARNING:
        return CapsuleIconYellow;
      case ResourceStatus.ERROR:
        return CapsuleIconRed;
      case ResourceStatus.NONE:
      default:
        return this.getIcon();
    }
  }

  public getBatteryPercentage(): Maybe<number> {
    if (this.getBattery() === BatteryOperated.True) {
      const maxCapacity = this.getState()?.batteryMaxCapacity;
      const batteryCapacity = this.getState()?.batteryCapacity;
      return batteryCapacity != null && maxCapacity != null ? Math.floor(batteryCapacity / maxCapacity * 100) : undefined;
    }
  }

  public createState(timestamp: number, reported?: Partial<CapsuleHWStateProperties>, desired?: Partial<CapsuleHWStateProperties>): CapsuleHWState {
    return new CapsuleHWState(this.getId(), new CapsuleHWStateProperties(reported ?? {}), new CapsuleHWStateProperties(desired ?? {}), timestamp);
  }

  public static instanceOf(value: unknown): value is CapsuleHW {
    return value instanceof CapsuleHW;
  }
}
