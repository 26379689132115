/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { CognitoUser } from "@aws-amplify/auth";
import Amplify, { Auth } from "aws-amplify";
import AWS, { CognitoIdentity } from "aws-sdk";
import awsconfig from "../../aws-config";
import { Maybe } from "../../types/aliases";
import { isValidLanguageCode, SupportedLanguageCode } from "../../locales/localizationUtils";

Amplify.configure(awsconfig);

interface GetOpenIdParams {
  IdentityId: string;
  Logins: { [key: string]: string };
}

const ORG_ID_PREFIX = "ORG/";
const USER_ID_PREFIX = "USER/";
// the custom claims are set by preTokenGenerationHook in users-service (but many vtl files depend on these values)
const GRANTS_CLAIM = "custom:policies";
const HOME_CLAIM = "custom:home";
const ORGANIZATIONS_CLAIM = "custom:orgs";
const USER_ID_CLAIM = "sub";

export type UserGrants = {
  [organizationId: string]: string[] | undefined;
};

export interface UserClaims {
  userId: string;
  homeOrganizationId: string;
  uniqueParentOrganizations: string[];
  canSee: string[];
  grants: UserGrants;
}

interface UserAttribute {
  [key: string]: string;
}

/**
 * AuthenticatedUser
 * 
 * AuthenticatedUser contains additional keys which however exists even though the base class do not have them defined.
 * Definitions were created based on console log prints of the actual CognitoUser object so be cautios.
 */
export interface AuthenticatedUser extends CognitoUser {
  attributes: { 
    sub: string;
    given_name: string;
    family_name: string;
    email: string;
  };
  username: string;
  authenticationFlowType: string;
  pool: {
    userPoolId: string;
    clientId: string;
    client: {
      endpoint: string;
      fetchOptions: Record<string, unknown>;
    };
    advancedSecurityDataCollectionFlag: boolean;
    storage: {
      [key: string]: string;
    };
  };
  client: {
    endpoint: string;
    fetchOptions: Record<string, unknown>;
  };
  storage: {
    [key: string]: string;
  };
  keyPrefix: string;
  userDataKey: string;
  Session?: string;
  challengeName?: "NEW_PASSWORD_REQUIRED";
  challengeParam?: {
    userAttributes: {
      email: string;
    };
    requiredAttributes: [];
  };
  signInUserSession?: {
    idToken: {
      jwtToken: string;
      payload: {
        sub: string;
        "custom:policies": string;
        iss: string;
        "cognito:username": string;
        aud: string;
        event_id: string;
        token_use: string;
        "custom:home": string;
        auth_time: number;
        "custom:orgs": string;
        exp: number;
        iat: number;
        email: string;
      };
    };
    refreshToken: {
      token: string;
    };
    accessToken: {
      jwtToken: string;
      payload: {
        sub: string;
        event_id: string;
        token_use: string;
        scope: string;
        auth_time: number;
        iss: string;
        exp: number;
        iat: number;
        jti: string;
        client_id: string;
        username: string;
      };
    };
    clockDrift: number;
  };
}

// REFACTOR: Hard to unit test as dependencies are built in. Could use dependency injection.
// Also usage of static methods can make mocking hard in classes that use AuthWrapper.
export default class AuthWrapper {
  private static userClaims?: UserClaims;

  public static async logIn(email: string, password: string): Promise<AuthenticatedUser> {
    if (!password.length) {
      throw new Error("Empty password");
    }
    return Auth.signIn(email, password) as Promise<AuthenticatedUser>;
  }

  public static async logOut(): Promise<void> {
    await Auth.signOut();
    AuthWrapper.userClaims = undefined;
  }

  public static async forgotPassword(email: string): Promise<void> {
    await Auth.forgotPassword(email);
  }

  public static async checkCodeAndSubmitNewPassword(email: string, code: string, newPassword: string): Promise<void> {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  public static async completeNewPassword(user: AuthenticatedUser, password: string): Promise<AuthenticatedUser> {
    return Auth.completeNewPassword(user, password, {});
  }

  public static async isCurrentUserAuthenticated(bypassCache?: boolean): Promise<boolean> {
    try {
      const result = await this.getCurrentAuthenticatedUser(bypassCache);
      return result !== undefined;
    } catch {
      return false;
    }
  }

  public static async getCurrentAuthenticatedUser(bypassCache?: boolean): Promise<Maybe<AuthenticatedUser>> {
    return Auth.currentAuthenticatedUser({
      bypassCache: bypassCache ?? false,
    });
  }
  
  public static async getCurrentAuthenticatedUserClaims(): Promise<Maybe<UserClaims>> {
    if (!AuthWrapper.userClaims) {
      const claims = (await AuthWrapper.getCurrentAuthenticatedUser(false))
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.decodePayload();

      if (!claims) {
        console.error("Cannot retrieve claims for an unauthenticated user");
        return;
      }

      const rawUserId = claims[USER_ID_CLAIM];
      const organizations = (claims[ORGANIZATIONS_CLAIM] ? JSON.parse(claims[ORGANIZATIONS_CLAIM]) as string[] : [])
        // add organization prefix to IDs
        .map((organization) => ORG_ID_PREFIX + organization);
      const grantsRaw: UserGrants = claims[GRANTS_CLAIM] ? JSON.parse(claims[GRANTS_CLAIM]) : {};

      AuthWrapper.userClaims = {
        userId: USER_ID_PREFIX + rawUserId,
        homeOrganizationId: ORG_ID_PREFIX + claims[HOME_CLAIM],
        uniqueParentOrganizations: organizations,
        // user id in canSee value does not have user prefix, but organizations have organization prefix
        canSee: [rawUserId].concat(organizations),
        // add organization prefix to raw id keys
        grants: Object.entries(grantsRaw).reduce((acc, [key, value]) => {
          acc[ORG_ID_PREFIX + key] = value;
          return acc;
        }, {} as UserGrants),
      };
    }
    return AuthWrapper.userClaims;
  }

  public static async getCurrentAuthenticatedUsername(): Promise<string> {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    return loggedInUser.username;
  }

  public static async getGivenName(): Promise<Maybe<string>> {
    const loggedInUserAttributes = await Auth.currentUserInfo();
    return loggedInUserAttributes.attributes.given_name;
  }

  public static async getFamilyName(): Promise<Maybe<string>> {
    const loggedInUserAttributes = await Auth.currentUserInfo();
    return loggedInUserAttributes.attributes.family_name;
  }

  public static async getPhoneNumber(): Promise<Maybe<string>> {
    const loggedInUserAttributes = await Auth.currentUserInfo();
    return loggedInUserAttributes.attributes.phone_number;
  }

  public static async getLanguage(): Promise<Maybe<SupportedLanguageCode>> {
    const languageCode = ((await Auth.currentUserInfo())?.attributes?.["custom:language"] as Maybe<string>)?.toLowerCase();
    if (languageCode && isValidLanguageCode(languageCode)) return languageCode;
    if (languageCode) console.error(`Language code ${languageCode} is not supported`);
    return undefined;
  }

  public static async setName(firstname: string, lastname: string): Promise<void> {
    const attributes: UserAttribute[] = [
      { ["given_name"]: firstname },
      { ["family_name"]: lastname },
    ];
    return this.setAttributes(attributes);
  }

  public static async setPhoneNumber(number: string): Promise<void> {
    const attributes: UserAttribute[] = [{ ["phone_number"]: number }];
    return this.setAttributes(attributes);
  }

  public static async setLanguage(language: SupportedLanguageCode): Promise<void> {
    return this.setAttributes([{ ["custom:language"]: language }]);
  }

  private static async setAttributes(attributes: UserAttribute[]): Promise<void> {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(loggedInUser, Object.assign({}, ...attributes));
  }

  public static async submitNewPassword(oldPassword: string, newPassword: string): Promise<void> {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(loggedInUser, oldPassword, newPassword);
  }

  public static async getOpenIdToken(): Promise<Maybe<string>> {
    const currentSession = await Auth.currentSession();
    const cognitoAuthenticatedLoginsKey = `cognito-idp.${awsconfig.Auth.region}.amazonaws.com/${awsconfig.Auth.userPoolId}`;
    const cognitoAuthenticatedLogins = { [cognitoAuthenticatedLoginsKey]: currentSession.getIdToken().getJwtToken() };

    if (!awsconfig.Auth.identityPoolId) {
      console.error("No identity pool id available");
      return;
    }

    const getIdParams: CognitoIdentity.Types.GetIdInput = {
      IdentityPoolId: awsconfig.Auth.identityPoolId,
      Logins: cognitoAuthenticatedLogins,
    };

    if (!AWS.config.region) {
      AWS.config.update({
        region: awsconfig.Auth.region,
      });
    }

    const cognitoIdentity = new AWS.CognitoIdentity();

    try {
      const data: AWS.CognitoIdentity.GetIdResponse = await cognitoIdentity.getId(getIdParams).promise();

      if (!data.IdentityId) {
        console.error("No identity id available");
        return;
      }
      const getOpenIdParams: GetOpenIdParams = {
        IdentityId: data.IdentityId,
        Logins: cognitoAuthenticatedLogins,
      };
      const response: AWS.CognitoIdentity.GetOpenIdTokenResponse = await cognitoIdentity.getOpenIdToken(getOpenIdParams).promise();
      return response.Token;
    } catch (error) {
      console.error("getOpenIdToken", error);
    }
  }
}
