/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { Grid, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, Component, ReactNode } from "react";
import Loader from "../../ui/loader";
import { Maybe } from "../../../types/aliases";
import AuthWrapper from "../../../data/auth/AuthWrapper";
import CustomButton from "../../ui/custom-button";
import { changeLanguage } from "../../../locales/localisator";
import DropdownSelection from "../../ui/dropdown-selection";
import { SUPPORTED_LANGUAGES, SupportedLanguageCode } from "../../../locales/localizationUtils";
import { translations } from "../../../generated/translationHelper";
import { isError } from "../../../data/utils/ErrorUtils";

interface Props {
}

interface State {
  isLoading: boolean;
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  language?: SupportedLanguageCode;
  error?: string;
}

export default class AttributeForm extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  public componentDidMount(): void {
    this.getUserDetails();
  }

  private async getUserDetails(): Promise<void> {
    this.setState({ isLoading: true });

    Promise.allSettled([
      AuthWrapper.getGivenName(),
      AuthWrapper.getFamilyName(),
      AuthWrapper.getPhoneNumber(),
      AuthWrapper.getLanguage(),
    ]).then(results => {
      const firstname = AttributeForm.getPromiseResult(results[0]);
      const lastname = AttributeForm.getPromiseResult(results[1]);
      const phoneNumber = AttributeForm.getPromiseResult(results[2]);
      const language = AttributeForm.getPromiseResult(results[3]);
      this.setState({
        firstname,
        lastname,
        phoneNumber,
        language,
      });
    });
    this.setState({ isLoading: false });
  }

  private static getPromiseResult<TResult = string>(result: PromiseSettledResult<Maybe<TResult>>, fallbackResult?: TResult): Maybe<TResult> {
    if (result.status === "rejected") {
      console.error("Failed to settle", result.reason);
    } else {
      return result.value ?? fallbackResult;
    }
  }

  private handleNameSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.setName(this.state.firstname ?? "", this.state.lastname ?? "");
      this.setState({
        firstname: this.state.firstname,
        lastname: this.state.lastname,
      });
    } catch (error) {
      console.error("handleNameSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  private handlePhoneNumberSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.setPhoneNumber(this.state.phoneNumber ?? "");
    } catch (error) {
      console.error("handlePhoneNumberSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private handleChangeLanguageSubmit = async (): Promise<void> => {
    try {
      if (!this.state.language) {
        return console.error("Invalid language setting");
      }
      this.setState({ isLoading: true });
      await AuthWrapper.setLanguage(this.state.language);
      await changeLanguage(this.state.language);
    } catch (error) {
      console.error("handleChangeLanguageSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private handleErrorCode(code?: string): void {
    switch (code) {
      case "Attribute value for given_name must not be null":
      case "Attribute value for family_name must not be null":
        this.setErrorMessage(translations.user.texts.invalidName());
        break;
      case "Invalid phone number format.":
        this.setErrorMessage(translations.user.texts.invalidPhoneNumberFormat());
        break;
      case "Network error":
        this.setErrorMessage(translations.common.texts.networkError());
        break;
      default:
        this.setErrorMessage(translations.common.texts.unableToPerformAction());
        break;
    }
  }

  private setErrorMessage(error?: string): void {
    this.setState({ error });
  }
    
  private renderUserAttributeSubmitMessage(): ReactNode {
    if (this.state.error) {
      return (
        <Grid item={true} container={true} justifyContent="center">
          <span data-testid="user-attributes-error">{translations.common.texts.errorOccurred({ error: this.state.error })}</span>
        </Grid>
      );
    }
  }

  private renderNameInputs(): JSX.Element {
    const isEnabled = !!this.state.firstname || !!this.state.lastname;
    return (
      <Grid item={true} xs={12} sm={10} md={8}>
        <Grid item={true}>
          <TextField
            fullWidth={true}
            label={translations.user.inputs.firstname()}
            type="text"
            margin="normal"
            variant="outlined"
            inputProps={{ "data-testid": "first-name-field" }}
            value={this.state.firstname ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({ firstname: event.target.value, error: undefined });
            }}
          />
        </Grid>
        <Grid item={true}>
          <TextField
            fullWidth={true}
            label={translations.user.inputs.lastname()}
            type="text"
            margin="normal"
            variant="outlined"
            inputProps={{ "data-testid": "last-name-field" }}
            value={this.state.lastname ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({ lastname: event.target.value, error: undefined });
            }}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <CustomButton
            disabled={!isEnabled}
            variant="contained"
            color="secondary"
            onClick={this.handleNameSubmit}
            data-testid="change-name-button"
          >
            {translations.user.buttons.confirmNameChange()}
          </CustomButton>
        </Grid>
      </Grid>
    );
  }

  private renderPhoneNumberInputs(): JSX.Element {
    return (
      <Grid item={true} xs={12} sm={10} md={8}>
        <Grid item={true}>
          <TextField
            fullWidth={true}
            label={translations.user.inputs.phonenumber()}
            type="text"
            margin="normal"
            variant="outlined"
            inputProps={{ "data-testid": "phone-number-field" }}
            value={this.state.phoneNumber ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setErrorMessage(undefined);
              this.setState({ phoneNumber: event.target.value, error: undefined });
            }}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <CustomButton
            variant="contained"
            color="secondary"
            data-testid="change-phone-number-button"
            onClick={this.handlePhoneNumberSubmit}
          >
            {translations.user.buttons.updatePhoneNumber()}
          </CustomButton>
        </Grid>
      </Grid>
    );
  }

  private renderLocalisationInputs(): JSX.Element {
    const localisedLanguages = SUPPORTED_LANGUAGES.map((code) => ({ key: code, label: translations.user.languages[code]() }));
    return (
      <Grid item={true} xs={12} sm={10} md={8}>
        <Grid item={true}>
          <DropdownSelection
            selectionList={localisedLanguages}
            onSelect={(selection?: number): void => {
              this.setState({
                language: selection !== undefined ? SUPPORTED_LANGUAGES[selection] : undefined, error: undefined, 
              });
            }}
            currentSelection={SUPPORTED_LANGUAGES.findIndex((language) => language === this.state.language)}
            variant="outlined"
            fullWidth={true}
            margin="normal"
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <CustomButton
            disabled={!this.state.language}
            variant="contained"
            color="secondary"
            data-testid="change-language-button"
            onClick={this.handleChangeLanguageSubmit}
          >
            {translations.user.buttons.changeLanguage()}
          </CustomButton>
        </Grid>
      </Grid>
    );
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoading) {
      return <Grid item={true} container={true} justifyContent="center"><Loader /></Grid>;
    }
  }

  public render(): JSX.Element {
    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} container={true}>
          <Grid item={true} container={true} justifyContent="center">
            <Typography variant="h6" style={{ fontWeight: "bold" }}>{translations.user.texts.enterFirstnameAndLastname()}</Typography>
          </Grid>
          <Grid item={true} container={true} spacing={2} justifyContent="center">
            {this.renderNameInputs()}
          </Grid>
        </Grid>
        <Grid item={true} container={true}>
          <Grid item={true} container={true} justifyContent="center">
            <Typography variant="h6" style={{ fontWeight: "bold" }}>{translations.user.texts.enterPhoneNumber()}</Typography>
          </Grid>
          <Grid item={true} container={true} spacing={2} justifyContent="center">
            {this.renderPhoneNumberInputs()}
          </Grid>
        </Grid>
        <Grid item={true} container={true}>
          <Grid item={true} container={true} justifyContent="center">
            <Typography variant="h6" style={{ fontWeight: "bold" }}>{translations.user.texts.chooseLanguage()}</Typography>
          </Grid>
          <Grid item={true} container={true} spacing={2} justifyContent="center">
            {this.renderLocalisationInputs()}
          </Grid>
        </Grid>
        {this.renderLoader()}
        {this.renderUserAttributeSubmitMessage()}
      </Grid>
    );
  }
}
