/* Copyright */
import { Cell, Row, RowState } from "../components/ui/custom-table";
import EventSet from "../data/events/EventSet";
import { convertTimestampToString, DateTimeFormatTarget } from "../data/utils/Utils";
import { EventState, EventType, Event, EventSeverity } from "../generated/gqlEvents";
import { translations } from "../generated/translationHelper";
import { getDynamicTranslation } from "../locales/localizationUtils";

interface Timestamps {
  timestamp: string | number;
  updatedTimestamp?: string | number;
}

enum Order {
  ASC,
  DESC
}

/**
 * ServiceDataService provides data for the Service view to show in various tables.
 */
export default class ServiceDataService {

  /**
   * Get upcoming factory services.
   * @param eventSet Factory event set
   * @param factoryLabel Factory name
   * @param count Return count items
   * @return row array
   */
  public static getUpcomingFactoryServices(eventSet: EventSet, count?: number): Row<Event>[] {
    const services: Row<Event>[] = (eventSet.getData() ?? [])
      .filter((event) => ServiceDataService.isServiceEvent(event, EventState.Active))
      .sort((eventA, eventB) => ServiceDataService.compareTimestamps(
        { timestamp: eventA.timestamp, updatedTimestamp: eventA.updatedTimestamp ?? undefined },
        { timestamp: eventB.timestamp, updatedTimestamp: eventB.updatedTimestamp ?? undefined },
        Order.ASC,
      ))
      .map((event) => ({
        id: event.factoryId + event.timestamp,
        cells: [
          {
            id: "crossingName",
            value: event.crossingName ?? "N/A",
          },
          {
            id: "timestamp",
            value: convertTimestampToString(ServiceDataService.getNewerTimestamp(event.timestamp, event.updatedTimestamp ?? undefined), DateTimeFormatTarget.UpcomingServicesTable),
          },
          {
            id: "description",
            value: event.description ?? "",
          },
        ],
        source: event,
        state: ServiceDataService.resolveRowState(event.link ?? []),
      }));
    console.log("Upcoming services", services);
    return count ? services.slice(undefined, count) : services;
  }

  /**
   * Get upcoming factory services header
   * @returns cell array
   */
  public static getUpcomingFactoryServicesHeader(): Cell<Event>[] {
    return [
      {
        id: "crossingName",
        value: translations.common.texts.unit(),
      },
      {
        id: "timestamp",
        value: translations.common.texts.date(),
      },
      {
        id: "description",
        value: translations.service.texts.service(),
      },
    ];
  }

  /**
   * Get past factory services.
   * @param eventSet Factory event set
   * @param factoryLabel Factory name
   * @param count Return count items
   * @return row array
   */
  public static getPastFactoryServices(eventSet: EventSet, count?: number): Row<Event>[] {
    const services: Row<Event>[] = (eventSet.getData() ?? [])
      .filter((event) => ServiceDataService.isServiceEvent(event, EventState.Inactive))
      .sort((eventA, eventB) => ServiceDataService.compareTimestamps(
        { timestamp: eventA.timestamp, updatedTimestamp: eventA.updatedTimestamp ?? undefined },
        { timestamp: eventB.timestamp, updatedTimestamp: eventB.updatedTimestamp ?? undefined },
        Order.DESC,
      ))
      .map((event): Row<Event> => ({
        id: event.factoryId + event.timestamp,
        cells: [
          {
            id: "crossingName",
            value: event.crossingName ?? "N/A",
          },
          {
            id: "timestamp",
            value: convertTimestampToString(ServiceDataService.getNewerTimestamp(event.timestamp, event.updatedTimestamp ?? undefined), DateTimeFormatTarget.PastServicesTable),
          },
          {
            id: "submittedBy",
            value: event.submittedBy ?? "",
          },
          {
            id: "description",
            value: event.description ?? "",
          },
        ],
        source: event,
      }));
    console.log("Past services", services);
    return count ? services.slice(undefined, count) : services;
  }

  /**
   * Get upcoming factory services header
   * @returns cell array
   */
  public static getPastFactoryServicesHeader(): Cell<Event>[] {
    return [
      {
        id: "crossingName",
        value: translations.common.texts.unit(),
      },
      {
        id: "timestamp",
        value: translations.common.texts.date(),
      },
      {
        id: "submittedBy",
        value: translations.service.texts.servicedBy(),
      },
      {
        id: "description",
        value: translations.service.texts.service(),
      },
    ];
  }

  /**
   * Get upcoming crossing services.
   * @param eventSet Crossing event set
   * @param count Return count items
   * @returns row array
   */
  public static getUpcomingCrossingServices(eventSet: EventSet, count?: number): Row<Event>[] {
    const services: Row<Event>[] = (eventSet.getData() ?? [])
      .filter((event) => ServiceDataService.isServiceEvent(event, EventState.Active))
      .sort((eventA, eventB) => ServiceDataService.compareTimestamps(
        { timestamp: eventA.timestamp, updatedTimestamp: eventA.updatedTimestamp ?? undefined },
        { timestamp: eventB.timestamp, updatedTimestamp: eventB.updatedTimestamp ?? undefined },
        Order.ASC,
      ))
      .map((event): Row<Event> => ({
        id: event.crossingId + event.timestamp,
        cells: [
          {
            id: "timestamp",
            value: convertTimestampToString(ServiceDataService.getNewerTimestamp(event.timestamp, event.updatedTimestamp ?? undefined), DateTimeFormatTarget.UpcomingServicesTable),
          },
          {
            id: "description",
            value: event.description ?? "",
          },
        ],
        source: event,
        state: ServiceDataService.resolveRowState(event.link ?? []),
      }));
    console.log("Upcoming services", services);
    return count ? services.slice(undefined, count) : services;
  }

  /**
   * Get upcoming crossing services header
   * @returns cell array
   */
  public static getUpcomingCrossingServicesHeader(): Cell<Event>[] {
    return [
      {
        id: "timestamp",
        value: translations.common.texts.due(),
      },
      {
        id: "description",
        value: translations.common.texts.service(),
      },
    ];
  }

  /**
   * Get past crossing services.
   * @param eventSet Crossing event set
   * @param crossingLabel Crossing name
   * @param count Return count items
   * @returns row array
   */
  public static getPastCrossingServices(eventSet: EventSet, count?: number): Row<Event>[] {
    const services: Row<Event>[] = (eventSet.getData() ?? [])
      .filter((event) => ServiceDataService.isServiceEvent(event, EventState.Inactive))
      .sort((eventA, eventB) => ServiceDataService.compareTimestamps(
        { timestamp: eventA.timestamp, updatedTimestamp: eventA.updatedTimestamp ?? undefined },
        { timestamp: eventB.timestamp, updatedTimestamp: eventB.updatedTimestamp ?? undefined },
        Order.DESC,
      ))
      .map((event): Row<Event> => ({
        id: event.crossingId + event.timestamp,
        cells: [
          {
            id: "deviceName",
            value: event.deviceName ?? event.crossingName ?? "N/A",
          },
          {
            id: "timestamp",
            value: convertTimestampToString(ServiceDataService.getNewerTimestamp(event.timestamp, event.updatedTimestamp ?? undefined), DateTimeFormatTarget.PastServicesTable),
          },
          {
            id: "submittedBy",
            value: event.submittedBy ?? "",
          },
          {
            id: "description",
            value: event.description ?? "",
          },
        ],
        source: event,
      }));
    console.log("Past services", services);
    return count ? services.slice(undefined, count) : services;
  }

  /**
   * Get past crossing services header
   * @returns cell array
   */
  public static getPastCrossingServicesHeader(): Cell<Event>[] {
    return [
      {
        id: "crossingName",
        value: translations.common.texts.unit(),
      },
      {
        id: "timestamp",
        value: translations.common.texts.date(),
      },
      {
        id: "submittedBy",
        value: translations.service.texts.servicedBy(),
      },
      {
        id: "description",
        value: translations.service.texts.service(),
      },
    ];
  }

  /**
   * Get upcoming device services.
   * @param eventSet Device event set
   * @param crossingEventSet Crossing event set
   * @param count Return count items
   * @return row array
   */
  public static getUpcomingDeviceServices(eventSet: EventSet, crossingEventSet?: EventSet, count?: number): Row<Event>[] {
    const crossingServices: Event[] = (crossingEventSet?.getData() ?? [])
      .filter((event) => ServiceDataService.isServiceEvent(event, EventState.Active) && event.deviceId === "NONE");
    const deviceServices: Event[] = (eventSet.getData() ?? [])
      .filter((event) => ServiceDataService.isServiceEvent(event, EventState.Active));
    const services = deviceServices.concat(crossingServices)
      .sort((eventA, eventB) => ServiceDataService.compareTimestamps(
        { timestamp: eventA.timestamp, updatedTimestamp: eventA.updatedTimestamp ?? undefined },
        { timestamp: eventB.timestamp, updatedTimestamp: eventB.updatedTimestamp ?? undefined },
        Order.ASC,
      ))
      .map((event): Row<Event> => ({
        id: event.deviceId + event.timestamp,
        cells: [
          {
            id: "timestamp",
            value: convertTimestampToString(ServiceDataService.getNewerTimestamp(event.timestamp, event.updatedTimestamp ?? undefined), DateTimeFormatTarget.UpcomingServicesTable),
          },
          {
            id: "description",
            value: event.description ?? "",
          },
        ],
        source: event,
        state: ServiceDataService.resolveRowState(event.link ?? []),
      }));
    console.log("Upcoming services", services);
    return count ? services.slice(undefined, count) : services;
  }

  /**
   * Get upcoming device services header
   * @returns cell array
   */
  public static getUpcomingDeviceServicesHeader(): Cell<Event>[] {
    return [
      {
        id: "timestamp",
        value: translations.common.texts.due(),
      },
      {
        id: "description",
        value: translations.service.texts.service(),
      },
    ];
  }

  /**
   * Get past device services.
   * @param eventSet Device event set
   * @param crossingEventSet Crossing event set
   * @param count Return count items
   */
  public static getPastDeviceServices(eventSet: EventSet, crossingEventSet?: EventSet, count?: number): Row<Event>[] {
    const crossingServices: Event[] = (crossingEventSet?.getData() ?? [])
      .filter((event) => ServiceDataService.isServiceEvent(event, EventState.Inactive) && event.deviceId === "NONE");
    const deviceServices: Event[] = (eventSet.getData() ?? [])
      .filter((event) => ServiceDataService.isServiceEvent(event, EventState.Inactive));
    const services = deviceServices.concat(crossingServices)
      .sort((eventA, eventB) => ServiceDataService.compareTimestamps(
        { timestamp: eventA.timestamp, updatedTimestamp: eventA.updatedTimestamp ?? undefined },
        { timestamp: eventB.timestamp, updatedTimestamp: eventB.updatedTimestamp ?? undefined },
        Order.DESC,
      ))
      .map((event): Row<Event> => ({
        id: event.deviceId + event.timestamp,
        cells: [
          {
            id: "timestamp",
            value: convertTimestampToString(ServiceDataService.getNewerTimestamp(event.timestamp, event.updatedTimestamp ?? undefined), DateTimeFormatTarget.PastServicesTable),
          },
          {
            id: "submittedBy",
            value: event.submittedBy ?? "",
          },
          {
            id: "description",
            value: event.description ?? "",
          },
        ],
        source: event,
      }));
    console.log("Past services", services);
    return count ? services.slice(undefined, count) : services;
  }

  /**
   * Get past device services header
   * @returns cell array
   */
  public static getPastDeviceServicesHeader(): Cell<Event>[] {
    return [
      {
        id: "timestamp",
        value: translations.common.texts.date(),
      },
      {
        id: "submittedBy",
        value: translations.service.texts.servicedBy(),
      },
      {
        id: "description",
        value: translations.service.texts.service(),
      },
    ];
  }

  /**
   * Get linked diagnostic issues.
   * @param linkedEvents Event[]
   */
  public static getLinkedDiagnosticIssues(linkedEvents: Event[]): Row<Event>[] {
    return linkedEvents.map((event: Event) => ({
      id: event.deviceId + event.timestamp,
      cells: [
        {
          id: "timestamp",
          value: convertTimestampToString(ServiceDataService.getNewerTimestamp(event.timestamp, event.updatedTimestamp ?? undefined), DateTimeFormatTarget.PastServicesTable),
        },
        {
          id: "severity",
          value: getDynamicTranslation(translations.common.data, event.severity ?? "LOW"),
        },
        {
          id: "result",
          value: getDynamicTranslation(translations.common.data, event.result ?? ""),
        },
      ],
    }));
  }

  /**
   * Get diagnostic issues header for linked events.
   * @returns Cell<Event>[]
   */
  public static getLinkedDiagnosticIssuesHeader(): Cell<Event>[] {
    return [
      {
        id: "timestamp",
        value: translations.common.texts.date(),
      },
      {
        id: "severity",
        value: translations.service.texts.severity(),
      },
      {
        id: "result",
        value: translations.service.texts.errorCode(),
      },
    ];
  }

  private static resolveRowState(linkedEvents: Event[]): RowState {
    const errorEvents = linkedEvents.filter((event: Event) => event.severity === EventSeverity.High);
    const warningEvents = linkedEvents.filter((event: Event) => event.severity === EventSeverity.Medium);

    if (errorEvents.length) {
      return "ERROR";
    } else if (warningEvents.length) {
      return "WARNING";
    }
    return "NONE";
  }

  private static isServiceEvent(event: Event, active?: EventState): boolean {
    if (active) {
      return event.type === EventType.Service && event.eventState === active;
    }
    return event.type === EventType.Service;
  }

  private static compareTimestamps(a: Timestamps, b: Timestamps, order: Order = Order.ASC): -1 | 1 {
    const timestampA = ServiceDataService.getNewerTimestamp(a.timestamp, a.updatedTimestamp);
    const timestampB = ServiceDataService.getNewerTimestamp(b.timestamp, b.updatedTimestamp);

    if (timestampA >= timestampB) {
      return order === Order.ASC ? 1 : -1;
    }
    return order === Order.ASC ? -1 : 1;
  }

  private static getNewerTimestamp(a?: number | string, b?: number | string): number {
    if (!a && !b) {
      throw new Error("Both arguments can not be undefined");
    }

    if (a || b) {
      return Number(a ?? b);
    }
    return Number(a) > Number(b) ? Number(a) : Number(b);
  }
}
