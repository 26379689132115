/* Copyright */
import { Nullable } from "../../../types/aliases";
import { DeviceStateProperties } from "../../../data/device/DeviceStateProperties";

export class CapsuleHWStateProperties extends DeviceStateProperties {
  public readonly batteryCapacity: Nullable<number>;
  public readonly batteryMaxCapacity: Nullable<number>;
  public readonly batteryAverageCurrent: Nullable<number>;
  public readonly batteryCurrentPerSec: Nullable<number>;

  public constructor(deviceProperties: Partial<CapsuleHWStateProperties>) {
    super(deviceProperties);
    this.batteryCapacity = deviceProperties.batteryCapacity ?? null;
    this.batteryMaxCapacity = deviceProperties.batteryMaxCapacity ?? null;
    this.batteryAverageCurrent = deviceProperties.batteryAverageCurrent ?? null;
    this.batteryCurrentPerSec = deviceProperties.batteryCurrentPerSec ?? null;
  }
}
