/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthWrapper from "../../data/auth/AuthWrapper";
import OtaManager from "../../data/ota/OtaManager";
import EventsRepository from "../../data/events/EventsRepository";
import AdminView from "../../components/admin-view";
import StatusView from "../../components/status-view";
import UserView from "../../components/user-view";
import ServiceView from "../../components/service-view";
import Paths from "../Paths";

interface Props {}

export default class ContentController extends Component<Props> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (await AuthWrapper.isCurrentUserAuthenticated()) {
      await EventsRepository.instance.init();
      await OtaManager.getInstance().init();
    }

    this.setState({ isLoading: false });
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.uninit();
    OtaManager.getInstance().uninit();
  }

  public render(): JSX.Element {
    return (
      <section className="body-container col-sm-12 col-xsm-12">
        <Switch>
          <Route
            exact={true}
            path={[`${Paths.STATUS}/:type?/:id?`, `${Paths.STATUS}/:type?/:id?/:resources`]}
          >
            <StatusView />
          </Route>
          <Route
            exact={true}
            path={[`${Paths.SERVICE}/:type?/:id?`, `${Paths.SERVICE}/:type?/:id?/:resources`]}
          >
            <ServiceView />
          </Route>
          <Route
            exact={true}
            path={Paths.USER}
          >
            <UserView />
          </Route>
          <Route
            exact={true} 
            path={Paths.ADMIN}
          >
            <AdminView />
          </Route>
          <Redirect to={Paths.STATUS} />
        </Switch>
      </section>
    );
  }
}
