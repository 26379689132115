/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Device, { DeviceObserver } from "../../../data/device/Device";
import React, { Component, ReactNode } from "react";
import { ReferenceHWState } from "../../../client/devices/ReferenceHW/ReferenceHWState";
import moment from "moment";
import { DateTimeFormatTarget, getDateTimeFormat, getDisplayName } from "../../../data/utils/Utils";
import ReactTooltip from "react-tooltip";
import DeviceSettingsButton from "../../device-settings/device-settings-button";
import LocationIcon from "../../ui/location-icon";
import ClientProperties from "../../../data/clientSpecific/ClientProperties";
import { getPowerSupplyStateIcon, getSignalStrengthIcon } from "../helpers/icon-factory";
import DeviceGroup from "../../../data/device/DeviceGroup";
import DeviceState from "../../../data/device/DeviceState";
import { DeviceStateProperties } from "../../../data/device/DeviceStateProperties";
import { translations } from "../../../generated/translationHelper";

const TOOLTIP_DELAY_MS = 400;

interface Props {
  device: Device;
  editMode?: boolean;
  parentGroup?: DeviceGroup;
  selected?: boolean;
  onDeviceSelect?: (device?: Device) => void;
  "data-testid"?: string;
}

interface State {
  deviceState?: DeviceState<DeviceStateProperties>;
}

export default class DraggableDeviceItem extends Component<Props, State> implements DeviceObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceState: props.device.getState(),
    };
  }

  public async componentDidMount(): Promise<void> {
    this.props.device.addObserver(this);
  }

  public componentWillUnmount(): void {
    this.props.device.removeObserver(this);
  }

  private handleClick = (): void => {
    if (this.props.onDeviceSelect) {
      this.props.onDeviceSelect(this.props.device);
      // this.setState({ selected: true });
    }
  };

  public onDeviceStateUpdated(device: Device): void {
    this.setState({ deviceState: device.getState() });
  }

  private handleDragStart = (event: React.DragEvent): void => {
    ReactTooltip.hide();

    if (this.props.parentGroup) {
      event.dataTransfer.setData("group", this.props.parentGroup.getId());
    }
    event.dataTransfer.setData("device", this.props.device.getId());
  };

  private getTooltip = (): string => {
    let timestampAsString = translations.common.texts.notAvailable();

    if (this.state.deviceState && this.state.deviceState.getStateUpdatedTimestampMillis()) {
      const timestamp = this.state.deviceState.getStateUpdatedTimestampMillis();
      timestampAsString = moment(timestamp).format(getDateTimeFormat(DateTimeFormatTarget.ShadowUpdate));
    }
    return "Last update: " + timestampAsString;
  };

  private renderSignalStatusElement(): ReactNode {
    const icon = getSignalStrengthIcon(this.state.deviceState);

    if (icon) {
      return (
        <img
          className="status-icon"
          data-tip={this.getTooltip()}
          src={icon}
          alt="Signal"
        />
      );
    }
  }

  private renderPowerSupplyStatusElement(): ReactNode {
    const icon = getPowerSupplyStateIcon(this.state.deviceState);

    if (icon) {
      return (
        <img
          className="status-icon"
          data-tip={this.getTooltip()}
          src={icon}
          alt="Battery"
        />
      );
    }
  }

  private renderDeviceIcon(): ReactNode {
    return <img src={this.props.device.getIcon()} alt="device logo" />;
  }

  public onDeviceStateUpdate(device: Device): void {
    this.setState({ deviceState: device.getState() });
  }

  private renderDeviceName(): ReactNode {
    return (
      <div
        className="iot-item-link col-sm-8 col-xsm-8"
        data-testid={"device-item-" + this.props.device.getId()}
        onClick={this.handleClick}
      >
        <div
          className="iot-icon col-sm-1 col-xsm-1"
          data-tip={ClientProperties.getAlarmTooltipFromEntities([])}
        >
          {this.renderDeviceIcon()}
        </div>
        <div className="iot-name col-sm-10 col-xsm-10" data-tip={this.getTooltip()}>
          {getDisplayName(this.props.device)}
        </div>
      </div>
    );
  }

  private renderStatusContainer(): ReactNode {
    if (this.props.editMode || !ReferenceHWState.instanceOf(this.state.deviceState)) return;
        
    const deviceState = this.state.deviceState;

    return (
      <div className="iot-status-container col-sm-4 col-xsm-4">
        <DeviceSettingsButton
          device={this.props.device}
          isIcon={true}
        />
        {this.renderSignalStatusElement()}
        <LocationIcon
          locationStatus={deviceState?.gpsFix ?? undefined}
          updateMilliseconds={deviceState?.getStateUpdatedTimestampMillis()}
        />
        {this.renderPowerSupplyStatusElement()}
      </div>
    );
  }

  public render(): JSX.Element {
    // TODO:  remove ClientProperties.getAlarmTooltipFromEntities([]) or add alarms back
    //        currently it provides some custom tool-tipping even if alerts are not enabled
    const divClass = this.props.selected ? "tree-node-selected" : "tree-node-not-selected";
    return (
      <div
        className={divClass}
        draggable={this.props.editMode}
        onDragStart={(event: React.DragEvent): void => this.handleDragStart(event)}
        data-testid={this.props["data-testid"]}
      >
        <ReactTooltip delayShow={TOOLTIP_DELAY_MS}/>
        {this.renderDeviceName()}
        {this.renderStatusContainer()}
      </div >
    );
  }
}
