/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import PolicyGroup, { PolicyGroupParameters, UpdateParameters } from "./PolicyGroup";
import { AWSOrganizationBackend } from "./AWSOrganizationBackend";
import User from "./User";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import {
  PolicyGroupsDeleteDocument,
  PolicyGroupsUpdateDocument,
  PolicyGroupsUsersAddDocument,
  PolicyGroupsUsersListDocument,
  PolicyGroupsUsersRemoveDocument,
  ResultType,
} from "../../generated/gqlUsers";
import { throwGQLError } from "../utils/Utils";
import { HasEntityRelations, RelationChange } from "../utils/EntityRelationCache";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";
import AWSUser from "./AWSUser";
import { verifyUserType } from "./AWSTypeUtils";

export default class AWSPolicyGroup extends PolicyGroup implements HasEntityRelations {
  public readonly entityType = AWSPolicyGroup;
  private usersSemaphore = new PromiseSemaphore(() => this.backendFetchUsers());

  public constructor(private readonly backend: AWSOrganizationBackend, parameters: PolicyGroupParameters) {
    super(parameters);
  }

  public async getUsers(): Promise<User[]> {
    await this.usersSemaphore.guard();
    const users = this.backend.entityRelationCache.listFor(this, AWSUser);
    users.sort(User.alphabeticUserOrdering);
    return users;
  }

  public async update(parameters: UpdateParameters): Promise<void> {
    if (Object.keys(parameters).length === 0) {
      return;
    }
    
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.mutate(
      PolicyGroupsUpdateDocument,
      {
        payload: {
          id: this.id,
          ...parameters,
        },
      },
    );

    if (!response.data?.policyGroupsUpdate) {
      throwGQLError(response, "Failed to update policy group");
    }

    const nameUpdated = this.name !== response.data.policyGroupsUpdate.name;
    const policiesUpdated = new Set([...this.policies, ...response.data.policyGroupsUpdate.policies]).size !== this.policies.length;
    
    this.name = response.data.policyGroupsUpdate.name;
    this.policies = response.data.policyGroupsUpdate.policies;
    
    if (nameUpdated) {
      this.notifyAction(observer => observer.onNameChange?.(this));
    }

    if (policiesUpdated) {
      this.notifyAction(observer => observer.onPoliciesChange?.(this.policies, this));
    }
  }

  public async addUser(user: User): Promise<void> {
    verifyUserType(user);
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.mutate(
      PolicyGroupsUsersAddDocument,
      {
        userId: user.getId(),
        policyGroupId: this.id,
      },
    );

    if (!response.data || response.data.policyGroupsUsersAdd?.result !== ResultType.Ok) {
      throwGQLError(
        response,
        response.data?.policyGroupsUsersAdd?.failureReason ?? "Failed to add user to policy group",
      );
    }

    this.backend.entityRelationCache.link(this, user as AWSUser);
  }

  public async removeUser(user: User): Promise<void> {
    verifyUserType(user);
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.mutate(
      PolicyGroupsUsersRemoveDocument,
      {
        userId: user.getId(),
        policyGroupId: this.id,
      },
    );

    if (!response.data || response.data.policyGroupsUsersRemove?.result !== ResultType.Ok) {
      throwGQLError(
        response,
        response.data?.policyGroupsUsersRemove?.failureReason ?? "Failed to remove user from policy group",
      );
    }

    this.backend.entityRelationCache.unlink(this, user as AWSUser);
  }

  public async delete(): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.mutate(PolicyGroupsDeleteDocument, { policyGroupId: this.id });

    if (!response.data || response.data.policyGroupsDelete?.result !== ResultType.Ok) {
      throwGQLError(
        response,
        response.data?.policyGroupsDelete?.failureReason ?? "Failed to delete policy group",
      );
    }

    await this.backend.cleanEntityFromCaches(this.id);
    this.notifyAction(observer => observer.onDelete?.(this));
    this.clearObservers();
  }

  public onRelationChange(change: RelationChange): void {
    if (change.ofType(AWSUser) && this.usersSemaphore.invoked()) {
      const users = this.backend.entityRelationCache.listFor(this, AWSUser);
      this.notifyAction(observer => observer.onUsersChange?.(users, this));
    }
  }

  private async backendFetchUsers(): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);

    const response = await client.query(
      PolicyGroupsUsersListDocument,
      {
        policyGroupId: this.id,
      },
    );

    if (!response.data.policyGroupsUsersList) {
      throwGQLError(response, "Failed to fetch policy group's users");
    }

    // As with AWSOrganization::getUsers - this is slow going
    const users = await Promise.all(response.data.policyGroupsUsersList.users.map((userId) => this.backend.getUser(userId)));
    this.backend.entityRelationCache.replaceTypedLinks(this, AWSUser, users.filter(AWSUser.instanceOf));
  }
  
  public static instanceOf(value: unknown): value is AWSPolicyGroup {
    return value instanceof AWSPolicyGroup;
  }
}
