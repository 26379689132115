/* Copyright */

import { Nullable } from "../../../types/aliases";
import DeviceState from "../../../data/device/DeviceState";
import { SignHWStateProperties } from "./SignHWStateProperties";


export class SignHWState extends DeviceState<SignHWStateProperties> {
  public get batteryCapacity(): Nullable<number> {
    return this.reported.batteryCapacity;
  }

  public get batteryMaxCapacity(): Nullable<number> {
    return this.reported.batteryMaxCapacity;
  }

  public get batteryAverageCurrent(): Nullable<number> {
    return this.reported.batteryAverageCurrent;
  }

  public get batteryCurrentPerSec(): Nullable<number> {
    return this.reported.batteryCurrentPerSec;
  }


  public get displayName(): Nullable<string> {
    return this.reported.displayName;
  }

  public set displayName(name: Nullable<string>) {
    this.changedValues.displayName = name;
  }

  public revert(): void {
    this.changedValues = {};
  }
}
