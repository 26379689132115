/* Copyright */
import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { RailroadDevice } from "../../../client/devices/RailroadDevice/RailroadDevice";
import Crossing from "../../../client/groups/Crossing/Crossing";
import { RailroadGroup } from "../../../client/groups/RailroadGroup";
import { Maybe } from "../../../types/aliases";
import CustomButton from "../../ui/custom-button";
import CustomTable, { Row } from "../../ui/custom-table";
import { EventState, Event } from "../../../generated/gqlEvents";
import ServiceDataService from "../../../services/ServiceDataService";
import EventSet, { EventObserver } from "../../../data/events/EventSet";
import Factory from "../../../client/groups/Factory/Factory";
import Device, { DeviceObserver } from "../../../data/device/Device";
import { MainUnitHW } from "../../../client/devices/MainUnitHW/MainUnitHW";
import { COUNT_OF_PAST_SERVICES, COUNT_OF_UPCOMING_SERVICES, ServiceEditorConfig, TableContent } from "..";
import Loader from "../../ui/loader";
import { Mode } from "./service-editor";
import { MainUnitHWState } from "../../../client/devices/MainUnitHW/MainUnitHWState";
import { translations } from "../../../generated/translationHelper";

interface Props {
  resource: RailroadDevice | RailroadGroup;
  onEditorOpen: (serviceEditorConfig: ServiceEditorConfig) => void;
}

export default function RailroadContent(props: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [mainUnitState, setMainUnitState] = useState<MainUnitHWState | undefined>();
  const [tableData, setTableData] = useState<[TableContent, TableContent | undefined]>();
  const [pastServicesSet, setPastServicesSet] = useState<EventSet[]>();
  const [upcomingServicesSet, setUpcomingServicesSet] = useState<EventSet[]>();
  const loadingTasks = useRef(0);
  const mainUnitRef = useRef<MainUnitHW>();

  const deviceObserver = useRef<DeviceObserver>({
    onDeviceStateUpdated: (device: Device) => {
      console.log("Device updated", device);
      if (MainUnitHW.instanceOf(device)) setMainUnitState(device.getState() ?? undefined);
    },
  });

  const toggleServiceMode = async (): Promise<void> => {
    if (mainUnitState) {
      try {
        mainUnitState.isServiceMode = !mainUnitState.isServiceMode;
        await mainUnitState.store();
      } catch (error) {
        console.error("toggleServiceMode", error);
      }
    }
  };

  const handleViewService = (row: Row<Event>): void => {
    if (row.source) {
      props.onEditorOpen({
        mode: Mode.VIEW,
        source: {
          deviceId: row.source.deviceId,
          deviceName: row.source.deviceName ?? undefined,
          timestamp: Number(row.source.timestamp),
          description: row.source.description ?? "",
          eventId: row.source.eventId ?? "",
          eventState: row.source.eventState ?? EventState.Inactive,
          crossingId: row.source.crossingId ?? "",
          factoryId: row.source.factoryId ?? "",
          submittedBy: row.source.submittedBy ?? "",
          factoryName: row.source.factoryName ?? "",
          crossingName: row.source.crossingName ?? "",
          link: row.source.link ?? undefined,
        },
      });
    }
  };

  const handleRegisterService = (row: Row<Event>): void => {
    if (row.source) {
      props.onEditorOpen({
        // Services can not be registered on factory level
        mode: Factory.instanceOf(props.resource) ? Mode.VIEW : Mode.REGISTER,
        source: {
          deviceId: row.source.deviceId,
          deviceName: row.source.deviceName ?? undefined,
          timestamp: Number(row.source.timestamp),
          description: row.source.description ?? "",
          eventId: row.source.eventId ?? "",
          eventState: row.source.eventState ?? EventState.Active,
          crossingId: row.source.crossingId ?? "",
          factoryId: row.source.factoryId ?? "",
          submittedBy: "",
          factoryName: row.source.factoryName ?? "",
          crossingName: row.source.crossingName ?? "",
          updatedTimestamp: Date.now(),
          link: row.source.link ?? undefined,
        },
      });
    }
  };

  const addToLoadingQueue = (): void => {
    loadingTasks.current++;
    setIsLoading(loadingTasks.current > 0);
  };

  const removeFromLoadingQueue = (): void => {
    loadingTasks.current--;
    setIsLoading(loadingTasks.current > 0);
  };

  const setPastServices = async (resource: RailroadDevice | RailroadGroup): Promise<void> => {
    addToLoadingQueue();
    const crossing = props.resource.getParentGroup();
    const timestampNow = Date.now();
    const hour = 3600 * 1000;
    const day = 24 * hour;
    const year = 365 * day;
    // Get timestamp from start of the date (midnight)
    const todayMidnight = new Date(new Date(timestampNow).toDateString()).getTime();
    const yearAgo = todayMidnight - year;
    const after5Years = todayMidnight + year * 5;
    const pastServicesEventSet = resource.getEventSet(yearAgo, after5Years);
    if (!pastServicesEventSet.getData()) await pastServicesEventSet.fetch();
    const pastServicesSet = [pastServicesEventSet];

    if (Crossing.instanceOf(crossing)) {
      const crossingPastServicesEventSet = crossing.getEventSet(yearAgo, after5Years);
      if (!crossingPastServicesEventSet.getData()) await crossingPastServicesEventSet.fetch();
      pastServicesSet.push(crossingPastServicesEventSet);
    }
    setPastServicesSet(pastServicesSet);
    removeFromLoadingQueue();
  };

  const setUpcomingServices = async (resource: RailroadDevice | RailroadGroup): Promise<void> => {
    addToLoadingQueue();
    const crossing = props.resource.getParentGroup();
    const timestampNow = Date.now();
    const hour = 3600 * 1000;
    const day = 24 * hour;
    const year = 365 * day;
    const month = 30 * day;
    const todayMidnight = new Date(new Date(timestampNow).toDateString()).getTime();
    const after5Years = todayMidnight + year * 5;
    const monthAgo = todayMidnight - month;
    const upcomingServicesEventSet = resource.getEventSet(monthAgo, after5Years);
    if (!upcomingServicesEventSet.getData()) await upcomingServicesEventSet.fetch();
    const upcomingServiceSets = [upcomingServicesEventSet];

    if (Crossing.instanceOf(crossing)) {
      const crossingUpcomingServicesEventSet = crossing.getEventSet(monthAgo, after5Years);
      if (!crossingUpcomingServicesEventSet.getData()) await crossingUpcomingServicesEventSet.fetch();
      upcomingServiceSets.push(crossingUpcomingServicesEventSet);
    }
    setUpcomingServicesSet(upcomingServiceSets);
    removeFromLoadingQueue();
  };

  /**
   * Update table contents in the Service View.
   * @param resource
   *  Currently selected resource
   */
  const setTableContent = async (): Promise<void> => {
    if (upcomingServicesSet && pastServicesSet) {
  
      if (Factory.instanceOf(props.resource)) {
        const upcomingServices = ServiceDataService.getUpcomingFactoryServices(upcomingServicesSet[0], COUNT_OF_UPCOMING_SERVICES);
        const pastServices = ServiceDataService.getPastFactoryServices(pastServicesSet[0], COUNT_OF_PAST_SERVICES);
        setTableData([
          {
            data: upcomingServices,
            title: translations.service.texts.upcomingServices({ unit: props.resource.getName() }),
            header: ServiceDataService.getUpcomingFactoryServicesHeader(),
          },
          {
            data: pastServices,
            title: translations.service.texts.pastServices({ unit: props.resource.getName() }),
            header: ServiceDataService.getPastFactoryServicesHeader(),
          },
        ]);
      } else if (Crossing.instanceOf(props.resource)) {
        const upcomingServices = ServiceDataService.getUpcomingCrossingServices(upcomingServicesSet[0], COUNT_OF_UPCOMING_SERVICES);
        const pastServices = ServiceDataService.getPastCrossingServices(pastServicesSet[0], COUNT_OF_PAST_SERVICES);
        setTableData([
          {
            data: upcomingServices,
            title: translations.service.texts.upcomingServices({ unit: props.resource.getName() }),
            header: ServiceDataService.getUpcomingCrossingServicesHeader(),
          },
          {
            data: pastServices,
            title: translations.service.texts.pastServices({ unit: props.resource.getName() }),
            header: ServiceDataService.getPastCrossingServicesHeader(),
          },
        ]);
      } else if (RailroadDevice.instanceOf(props.resource)) {
        const upcomingServices = ServiceDataService.getUpcomingDeviceServices(upcomingServicesSet[0], upcomingServicesSet[1], COUNT_OF_UPCOMING_SERVICES);
        const pastServices = ServiceDataService.getPastDeviceServices(pastServicesSet[0], pastServicesSet[1], COUNT_OF_PAST_SERVICES);
        setTableData([
          {
            data: upcomingServices,
            title: translations.service.texts.upcomingServices({ unit: props.resource.getState()?.displayName ?? props.resource.getId() }),
            header: ServiceDataService.getUpcomingDeviceServicesHeader(),
          },
          {
            data: pastServices,
            title: translations.service.texts.upcomingServices({ unit: props.resource.getState()?.displayName ?? props.resource.getId() }),
            header: ServiceDataService.getPastDeviceServicesHeader(),
          },
        ]);
      }      
    }
  };

  const resolveMainUnit = async (resource: RailroadDevice | RailroadGroup): Promise<Maybe<MainUnitHW>> => {
    if (MainUnitHW.instanceOf(resource)) {
      return resource;
    } else if (RailroadDevice.instanceOf(resource)) {
      return await (await resource.getParentGroup())?.getMainUnit();
    } else if (Crossing.instanceOf(resource)) {
      return await resource.getMainUnit(); 
    }
    return undefined;
  };

  const setMainUnitData = async (resource: RailroadDevice | RailroadGroup): Promise<void> => {
    addToLoadingQueue();
    mainUnitRef.current?.removeObserver(deviceObserver.current);
    const mainUnit = await resolveMainUnit(resource);
    mainUnitRef.current = mainUnit;
    mainUnitRef.current?.addObserver(deviceObserver.current);
    setMainUnitState(mainUnit?.getState() ?? undefined);
    removeFromLoadingQueue();
  };

  const eventObserver = useRef<EventObserver>({
    onEventSetUpdate: (eventSet: EventSet) => {
      console.log(`EventSet updated: ${eventSet}`);
      setTableContent();
    },
  });

  useEffect(() => {
    setTableContent();
    const currentEventSetObserver = eventObserver.current;
    pastServicesSet?.forEach((eventSet) => eventSet.addObserver(eventObserver.current));

    return (): void => {
      pastServicesSet?.forEach((eventSet) => eventSet.removeObserver(currentEventSetObserver));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastServicesSet]);

  useEffect(() => {
    setTableContent();
    const currentEventSetObserver = eventObserver.current;
    upcomingServicesSet?.forEach((eventSet) => eventSet.addObserver(eventObserver.current));

    return (): void => {
      upcomingServicesSet?.forEach((eventSet) => eventSet.removeObserver(currentEventSetObserver));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingServicesSet]);

  useEffect(() => {
    setMainUnitData(props.resource);
    setPastServices(props.resource);
    setUpcomingServices(props.resource);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resource]);

  useEffect(() => {
    return (): void => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mainUnitRef.current?.removeObserver(deviceObserver.current);
    };
  }, []);

  const renderServiceActionButtons = (): Maybe<JSX.Element> => {
    if (RailroadDevice.instanceOf(props.resource) || Crossing.instanceOf(props.resource)) {
      return (
        <Grid item container justifyContent="space-around" xs>
          <Grid item>
            <CustomButton
              variant="contained"
              color="secondary"
              onClick={(): void => props.onEditorOpen({ mode: Mode.REGISTER })}
            >
              {translations.service.buttons.registerService()}
            </CustomButton>
          </Grid>
          {mainUnitState?.isServiceMode !== undefined &&
            <Grid item>
              <CustomButton
                variant="contained"
                color="secondary"
                onClick={async (): Promise<void> => await toggleServiceMode()}
              >
                {mainUnitState.isServiceMode ? translations.service.buttons.disableServiceMode() : translations.service.buttons.enableServiceMode()}
              </CustomButton>
            </Grid>
          }
        </Grid>
      );
    }
  };

  const renderLoader = (): Maybe<JSX.Element> => {
    if (isLoading) {
      return <Loader/>;
    }
  };

  return renderLoader() ?? (
    <Grid container direction="column" spacing={3}>
      {tableData && tableData[0] &&
          <Grid item xs>
            <CustomTable
              header={tableData[0].header ?? []}
              body={tableData[0].data ?? []}
              title={tableData[0].title ?? ""}
              emptyTableText={translations.service.texts.noUpcomingServicesFound()}
              onRowSelect={handleRegisterService}
            />
          </Grid>
      }
      {tableData && tableData[1] &&
          <Grid item xs>
            <CustomTable
              header={tableData[1].header ?? []}
              body={tableData[1].data ?? []}
              title={tableData[1].title ?? ""}
              emptyTableText={translations.service.texts.noPastServicesFound()}
              onRowSelect={handleViewService}
            />
          </Grid>
      }
      {renderServiceActionButtons()}
    </Grid>
  );
}
