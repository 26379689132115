/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { RouteComponentProps } from "react-router-dom";

export enum Paths {
  STATUS = "/status",
  HISTORY = "/history",
  ANALYTICS = "/analytics",
  SERVICE = "/service",
  USER = "/user",
  ADMIN = "/admin",
  ROOT = "/",
  EVENTS = "/events",
  LOGIN = "/login",
}

interface RouteParamPreservationConfig {
  pathParams: string[];
}
  
  type PreservationConfigurations = Record<Paths, RouteParamPreservationConfig>;
  
  
const preservationConfigs: PreservationConfigurations = {
  [Paths.ROOT]: {
    pathParams: [],
  },
  [Paths.STATUS]: {
    pathParams: ["type", "id"],
  },
  [Paths.HISTORY]: {
    pathParams: ["type", "id"],
  },
  [Paths.LOGIN]: {
    pathParams: [],
  },
  [Paths.EVENTS]: {
    pathParams: ["type", "id"],
  },
  [Paths.ADMIN]: {
    pathParams: [],
  },
  [Paths.USER]: {
    pathParams: [],
  },
  [Paths.ANALYTICS]: {
    pathParams: ["type", "id"],
  },
  [Paths.SERVICE]: {
    pathParams: ["type", "id"],
  },
};
  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPathWithRetainedParams(path: Paths, routeProps: RouteComponentProps<any>): string {
  const retainedPathParams: string[] = [];
  
  for (const pathParam of preservationConfigs[path].pathParams) {
    if (routeProps.match.params[pathParam]) {
      retainedPathParams.push(routeProps.match.params[pathParam]);
    } else {
      break;
    }
  }
  
  return path + (retainedPathParams.length > 0 ? "/" + retainedPathParams.join("/") : "");
}
  
export default Paths;
