/* Copyright */

import { Maybe } from "../../types/aliases";
import AWSThingGroup from "../../data/device/AWSThingGroup";
import EventSet from "../../data/events/EventSet";
import { EventSeverity, EventState, EventType } from "../../generated/gqlEvents";
import { ResourceStatus } from "../ResourceStatus";
import { CoordinateLocation } from "../devices/RailroadDevice/RailroadDevice";
import { isValidJSON } from "../../utils/validation";
import TimePeriod from "../../data/utils/TimePeriod";

export abstract class RailroadGroup extends AWSThingGroup {
  public getLocation(): Maybe<CoordinateLocation> {
    return this.getAttribute("location");
  }

  public async getResourceStatus(): Promise<ResourceStatus> {
    const eventSet = this.getEventSet();
    if (!eventSet?.getData()) await eventSet.fetch();
    const events = eventSet?.getData();
    const error = events?.some(e => e.eventState === EventState.Active && e.severity === EventSeverity.High && e.type === EventType.Diagnostics);
    const warning = events?.some(e => e.eventState === EventState.Active && e.severity === EventSeverity.Medium && e.type === EventType.Diagnostics);

    if (error) return ResourceStatus.ERROR;
    if (warning) return ResourceStatus.WARNING;
    return ResourceStatus.NONE;
  }

  public getAttribute<T>(key: string): Maybe<T> {
    const attribute = super.getAttributes()?.find((attribute) => attribute.key === key);

    if (attribute?.value && isValidJSON(attribute.value)) {
      return JSON.parse(attribute.value);
    }
    return attribute?.value as unknown as T ?? undefined;
  }

  public static instanceOf(value: unknown): value is RailroadGroup {
    return value instanceof RailroadGroup;
  }

  public abstract getEventSet(startTimestamp?: number, endTimestamp?: number): EventSet;

  public abstract getStatusIcon(timePeriod: TimePeriod): Promise<string>;
}
