/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import { timestampToMilliseconds } from "../utils/Utils";
import { DeviceStateProperties } from "./DeviceStateProperties";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe, Nullable } from "../../types/aliases";
import { DevicesStatesUpdateDocument } from "../../generated/gqlDevice";

export default abstract class DeviceState<TStateProperties extends DeviceStateProperties = DeviceStateProperties> {
  // Changed shadow properties need to be added to this object when setting property value
  protected changedValues: Partial<TStateProperties> = {};
  private readonly id: string;
  private updateTimestamp?: number;

  public constructor(deviceId: string, protected reported: TStateProperties, protected desired: TStateProperties, timestamp?: number) {
    this.id = deviceId;
    this.updateTimestamp = timestamp;
  }

  // Returns true if one of the properties is being taken into use in the device side
  public beingApplied(key: keyof TStateProperties): boolean {
    return this.deltaExists(key) && this.changedValues[key] === undefined;
  }

  public deltaExists(key: keyof TStateProperties): boolean {
    return (this.desired[key] != null && this.desired[key] !== this.reported[key]);
  }

  public get deviceId(): string {
    return this.id;
  }

  public get displayName(): Nullable<string> {
    return (this.deltaExists("displayName") ? this.desired.displayName : this.reported.displayName)!;
  }

  public set displayName(name: Nullable<string>) {
    this.desired.displayName = name;
    this.changedValues.displayName = name;
  }

  public get otaId(): Nullable<string> {
    return this.reported.otaId;
  }

  public set otaId(id: Nullable<string>) {
    this.desired.otaId = id;
    this.changedValues.otaId = id;
  }

  public getStateUpdatedTimestampMillis(): Maybe<number> {
    if (this.updateTimestamp) {
      return timestampToMilliseconds(this.updateTimestamp);
    }
  }

  public async store(): Promise<void> {
    console.log("Store state, payload " + JSON.stringify(this.changedValues));

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      await client.mutate(
        DevicesStatesUpdateDocument,
        {
          deviceId: this.deviceId,
          state: JSON.stringify(this.changedValues),
        },
      );
      this.changedValues = {};
    } catch (error) {
      console.error("Error", error);
    }
  }

  public revert(): void {
    const keys = Object.keys(this.changedValues) as Array<keyof TStateProperties>;
    keys.forEach((key: keyof TStateProperties) => {
      this.desired[key] = this.reported[key];
    });
    this.changedValues = {};
  }


  public static instanceOf(state: unknown): state is DeviceState {
    return state instanceof DeviceState;
  }
}
