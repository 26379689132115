/* Copyright */
import { Drawer, IconButton } from "@material-ui/core";
import React from "react";

import { Maybe } from "../../types/aliases";
import { DrawerState } from "../../data/utils/Utils";
import ArrowDouble from "../../assets/arrow-double-24px.svg";
import Map from "../ui/map";
import Device from "../../data/device/Device";
import DeviceGroup from "../../data/device/DeviceGroup";

interface Props {
  isSelectionEnabled: boolean;
  devices?: Device[];
  groups?: DeviceGroup[];
  children?: Maybe<JSX.Element>[] | Maybe<JSX.Element>;
  initialDrawerState?: DrawerState;
  zoomToResource?: Device | DeviceGroup;
}

/**
 * MapDrawer renders a drawer on the left that contains a map. Rest of the view
 * is rendered with the given children component.
 */
export default function MapDrawer(props: Props): JSX.Element {
  const {
    devices,
    groups,
    initialDrawerState,
    zoomToResource,
    isSelectionEnabled,
    children,
  } = props;
  const [drawerState, setDrawerState] = React.useState(initialDrawerState ?? DrawerState.Open);

  const openDrawer = (): void => {
    setDrawerState(DrawerState.Open);
  };

  const openFullDrawer = (): void => {
    setDrawerState(DrawerState.Full);
  };

  const closeDrawer = (): void => {
    setDrawerState(DrawerState.Closed);
  };

  const renderButtons = (): Maybe<JSX.Element> => {
    if (drawerState === DrawerState.Open) {
      return (
        <div className="drawer-button-container">
          <IconButton className="drawer-button close" onClick={closeDrawer}>
            <img src={ArrowDouble} alt="forward arrow" />
          </IconButton>
          <IconButton onClick={openFullDrawer} className="drawer-button visible">
            <img src={ArrowDouble} alt="forward arrow" />
          </IconButton>
        </div>
      );
    }

    if (drawerState === DrawerState.Full) {
      return (
        <div className="drawer-button-container">
          <IconButton className="drawer-button close" onClick={openDrawer}>
            <img src={ArrowDouble} alt="forward arrow" />
          </IconButton>
        </div >
      );
    }
  };

  const renderSidebar = (): Maybe<JSX.Element> => {
    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerState !== DrawerState.Closed}
        transitionDuration={500}
        classes={{
          paper: (drawerState === DrawerState.Full ? "col-xsm-12 col-sm-12 col-md-12 col-lg-12" :
            "col-xsm-12 col-sm-12 col-md-12 col-lg-6") + " iot-content-drawer",
        }}
      >
        <div className="status-map-container">
          <Map
            drawerState={drawerState}
            devices={devices}
            groups={groups}
            zoomToResource={zoomToResource}
            isSelectionEnabled={isSelectionEnabled}
          />
        </div>
        {renderButtons()}
      </Drawer>
    );
  };

  const renderSidebarButton = (): Maybe<JSX.Element> => {
    if (drawerState !== DrawerState.Open) {
      return (
        <div className="drawer-button-container">
          <IconButton onClick={openDrawer} className="drawer-button open">
            <img src={ArrowDouble} alt="forward arrow" />
          </IconButton>
        </div>
      );
    }
  };

  return (
    <>
      {renderSidebar()}
      <div
        className="iot-content-container col-xsm-12 col-sm-12 col-md-12 col-lg-12"
        style={
          (drawerState === DrawerState.Open)
            ? { marginLeft: "50%", width: "50%" }
            : drawerState === DrawerState.Full
              ? { width: "0%" }
              : undefined
        }
      >
        {renderSidebarButton()}
        {children}
      </div >
    </ >
  );
}
