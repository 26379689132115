/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { Box, Grid } from "@material-ui/core";
import CustomButton from "../../../ui/custom-button";

interface Props {
  changesMade: boolean;
  submitButtonLabel: string;
  cancelButtonLabel: string;
  onSave: () => void;
  onCancel: () => void;
}

const SettingsControls: FunctionComponent<Props> = (props: PropsWithChildren<Props>): ReactElement => {
  return (
    <Box>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item>
          <CustomButton
            variant="contained"
            color="secondary"
            disabled={!props.changesMade}
            className="button left"
            onClick={props.onSave}
          >
            {props.submitButtonLabel}
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            variant="contained"
            color="secondary"
            className="button"
            onClick={props.onCancel}
          >
            {props.cancelButtonLabel}
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsControls;
