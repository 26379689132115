/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import Organization, { OrganizationObserver } from "../../../../data/organization/Organization";
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import Loader from "../../../ui/loader";
import ErrorDialog from "../../../ui/error-dialog";
import BackendFactory from "../../../../data/BackendFactory";
import CustomButton from "../../../ui/custom-button";
import ConfirmationDialog from "../../../ui/confirmation-dialog";
import { translations } from "../../../../generated/translationHelper";
import { isError } from "../../../../data/utils/ErrorUtils";

interface UserPermissions {
  createOrganization: boolean;
  deleteOrganization: boolean;
}

const NO_PERMISSIONS: UserPermissions = {
  createOrganization: false,
  deleteOrganization: false,
};

interface Props {
  parentOrganization: Organization;
}

interface State {
  loading: boolean;
  children?: Organization[];
  userPermissions?: UserPermissions;
  newOrganizationName?: string;
  organizationToDelete?: Organization;
  errorMsg?: string;
}

const ROW_COL_COUNT = 2;

export default class OrganizationChildList extends Component<Props, State> implements OrganizationObserver{
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.performUpdates();
    this.props.parentOrganization.addObserver(this);
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.parentOrganization.getId() !== this.props.parentOrganization.getId()) {
      prevProps.parentOrganization.removeObserver(this);
      await this.performUpdates();
      this.props.parentOrganization.addObserver(this);
    }
  }
  
  public componentWillUnmount(): void {
    this.props.parentOrganization.removeObserver(this);
  }

  private async performUpdates(): Promise<void> {
    this.setState({
      loading: true,
    });
    await Promise.allSettled([
      this.loadChildren(),
      this.updateUserPermissions(),
    ])
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  private async updateUserPermissions(): Promise<void> {
    try {
      const user = await BackendFactory.getOrganizationBackend().getCurrentUser();

      if (user) {
        const organizationId = this.props.parentOrganization.getId();
        const allowedActions = await Promise.all([
          user.hasGrants(organizationId, "organizationsCreate"),
          user.hasGrants(organizationId, "organizationsDelete"),
        ]);

        this.setState({
          userPermissions: {
            createOrganization: allowedActions[0],
            deleteOrganization: allowedActions[1],
          },
        });
      }
    } catch (err) {
      console.error("Failed to update user's permissions", err);
      this.setState({ userPermissions: NO_PERMISSIONS });
    }
  }

  private async loadChildren(): Promise<void> {
    const children = await this.props.parentOrganization.getChildOrganizations();
    this.setState({
      children,
    });
  }

  private async performLoadableTask(taskName: string, task: () => Promise<void>, cleanup?: () => void): Promise<void> {
    this.setState({ loading: true });

    try {
      await task();
    } catch (err) {
      console.error(`Failed to complete task '${taskName}'`, err);
      this.setState({ errorMsg: `${taskName} failed: ${typeof err === "string" ? err : err.message}` });
    } finally {
      this.setState({ loading: false });

      if (cleanup) {
        cleanup();
      }
    }
  }

  public onChildrenChange(children: Organization[]): void {
    this.setState({ children });
  }

  private handleCreateOrganization = async (): Promise<void> => {
    return this.performLoadableTask(
      "Create organization",
      async (): Promise<void> => {

        if (!this.newOrganizationNameIsValid()) {
          console.error("New organization name is too short");
          return;
        }

        const name = this.state.newOrganizationName!;
        this.setState({
          newOrganizationName: undefined,
        });
        const parent = this.props.parentOrganization;
        // create organization
        const [organization, parentGroup] = await Promise.all([
          parent.createOrganization({ name }),
          BackendFactory.getBackend().findDeviceGroupByName(parent.getName(), parent.getId()),
        ]);

        // create organization's default device group
        await BackendFactory.getBackend().createDeviceGroup({
          displayName: name,
          organizationId: organization.getId(),
          parentGroup,
        });
      },
    );
  };

  private newOrganizationNameChanged(name: string): void {
    this.setState({
      newOrganizationName: name,
    });
  }

  private newOrganizationNameIsValid(): boolean {
    return !!this.state.newOrganizationName && this.state.newOrganizationName.length > 3;
  }

  public renderChildCreationTool(): ReactNode {
    if (!this.state.userPermissions?.createOrganization) {
      return;
    }
    return <TableRow>
      <TableCell>
        <TextField
          fullWidth={true}
          variant="outlined"
          placeholder={translations.admin.inputs.newOrganisationsName()}
          className="organization-name"
          inputProps={{ "data-testid": "new-organization-name" }}
          value={this.state.newOrganizationName ?? ""}
          onChange={(event): void => this.newOrganizationNameChanged(event.currentTarget.value)}
        />
      </TableCell>
      <TableCell align="right">
        <CustomButton
          variant="contained"
          color="secondary"
          className="button"
          onClick={this.handleCreateOrganization}
          data-testid="create-new-organization-button"
          disabled={!this.newOrganizationNameIsValid() || this.state.loading}
        >
          {translations.common.buttons.create()}
        </CustomButton>
      </TableCell>
    </TableRow>;
  }
  
  public renderChildList(): ReactNode {
    const children = [...(this.state.children ?? [])]
      .sort((child1, child2) => child1.getName().localeCompare(child2.getName()));

    if (children.length === 0) {
      return;
    }
    
    return children.map((child, index) => {
      return (
        <TableRow key={child.getId()}>
          <TableCell>
            <Typography color="textPrimary">{child.getName()}</Typography>
            <Typography variant="body2" color="textSecondary">{child.getId()}</Typography>
          </TableCell>
          <TableCell align="right">
            <CustomButton
              onClick={(): void => this.setState({ organizationToDelete: child })}
              variant="contained"
              color="secondary"
              disabled={!this.state.userPermissions?.deleteOrganization}
              data-testid={`organization-${index}-delete-button`}
            >
              {translations.common.buttons.delete()}
            </CustomButton>
          </TableCell>
        </TableRow>
      );
    });
  }

  private renderDeleteConfirmation(): ReactNode {
    if (!this.state.organizationToDelete) return;
    
    const organization = this.state.organizationToDelete;
    const deleteAction = (): void => void this.performLoadableTask(
      "Delete organization",
      async (): Promise<void> => {
        this.setState({ organizationToDelete: undefined });
            
        const organizationIndex = this.state.children?.findIndex((child) => child.getId() === organization.getId()) ?? -1;
    
        if (organizationIndex === -1) {
          throw new Error("Organization is not a valid child");
        }
    
        try {
          await organization.delete();
        } catch (err) {
          console.error("Delete organization", err);
    
          if (isError(err) && err.message && /has child/.test(err.message)) {
            this.setState({ errorMsg: translations.admin.texts.failedToDeleteOrganizationHasChildren() });
          } else {
            this.setState({ errorMsg: translations.admin.texts.failedToDeleteOrganization() });
          }
        }
      },
    );
    return (
      <ConfirmationDialog 
        title={translations.admin.texts.deleteOrganization()}
        message={translations.admin.texts.deleteOrganizationConfirmation({ organization: organization.getName() })}
        onConfirm={deleteAction} 
        onCancel={(): void => this.setState({ organizationToDelete: undefined })}
      />
    );
  }

  private renderError(): ReactNode {
    if (this.state.errorMsg) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <ErrorDialog
              errorMsg={this.state.errorMsg}
              onClose={(): void => this.setState({ errorMsg: undefined })}
            />
          </TableCell>
        </TableRow>
      );
    }
  }

  private renderLoader(): ReactNode {
    if (this.state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <Loader size="small" />
          </TableCell>
        </TableRow>
      );
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Table data-testid="organizations-table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={ROW_COL_COUNT}>{translations.admin.texts.organisationsChildren()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderChildCreationTool()}
            {this.renderChildList()}
          </TableBody>
        </Table>
        {this.renderLoader()}
        {this.renderDeleteConfirmation()}
        {this.renderError()}
      </Fragment>
    );
  }
}
