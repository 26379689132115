/* Copyright */
export interface SummaryDataFragment {
  id?: string;
  totalMessages?: number;
  totalDuration?: number;
  timestamp?: number;
}

export default class SummaryData implements Required<SummaryDataFragment> {
  public id: string;
  public totalMessages: number;
  public totalDuration: number;
  public timestamp: number;

  public constructor(summaryDataFragment: SummaryDataFragment) {
    if (!summaryDataFragment.timestamp && !summaryDataFragment.id) {
      throw new Error("Missing required properties");
    }
    this.id = summaryDataFragment.id!;
    this.totalMessages = summaryDataFragment.totalMessages ?? 0;
    this.totalDuration = summaryDataFragment.totalDuration ?? 0;
    this.timestamp = summaryDataFragment.timestamp!;
  }
}
