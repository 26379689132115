/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { DataSet } from "../data/DataSet";
import { LatestData } from "../data/LatestData";
import { SessionSet } from "../data/SessionSet";
import EventSet from "../events/EventSet";
import BaseObservable from "../observer/BaseObservable";
import DeviceState from "./DeviceState";
import { Maybe } from "../../types/aliases";
import { Attribute } from "./Attribute";
import DeviceGroup from "./DeviceGroup";

export type StatePropertiesOf<TState> = TState extends DeviceState<infer TProperties> ? TProperties : never;

export interface DeviceObserver {
  onDeviceStateUpdated?: (device: Device) => void;
  onDeviceGroupsChanged?: (device: Device) => void;
}
  
export interface DeviceParameters {
  deviceId: string;
  attributes?: Attribute[];
  parentGroupId?: string;
}

export default abstract class Device<TState extends DeviceState = DeviceState> extends BaseObservable<DeviceObserver> {

  public abstract init(): Promise<void>;

  public abstract getId(): string;

  public abstract getType(): string;

  public abstract getParentGroup(): Promise<Maybe<DeviceGroup>>;

  public abstract getIcon(): string;

  public abstract getAttribute(key: string): Maybe<string>;

  public abstract getAttributes(): Attribute[];

  public abstract getState(): Maybe<TState>;

  public abstract getSessionSet(startTimestamp: number, endTimestamp: number): SessionSet;

  public abstract getDataSet(startTimestamp: number, endTimestamp: number): DataSet;

  public abstract getLatestData(): LatestData;
  
  public abstract getGroups(): Promise<DeviceGroup[]>;

  public abstract setState(timestamp?: number, current?: Partial<StatePropertiesOf<TState>>, next?: Partial<StatePropertiesOf<TState>>): void;

  public abstract getEventSet(startTimestamp?: number, endTimestamp?: number): EventSet;

  public abstract updateAttributes(attributes: Attribute[]): Promise<void>;

  // These methods need to be implemented by a concrete device class
  public abstract createState(timestamp?: number, reported?: Partial<StatePropertiesOf<TState>>, desired?: Partial<StatePropertiesOf<TState>>): TState;

  public static instanceOf(value: unknown): value is Device {
    return value instanceof Device;
  }
}

