/* Copyright */
import AppSyncClientFactory from "../../data/backend/AppSyncClientFactory";
import { Service } from "../../data/backend/AppSyncClientProvider";
import { Event, EventsSubmitDocument, EventState, EventType, SubmitEventPayload } from "../../generated/gqlEvents";

type EventWithoutTimestamps = Omit<Event, "timestamp" | "updatedTimestamp" | "nextEventTimestamp">;

export interface ServiceEventFragment {
  timestamp: number;
  description: string;
  submittedBy: string;
  crossingId: string;
  factoryId: string;
  eventId: string;
  factoryName: string;
  crossingName: string;
  deviceId: string;
  deviceName?: string;
  updatedTimestamp?: number;
  nextEventTimestamp?: number;
  eventState?: EventState;
  link?: Event[];
}

export default class ServiceEvent implements EventWithoutTimestamps {
  public readonly deviceId: string;
  public readonly timestamp: number;
  public readonly type = EventType.Service;
  public eventId: string;
  public eventState: EventState;
  public description: string;
  public crossingId: string;
  public factoryId: string;
  public submittedBy: string;
  public deviceName?: string;
  public crossingName?: string;
  public factoryName?: string;
  public nextEventTimestamp?: number;
  public updatedTimestamp?: number;
  public link?: Event[];

  public constructor(serviceEventFragment: ServiceEventFragment) {
    this.deviceId = serviceEventFragment.deviceId ?? "NONE";
    this.timestamp = serviceEventFragment.timestamp;
    this.deviceName = serviceEventFragment.deviceName;
    this.eventId = serviceEventFragment.eventId;
    this.crossingId = serviceEventFragment.crossingId;
    this.crossingName = serviceEventFragment.crossingName;
    this.factoryId = serviceEventFragment.factoryId;
    this.factoryName = serviceEventFragment.factoryName;
    this.description = serviceEventFragment.description;
    this.nextEventTimestamp = serviceEventFragment.nextEventTimestamp;
    this.eventState = serviceEventFragment.eventState ?? EventState.Active;
    this.submittedBy = serviceEventFragment.submittedBy;
    this.link = this.filterLinkedEvents(serviceEventFragment.link);
  }

  public async submitService(): Promise<void> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      await client.mutate(
        EventsSubmitDocument,
        { payload: this.extractEventPayload() },
      );
    } catch (error) {
      console.error("Error", error);
    }
  }

  private filterLinkedEvents(events: Event[] = []): Event[] {
    return events.map((event: Event) => {
      const newEvent = {
        ...event,
      };
      delete newEvent.__typename;
      return newEvent; 
    });
  }

  private extractEventPayload(): SubmitEventPayload {
    return {
      deviceId: this.deviceId,
      timestamp: this.timestamp.toString(),
      type: this.type,
      eventId: this.eventId,
      submittedBy: this.submittedBy,
      crossingId: this.crossingId,
      factoryId: this.factoryId,
      description: this.description,
      nextEventTimestamp: this.nextEventTimestamp?.toString(),
      eventState: this.eventState,
      updatedTimestamp: this.updatedTimestamp?.toString(),
      factoryName: this.factoryName,
      crossingName: this.crossingName,
      deviceName: this.deviceName,
      link: this.link,
    };
  }
}
