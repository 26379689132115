/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode, Fragment } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import Organization from "../../../../data/organization/Organization";
import { Maybe } from "../../../../types/aliases";
import Loader from "../../../ui/loader";
import ErrorDialog from "../../../ui/error-dialog";
import CustomButton from "../../../ui/custom-button";
import accessControlled from "../../../access-control/access-controlled";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  organization: Organization;
}

interface State {
  loading: boolean;
  displayName: string;
  errorMsg?: string;
}

const ACTextField = accessControlled(TextField, ["organizationsUpdate"]);

const ROW_COL_COUNT = 2;
export default class OrganizationDetails extends Component<Props, State> {
  
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      displayName: props.organization.getName(),
    };
  }

  public async componentDidMount(): Promise<void> {
    this.setState({
      displayName: this.props.organization.getName(),
    });
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      this.setState({
        displayName: this.props.organization.getName(),
      });
    }
  }

  private displayNameChanged = (newInput: string): void => {
    this.setState({ displayName: newInput });
  };

  private handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === "Enter") {
      return this.submitNameChange();
    }
  };

  private handleSubmit = async (): Promise<void> => {
    return this.submitNameChange();
  };

  private async submitNameChange(): Promise<void> {
    this.setState({ loading: true });

    try {
      await this.props.organization.changeName(this.state.displayName);
      this.setState({
        loading: false,
        errorMsg: undefined,
        displayName: this.props.organization.getName(),
      });
    } catch (error) {
      console.error("submitNameChange", error);
      this.setState({ loading: false });
    }
  }

  private cancelNameChange = (): void => {
    this.setState({ displayName: this.props.organization.getName() });
  };
  
  private renderNameControllers(): ReactNode {
    const disabled = this.state.displayName === this.props.organization.getName();
    return (
      <div>
        <CustomButton
          variant="contained"
          color="secondary"
          onClick={this.handleSubmit}
          style={{ marginRight: "2rem" }}
          disabled={disabled}
          data-testid="save-org-name-change-button"
        >
          {translations.common.buttons.save()}
        </CustomButton>
        <CustomButton
          variant="contained"
          color="secondary"
          onClick={this.cancelNameChange}
          disabled={disabled}
          data-testid="cancel-org-name-change-button"
        >
          {translations.common.buttons.cancel()}
        </CustomButton>
      </div>
    );
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <Loader size="small" />
          </TableCell>
        </TableRow>
      );
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={ROW_COL_COUNT}>{translations.admin.texts.organisationDetails()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <ACTextField
                  contextOrganization={this.props.organization}
                  accessDeniedProps={{ disabled: true }}
                  fullWidth={true}
                  variant="outlined"
                  placeholder={translations.admin.inputs.organisationName()}
                  className="organization-name"
                  value={this.state.displayName}
                  onChange={(event): void => this.displayNameChanged(event.currentTarget.value)}
                  onKeyPress={this.handleKeyPress}
                  inputProps={{ "data-testid": "curr-org-name-field" }}
                />
              </TableCell>
              <TableCell align="right" size="small">
                {this.renderNameControllers()}
              </TableCell>
            </TableRow>
            {this.renderLoader()}
          </TableBody>
        </Table>
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={(): void => this.setState({ errorMsg: undefined })}
        />
      </Fragment>
    );
  }
}
