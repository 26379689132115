/* Copyright */
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceGroupParameters } from "../../../data/device/DeviceGroup";
import CrossingIconBlue from "../../../assets/Crossing-Blue-24x24.svg";
import CrossingIconYellow from "../../../assets/Crossing-Yellow-24x24.svg";
import CrossingIconRed from "../../../assets/Crossing-Red-24x24.svg";
import CrossingIconGray from "../../../assets/Crossing-Gray-24x24.svg";
import { RailroadGroup } from "../RailroadGroup";
import { ResourceStatus } from "../../ResourceStatus";
import { EventSet } from "../../../data/events/EventSet";
import { Maybe } from "../../../types/aliases";
import AWSCrossingEventSet from "../../../data/events/AWSCrossingEventSet";
import { RailroadDevice } from "../../devices/RailroadDevice/RailroadDevice";
import Factory from "../Factory/Factory";
import { MainUnitHW } from "../../devices/MainUnitHW/MainUnitHW";

const FIRST_ACTIVE_KEY = "firstActivity";

export default class Crossing extends RailroadGroup {
  public static readonly type = "Crossing";
  private eventSet?: EventSet;

  public constructor(backend: AWSBackend, params: DeviceGroupParameters) {
    super(backend, params);
  }

  public getIcon(): string {
    return CrossingIconBlue;
  }

  public async getStatusIcon(): Promise<string> {
    const status = await this.getResourceStatus();

    switch (status) {
      case ResourceStatus.WARNING:
        return CrossingIconYellow;
      case ResourceStatus.ERROR:
        return CrossingIconRed;
      case ResourceStatus.SERVICE:
        return CrossingIconGray;
      case ResourceStatus.NONE:
      default:
        return this.getIcon();
    }
  }

  public async getResourceStatus(): Promise<ResourceStatus> {
    if (await this.isInServiceMode()) {
      return ResourceStatus.SERVICE; 
    }
    return await super.getResourceStatus();
  }

  public getEventSet(startTimestamp?: number, endTimestamp?: number): EventSet {
    if (!this.eventSet || this.eventSet.getTimePeriod().startTimestamp !== startTimestamp || this.eventSet.getTimePeriod().endTimestamp !== endTimestamp) {
      this.eventSet = new AWSCrossingEventSet(this.getId(), startTimestamp, endTimestamp);
    }
    return this.eventSet;
  }

  public async getParentGroup(): Promise<Maybe<Factory>> {
    const parent = await super.getParentGroup();
    return Factory.instanceOf(parent) ? parent : undefined;
  }

  public getFirstActivity(): Maybe<string> {
    return this.getAttribute(FIRST_ACTIVE_KEY);
  }

  public async getDevices(): Promise<RailroadDevice[]> {
    const devices = await super.getDevices();
    const railroadDevices = devices.filter(RailroadDevice.instanceOf);
    return railroadDevices;
  }

  public childGroupCanBeAdded(): boolean {
    return false;
  }

  public async isInServiceMode(): Promise<boolean> {
    return (await this.getMainUnit())?.getState()?.isServiceMode ?? false;
  }

  public async getMainUnit(): Promise<Maybe<MainUnitHW>> {
    const devices = await this.getDevices();
    return devices.find(MainUnitHW.instanceOf);
  }

  public static instanceOf(value: unknown): value is Crossing {
    return value instanceof Crossing;
  }
}
