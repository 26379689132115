/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Box, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import Device from "../../data/device/Device";
import { DrawerState } from "../../data/utils/Utils";
import { Maybe } from "../../types/aliases";
import DeviceGroup from "../../data/device/DeviceGroup";
import { RailroadGroup } from "../../client/groups/RailroadGroup";
import MapDrawer from "../drawers/map-drawer";
import { Cell, Row } from "../ui/custom-table";
import NavigationContainer from "../ui/navigation-container";
import Loader from "../ui/loader";
import GeneralDataService from "../../services/GeneralDataService";
import { RailroadDevice } from "../../client/devices/RailroadDevice/RailroadDevice";
import Customer from "../../client/groups/Customer/Customer";
import { RoadDevice } from "../../client/devices/RoadDevice/RoadDevice";
import RoadContent from "./components/road-content";
import RailroadContent from "./components/railroad-content";
import NavigationCache from "../../utils/NavigationCache";
import { idFromProps, isDeviceInProps, isGroupInProps, isViewResourcesEnabled, Resource, ResourceType } from "../../utils/NavigationUtils";
import BackendFactory from "../../data/BackendFactory";
import { ResourcePathRouterProps } from "../../types/routerprops";
import { isDefined } from "../../utils/types";
import { ResourceChangeResultHandler } from "../../utils/ResourceChangeResultHandler";
import Factory from "../../client/groups/Factory/Factory";
import Crossing from "../../client/groups/Crossing/Crossing";
import { translations } from "../../generated/translationHelper";
import ReceiverManager from "../../data/utils/receivers/ReceiverManager";

type Props = RouteComponentProps<ResourcePathRouterProps>;

interface State {
  selectedResource?: Device | DeviceGroup;
  parentResource?: DeviceGroup;
  viewedResource?: Device | DeviceGroup;
  devices?: Device[];
  groups?: DeviceGroup[];
  drawerState: DrawerState;
  isLoading: boolean;
  summary?: [string, string, string];
}

export interface TableContent {
  title?: string;
  header?: Cell[];
  data: Row[];
}

const RESET_STATE = { drawerState: DrawerState.Open, selectedResource: undefined, parentResource: undefined, viewedResource: undefined, devices: undefined, groups: undefined, summary: undefined };

class StatusView extends Component<Props, State> {

  private readonly resourceChangeHandler = new ResourceChangeResultHandler({
    stateResetCallback: (): Promise<void> => this.setRootGroups(),
    resourceChangeCallback: (resource: Resource): Promise<void> => this.changeResource(resource),
  });

  public constructor(props: Props) {
    super(props);

    this.state = {
      drawerState: DrawerState.Open,
      isLoading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    const restoredResource = await NavigationCache.getInstance().navigateToCachedIfNoResourceInPath(this.props);

    if (restoredResource) {
      const device = NavigationCache.getInstance().getSelectedDevice();
      const group = NavigationCache.getInstance().getSelectedGroup();

      if (device) {
        await Promise.allSettled([this.setDeviceSelection(device), this.setResourcesOfDevice(device)]);
      } else if (group) {
        await Promise.allSettled([this.setDeviceGroupSelection(group), this.setResourcesOfParentGroup(group)]);
      }
    } else if (!isDeviceInProps(this.props) && !isGroupInProps(this.props)) {
      await this.setRootGroups();
    } else {
      const id = idFromProps(this.props);

      if (id && isDeviceInProps(this.props)) await Promise.allSettled([this.setDeviceSelection(id), this.setResourcesOfDevice(id)]);
      else if (id && isGroupInProps(this.props) && isViewResourcesEnabled(this.props)) await Promise.allSettled([this.setDeviceGroupSelection(id), this.setResourcesOfGroup(id)]);
      else if (id && isGroupInProps(this.props)) await Promise.allSettled([this.setDeviceGroupSelection(id), this.setResourcesOfParentGroup(id)]);
    }
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    await NavigationCache.getInstance().resolveResourceChange(this.resourceChangeHandler, this.props, prevProps);
  }

  public async componentWillUnmount(): Promise<void> {
    await NavigationCache.getInstance().setCurrentGroup();
  }

  private async setRootGroups(): Promise<void> {
    this.setState({ ...RESET_STATE, isLoading: true });
    const rootGroups = await BackendFactory.getBackend().getRootDeviceGroups();
    const entryDevicePromises = rootGroups.map((rootGroup) => rootGroup.getDevices());
    const entryDevices = (await Promise.allSettled(entryDevicePromises)).flatMap((mainDevicePromise) => mainDevicePromise.status === "fulfilled" ? mainDevicePromise.value : undefined).filter(isDefined);
    const rootChildGroupPromises = rootGroups.map((rootGroup) => rootGroup.getGroups());
    const rootChildGroups = (await Promise.allSettled(rootChildGroupPromises)).flatMap((mainGroupPromise) => mainGroupPromise.status === "fulfilled" ? mainGroupPromise.value : undefined).filter(isDefined);
    const entryGroupPromises = rootChildGroups.map((mainGroup) => mainGroup.getGroups());
    const entryGroups = (await Promise.allSettled(entryGroupPromises)).flatMap((childGroupPromise) => childGroupPromise.status === "fulfilled" ? childGroupPromise.value : undefined).filter(isDefined);
    ReceiverManager.instance.replaceReceivers({
      toAdd: entryGroups.map(group => group.getId()),
      toRemove: this.state.groups?.map(group => group.getId()),
    });
    this.setState({ isLoading: false, groups: entryGroups, devices: entryDevices });
  }

  private async setResourcesOfDevice(deviceOrId: string | Device): Promise<void> {
    const device = typeof deviceOrId === "string" ? await BackendFactory.getBackend().getDevice(deviceOrId) : deviceOrId;

    if (!device) {
      console.warn(`Device ${deviceOrId} not found!`);
      return;
    }
    // in theory device can belong to several parent groups
    const parentGroups = await device.getGroups();
    const [devicesPromise, groupsPromise] = await Promise.allSettled([parentGroups[0].getDevices(), parentGroups[0].getGroups()]);
    if (devicesPromise.status === "fulfilled" && groupsPromise.status === "fulfilled") this.setState({ devices: devicesPromise.value, groups: groupsPromise.value });
  }

  private async setResourcesOfParentGroup(groupOrId: string | DeviceGroup): Promise<void> {
    const group = typeof groupOrId === "string" ? await BackendFactory.getBackend().getDeviceGroup(groupOrId) : groupOrId;

    if (!group) {
      console.warn(`Group ${groupOrId} not found!`);
      return;
    }

    const parent = await group.getParentGroup();

    if (!parent) {
      console.warn(`Group ${groupOrId} parent not found!`);
      return;
    }

    const [devicesPromise, groupsPromise] = await Promise.allSettled([parent.getDevices(), parent.getGroups()]);
    if (devicesPromise.status === "fulfilled" && groupsPromise.status === "fulfilled") this.setState({ devices: devicesPromise.value, groups: groupsPromise.value });
  }

  private async setResourcesOfGroup(groupOrId: string | DeviceGroup): Promise<void> {
    const group = typeof groupOrId === "string" ? await BackendFactory.getBackend().getDeviceGroup(groupOrId) : groupOrId;

    if (!group) {
      console.warn(`Group ${groupOrId} not found!`);
      return;
    }
    const [devicesPromise, groupsPromise] = await Promise.allSettled([group.getDevices(), group.getGroups()]);
    if (devicesPromise.status === "fulfilled" && groupsPromise.status === "fulfilled") this.setState({ devices: devicesPromise.value, groups: groupsPromise.value });
  }

  private async setDeviceSelection(deviceOrId: string | Device): Promise<void> {
    this.setState({ isLoading: true });
    const device = typeof deviceOrId === "string" ? await BackendFactory.getBackend().getDevice(deviceOrId) : deviceOrId;

    if (device) {
      const parentGroup = (await device.getGroups())?.[0];
      this.setState({ selectedResource: device, viewedResource: device, parentResource: parentGroup });
      await this.fetchSummary(device, parentGroup);
    } else {
      await NavigationCache.getInstance().navigateToDevice(this.props);
    }
    this.setState({ isLoading: false });
  }

  private async setDeviceGroupSelection(groupOrId: string | DeviceGroup): Promise<void> {
    this.setState({ isLoading: true });
    const group = typeof groupOrId === "string" ? await BackendFactory.getBackend().getDeviceGroup(groupOrId) : groupOrId;

    if (group) {
      const parentGroup = await group.getParentGroup();
      if (isViewResourcesEnabled(this.props)) this.setState({ selectedResource: group, viewedResource: group, parentResource: parentGroup });
      else this.setState({ selectedResource: group, viewedResource: parentGroup, parentResource: parentGroup });
      await this.fetchSummary(group, parentGroup);
    } else {
      await NavigationCache.getInstance().navigateToGroup(this.props);
    }
    this.setState({ isLoading: false });
  }

  private async fetchSummary(resource: Device | DeviceGroup, parentGroup?: DeviceGroup): Promise<void> {
    this.setState({ isLoading: true });

    if (RailroadDevice.instanceOf(resource) || RailroadGroup.instanceOf(resource)) {
      const factorySummary = await GeneralDataService.getFactorySummary(resource);
      this.setState({ summary: [ factorySummary?.customer?.getName() ?? "", factorySummary?.factory?.getName() ?? "", translations.common.texts.crossings({ count: factorySummary?.amountOfCrossings ?? 0 }) ] }); 
    } else if (RoadDevice.instanceOf(resource)) {
      const displayName = resource.getState()?.displayName;
      const id = resource.getId();
      this.setState({ summary: [parentGroup?.getName() ?? "", id ?? "", displayName ?? "" ] });
    }
    this.setState({ isLoading: false });
  }

  private async changeResource(resource: Resource): Promise<void> {
    if (resource.type === ResourceType.Device) {
      await Promise.allSettled([this.setDeviceSelection(resource.id), this.setResourcesOfDevice(resource.id)]);
    } else if (resource.type === ResourceType.Group) {
      if (isViewResourcesEnabled(this.props)) await Promise.allSettled([this.setDeviceGroupSelection(resource.id), this.setResourcesOfGroup(resource.id)]);
      else await Promise.allSettled([this.setDeviceGroupSelection(resource.id), this.setResourcesOfParentGroup(resource.id)]);
    }
  }

  private handleBackClicked = async (): Promise<void> => {
    const parentGroup = this.state.parentResource;
    const rootGroups = await BackendFactory.getBackend().getRootDeviceGroups();
    const isParentRootOrCustomerGroup = Customer.instanceOf(parentGroup) || rootGroups.some((rootGroup) => rootGroup.getId() === parentGroup?.getId());

    if (RailroadGroup.instanceOf(this.state.selectedResource) && isViewResourcesEnabled(this.props)) {
      NavigationCache.getInstance().navigateToGroup(this.props, this.state.selectedResource);
    } else if ((DeviceGroup.instanceOf(this.state.selectedResource) || Device.instanceOf(this.state.selectedResource)) && !isParentRootOrCustomerGroup) {
      NavigationCache.getInstance().navigateToGroup(this.props, parentGroup);
    } else if (isParentRootOrCustomerGroup) {
      NavigationCache.getInstance().navigateToGroup(this.props);
    }
  };

  private handleForwardClicked = (): void => {
    if (Factory.instanceOf(this.state.selectedResource) || Crossing.instanceOf(this.state.selectedResource)) {
      NavigationCache.getInstance().navigateToGroup(this.props, this.state.selectedResource, { viewResources: true });
    } else {
      NavigationCache.getInstance().navigateToGroup(this.props);
    }
  };

  private backDisabled = (): boolean => {
    const parent = this.state.parentResource;
    const enabled = (parent && (Customer.instanceOf(parent) || RailroadGroup.instanceOf(parent) || DeviceGroup.instanceOf(parent))) || isViewResourcesEnabled(this.props);
    return !enabled;
  };

  private forwardDisabled = (): boolean => {
    return !RailroadGroup.instanceOf(this.state.selectedResource) || isViewResourcesEnabled(this.props);
  };

  private renderRailroadContent = (): Maybe<JSX.Element> => {
    if (this.state.selectedResource && (RailroadDevice.instanceOf(this.state.selectedResource) || RailroadGroup.instanceOf(this.state.selectedResource)))
      return (
        <RailroadContent
          resource={this.state.selectedResource}
        />
      );
  };

  private renderRoadContent = (): Maybe<JSX.Element> => {
    if (this.state.selectedResource && RoadDevice.instanceOf(this.state.selectedResource))
      return (
        <RoadContent
          resource={this.state.selectedResource}
        />
      );
  };

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.state.isLoading) {
      return <Loader/>;
    }
  };

  private renderNoSelectionContent = (): Maybe<JSX.Element> => {
    if (!this.state.selectedResource) {
      return (
        <Grid container justifyContent="center" style={{ marginTop: 100 }}>
          <Grid item>
            <Typography variant="subtitle1">{translations.common.texts.pleaseSelectResource()}</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  private renderNavigationContainer = (): Maybe<JSX.Element> => {
    if (this.state.summary) {
      return (
        <NavigationContainer
          titles={this.state.summary}
          backDisabled={this.backDisabled()}
          forwardDisabled={this.forwardDisabled()}
          onBackClicked={this.handleBackClicked}
          onForwardClicked={this.handleForwardClicked}
        >
          {this.renderRailroadContent() || this.renderRoadContent()}
        </NavigationContainer>
      );
    }
  };

  private renderContent = (): JSX.Element => {
    return this.renderNoSelectionContent() ?? this.renderLoader() ?? (
      <Box m={3} className="box-root">
        {this.renderNavigationContainer()}
      </Box>
    );
  };

  public render(): JSX.Element {
    return (
      <MapDrawer
        devices={this.state.devices}
        groups={this.state.groups}
        zoomToResource={this.state.viewedResource}
        isSelectionEnabled={true}
      >
        {this.renderContent()}
      </MapDrawer>
    );
  }
}

export default withRouter(StatusView);
