/* Copyright */
import DeviceState from "../../../data/device/DeviceState";
import { Maybe } from "../../../types/aliases";
import { isDefined } from "../../../utils/types";
import { isSignDirection, SignDirection, SpeedLimitSignHWStateProperties } from "./SpeedLimitSignHWStateProperties";

interface TurnSchedule<TKind extends string> {
  time: string;
  side: SignDirection;
  kind: TKind;
}

export type Weekday = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
export interface WeekdaySchedule extends TurnSchedule<"Weekday"> {
  weekday: Weekday;
}
export interface DateSchedule extends TurnSchedule<"Date"> {
  date: string;
}

interface Handlers<T> {
  getter: () => Maybe<T[]>;
  setter: (value?: T[]) => void;
}

export const WEEKDAYS: Weekday[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
const SCHEDULE_SEPARATOR = ";";
const DATE_TIME_SEPARATOR = "#";
const TIME_SIDE_SEPARATOR = "_";
const SCHEDULE_SIZE_LIMIT = 6;

export class SpeedLimitSignHWState extends DeviceState<SpeedLimitSignHWStateProperties> {

  public get dateSchedules(): DateSchedule[] {
    return [...(this.getDate1() ?? []), ...(this.getDate2() ?? [])];
  }

  public get weekdaySchedules(): WeekdaySchedule[] {
    return WEEKDAYS.map((weekday) => this.getWeekdayHandlers(weekday).getter()).filter(isDefined).flat();
  }

  public addWeekdaySchedules(schedules: WeekdaySchedule | WeekdaySchedule[]): void {
    const newSchedules = Array.isArray(schedules) ? schedules : [schedules];
    newSchedules.forEach((schedule) => {
      const { getter, setter } = this.getWeekdayHandlers(schedule.weekday);
      const currentSchedules = [...(getter() ?? [])];

      if (currentSchedules.length >= SCHEDULE_SIZE_LIMIT) {
        throw new Error("Out of weekday scheduling space!");
      }

      const index = SpeedLimitSignHWState.findScheduleIndexFromSchedules(schedule, currentSchedules);

      if (index === -1) {
        currentSchedules.push(schedule);
        setter(currentSchedules);
      }
    });
  }

  public addDateSchedules(schedules: DateSchedule | DateSchedule[]): void {
    const newSchedules = Array.isArray(schedules) ? schedules : [schedules];
    const dateSchedules1 = [...(this.getDate1() ?? [])];
    const dateSchedules2 = [...(this.getDate2() ?? [])];
    newSchedules.forEach((schedule) => {
      const isDateValueSameInExistingSchedules1 = dateSchedules1.every((dateSchedule) => dateSchedule.date === schedule.date);
      const isDateValueSameInExistingSchedules2 = dateSchedules2.every((dateSchedule) => dateSchedule.date === schedule.date);
      const index1 = SpeedLimitSignHWState.findScheduleIndexFromSchedules(schedule, dateSchedules1);
      const index2 = SpeedLimitSignHWState.findScheduleIndexFromSchedules(schedule, dateSchedules2);

      // Ensure that date schedule not exists in any of the memory places
      if (index1 === -1 && index2 === -1) {
        // Ensure that both memory places are not full
        if (dateSchedules1.length >= SCHEDULE_SIZE_LIMIT && dateSchedules2.length >= SCHEDULE_SIZE_LIMIT) {
          throw new Error("Out of date scheduling space!");
        } else if (!isDateValueSameInExistingSchedules1 && !isDateValueSameInExistingSchedules2) {
          throw new Error("Date schedules can contain only two different date values!");
        } else if (dateSchedules1.length < SCHEDULE_SIZE_LIMIT && isDateValueSameInExistingSchedules1) {
          dateSchedules1.push(schedule);
          this.setDate1(dateSchedules1);
        } else if (dateSchedules2.length < SCHEDULE_SIZE_LIMIT && isDateValueSameInExistingSchedules2) {
          dateSchedules2.push(schedule);
          this.setDate2(dateSchedules2);
        } else {
          throw new Error("Failed to add date schedule!");
        }
      }
    });
  }

  public removeWeekdaySchedules(schedules: WeekdaySchedule | WeekdaySchedule[]): void {
    const removedSchedules = Array.isArray(schedules) ? schedules : [schedules];
    removedSchedules.forEach((schedule) => {
      const { getter, setter } = this.getWeekdayHandlers(schedule.weekday);
      const schedules = [...(getter() ?? [])];

      const index = SpeedLimitSignHWState.findScheduleIndexFromSchedules(schedule, schedules);

      if (index !== -1) {
        schedules.splice(index, 1);
        setter(schedules);
      }
    });
  }

  public removeDateSchedules(schedules: DateSchedule | DateSchedule[]): void {
    const removedSchedules = Array.isArray(schedules) ? schedules : [schedules];
    const dateSchedules1 = [...(this.getDate1() ?? [])];
    const dateSchedules2 = [...(this.getDate2() ?? [])];
    removedSchedules.forEach((schedule) => {
      const index1 = SpeedLimitSignHWState.findScheduleIndexFromSchedules(schedule, dateSchedules1);
      const index2 = SpeedLimitSignHWState.findScheduleIndexFromSchedules(schedule, dateSchedules2);

      if (index1 !== -1) {
        // If schedule exists in first memory place remove it from there
        dateSchedules1.splice(index1, 1);
        this.setDate1(dateSchedules1);
      } else if (index2 !== -1) {
        // If schedule exists in second memory place remove it from there
        dateSchedules2.splice(index2, 1);
        this.setDate2(dateSchedules2);
      } else {
        throw new Error("Failed to remove date schedule");
      }
    });
  }

  public revert(): void {
    this.changedValues = {};
  }

  private getMonday(): Maybe<WeekdaySchedule[]> {
    if (this.changedValues.monday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.changedValues.monday, "monday");
    else if (this.reported.monday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.desired.monday ?? this.reported.monday, "monday");
    return undefined;
  }

  private setMonday(value?: WeekdaySchedule[]): void {
    if (value) this.changedValues.monday = SpeedLimitSignHWState.joinTurnSchedules(value);
    else this.changedValues.monday = null;
  }

  private getTuesday(): Maybe<WeekdaySchedule[]> {
    if (this.changedValues.tuesday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.changedValues.tuesday, "tuesday");
    else if (this.reported.tuesday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.desired.tuesday ?? this.reported.tuesday, "tuesday");
    return undefined;
  }

  private setTuesday(value?: WeekdaySchedule[]): void {
    if (value) this.changedValues.tuesday = SpeedLimitSignHWState.joinTurnSchedules(value);
    else this.changedValues.tuesday = null;
  }

  private getWednesday(): Maybe<WeekdaySchedule[]> {
    if (this.changedValues.wednesday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.changedValues.wednesday ?? "", "wednesday");
    else if (this.reported.wednesday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.desired.wednesday ?? this.reported.wednesday, "wednesday");
    return undefined;
  }

  private setWednesday(value?: WeekdaySchedule[]): void {
    if (value) this.changedValues.wednesday = SpeedLimitSignHWState.joinTurnSchedules(value);
    else this.changedValues.wednesday = null;
  }

  private getThursday(): Maybe<WeekdaySchedule[]> {
    if (this.changedValues.thursday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.changedValues.thursday ?? "", "thursday");
    else if (this.reported.thursday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.desired.thursday ?? this.reported.thursday, "thursday");
    return undefined;
  }

  private setThursday(value?: WeekdaySchedule[]): void {
    if (value) this.changedValues.thursday = SpeedLimitSignHWState.joinTurnSchedules(value);
    else this.changedValues.thursday = null;
  }

  private getFriday(): Maybe<WeekdaySchedule[]> {
    if (this.changedValues.friday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.changedValues.friday ?? "", "friday");
    else if (this.reported.friday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.desired.friday ?? this.reported.friday, "friday");
    return undefined;
  }

  private setFriday(value?: WeekdaySchedule[]): void {
    if (value) this.changedValues.friday = SpeedLimitSignHWState.joinTurnSchedules(value);
    else this.changedValues.friday = null;
  }

  private getSaturday(): Maybe<WeekdaySchedule[]> {
    if (this.changedValues.saturday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.changedValues.saturday ?? "", "saturday");
    else if (this.reported.saturday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.desired.saturday ?? this.reported.saturday, "saturday");
    return undefined;
  }

  private setSaturday(value?: WeekdaySchedule[]): void {
    if (value) {
      this.changedValues.saturday = SpeedLimitSignHWState.joinTurnSchedules(value);
    }
    else this.changedValues.saturday = null;
  }

  private getSunday(): Maybe<WeekdaySchedule[]> {
    if (this.changedValues.sunday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.changedValues.sunday ?? "", "sunday");
    else if (this.reported.sunday != null) return SpeedLimitSignHWState.splitWeekdayScheduleString(this.desired.sunday ?? this.reported.sunday, "sunday");
    return undefined;
  }

  private setSunday(value?: WeekdaySchedule[]): void {
    if (value) this.changedValues.sunday = SpeedLimitSignHWState.joinTurnSchedules(value);
    else this.changedValues.sunday = null;
  }

  private getDate1(): Maybe<DateSchedule[]> {
    if (this.changedValues.yearlyDay1 != null) return SpeedLimitSignHWState.splitDateScheduleString(this.changedValues.yearlyDay1 ?? "");
    else if (this.reported.yearlyDay1 != null) return SpeedLimitSignHWState.splitDateScheduleString(this.desired.yearlyDay1 ?? this.reported.yearlyDay1);
    return undefined;
  }

  private setDate1(value: Maybe<DateSchedule[]>): void {
    if (value) this.changedValues.yearlyDay1 = SpeedLimitSignHWState.joinTurnSchedules(value);
    else this.changedValues.yearlyDay1 = null;
  }

  private getDate2(): Maybe<DateSchedule[]> {
    if (this.changedValues.yearlyDay2 != null) return SpeedLimitSignHWState.splitDateScheduleString(this.changedValues.yearlyDay2 ?? "");
    else if (this.reported.yearlyDay2 != null) return SpeedLimitSignHWState.splitDateScheduleString(this.desired.yearlyDay2 ?? this.reported.yearlyDay2);
    return undefined;
  }

  private setDate2(value?: Maybe<DateSchedule[]>): void {
    if (value) this.changedValues.yearlyDay2 = SpeedLimitSignHWState.joinTurnSchedules(value);
    else this.changedValues.yearlyDay2 = null;
  }

  private getWeekdayHandlers(weekday: Weekday): Handlers<WeekdaySchedule> {
    switch (weekday) {
      case "monday":
        return { getter: (): Maybe<WeekdaySchedule[]> => this.getMonday(), setter: (value?: WeekdaySchedule[]): void => this.setMonday(value) };
      case "tuesday":
        return { getter: (): Maybe<WeekdaySchedule[]> => this.getTuesday(), setter: (value?: WeekdaySchedule[]): void => this.setTuesday(value) };
      case "wednesday":
        return { getter: (): Maybe<WeekdaySchedule[]> => this.getWednesday(), setter: (value?: WeekdaySchedule[]): void => this.setWednesday(value) }; 
      case "thursday":
        return { getter: (): Maybe<WeekdaySchedule[]> => this.getThursday(), setter: (value?: WeekdaySchedule[]): void => this.setThursday(value) }; 
      case "friday":
        return { getter: (): Maybe<WeekdaySchedule[]> => this.getFriday(), setter: (value?: WeekdaySchedule[]): void => this.setFriday(value) }; 
      case "saturday":
        return { getter: (): Maybe<WeekdaySchedule[]> => this.getSaturday(), setter: (value?: WeekdaySchedule[]): void => this.setSaturday(value) };
      case "sunday":
        return { getter: (): Maybe<WeekdaySchedule[]> => this.getSunday(), setter: (value?: WeekdaySchedule[]): void => this.setSunday(value) }; 
    }
  }

  private static splitWeekdayScheduleString(schedule: string, weekday: Weekday): WeekdaySchedule[] {
    return SpeedLimitSignHWState.splitScheduleString(schedule).filter(SpeedLimitSignHWState.isWeekdaySchedule).map((schedule) => ({
      ...schedule,
      weekday,
    }));
  }

  private static splitDateScheduleString(schedule: string): DateSchedule[] {
    return SpeedLimitSignHWState.splitScheduleString(schedule).filter(SpeedLimitSignHWState.isDateSchedule);
  }

  private static splitScheduleString(schedule: string): TurnSchedule<string>[] {
    const scheduleStrings = schedule ? schedule.split(SCHEDULE_SEPARATOR) : [];
    const turnSchedules: TurnSchedule<string>[] = [];

    let defaultDateString: Maybe<string> = undefined;

    for (const scheduleString of scheduleStrings) {
      const dateString: Maybe<string> = (scheduleString.match(/\d{2}[.]\d{2}/) ?? [])[0] || defaultDateString || undefined;

      if (!defaultDateString) {
        // Date schedule string differs from weekday schedule string format, so we have to do some additional magic to cope with it.
        defaultDateString = dateString;
      }
      const timeString = (scheduleString.match(/(\d{2}[:]*){3}/) ?? [])[0];
      const directionString = (scheduleString.match(/[AB]/) ?? [])[0];

      if (!timeString || !isSignDirection(directionString)) {
        if (!isSignDirection(directionString)) console.warn(`Invalid sign direction ${directionString} in schedule ${scheduleString}`);
        if (!timeString) console.warn(`Invalid time ${timeString} in schedule ${scheduleString}`);
        continue;
      }
      const schedule = {
        date: dateString,
        time: timeString,
        side: directionString,
        kind: dateString ? "Date" : "Weekday",
      };
      turnSchedules.push(schedule);
    }
    return turnSchedules;
  }

  private static joinTurnSchedules<TSchedule extends TurnSchedule<"Date" | "Weekday">>(schedules: TSchedule[]): string {
    let date: Maybe<string> = undefined;
    const scheduleStrings = schedules.sort(SpeedLimitSignHWState.sortByAscendingTime).map((turnSchedule) => {
      let scheduleString = "";

      if (SpeedLimitSignHWState.isDateSchedule(turnSchedule) && turnSchedule.date && !date) {
        // Date schedule string differs from weekday schedule string format, so we have to do some additional magic to cope with it.
        date = turnSchedule.date;
        scheduleString = scheduleString.concat(`${turnSchedule.date}${DATE_TIME_SEPARATOR}`);
      }
      scheduleString = scheduleString.concat(`${turnSchedule.time}${TIME_SIDE_SEPARATOR}`, `${turnSchedule.side}`);
      return scheduleString;
    });
    return scheduleStrings.join(SCHEDULE_SEPARATOR);
  }

  private static sortByAscendingTime(a: TurnSchedule<string>, b: TurnSchedule<string>): number {
    return a.time === b.time ? 0 : a.time > b.time ? 1 : -1;
  }

  private static findScheduleIndexFromSchedules(schedule: DateSchedule | WeekdaySchedule, schedules: DateSchedule[] | WeekdaySchedule[]): number {
    return schedules.findIndex((refSchedule: DateSchedule | WeekdaySchedule) => JSON.stringify(schedule) === JSON.stringify(refSchedule));
  }

  private static isDateSchedule(schedule: TurnSchedule<string>): schedule is DateSchedule {
    return schedule.kind === "Date";
  }

  private static isWeekdaySchedule(schedule: TurnSchedule<string>): schedule is WeekdaySchedule {
    return schedule.kind === "Weekday";
  }
}
