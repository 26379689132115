/* Copyright */
import { Service } from "../backend/AppSyncClientProvider";
import EventSet, { EventObserver } from "./EventSet";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Nullable } from "../../types/aliases";
import { Event, EventsDeactivateDocument, EventState, FactoryEventsListDocument } from "../../generated/gqlEvents";
import EventsSubscriptionManager from "./EventsSubscriptionManager";
import { toNumber } from "../../utils/functions";
import { eventIdentitiesMatch } from "./Event";

export default class AWSFactoryEventSet extends EventSet {

  public constructor(groupId: string, startTimestamp?: number, endTimestamp?: number) {
    super(groupId, startTimestamp, endTimestamp);
  }

  public async fetch(count?: number): Promise<void> {
    let nextToken: Nullable<string> = null;
    let events: Event[] = [];

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const { startTimestamp, endTimestamp } = this.getTimePeriod();
        const eventsResponse = await client.query(
          FactoryEventsListDocument,
          {
            groupId: this.getId(),
            period: startTimestamp && endTimestamp ? {
              startTimestamp: startTimestamp.toString(),
              endTimestamp: endTimestamp.toString(),
            } : undefined,
            count,
            nextToken,
          },
          {
            fetchPolicy: "network-only",
          },
        );
        // cast is required or response's type inference goes into a loop
        nextToken = (eventsResponse.data.factoryEventsList?.nextToken ?? null) as Nullable<string>;
        events = events.concat(eventsResponse.data.factoryEventsList?.events ?? []);
      } while (nextToken);
      this.events = events.sort(EventSet.eventOrdering);
    } catch (error) {
      console.error("Failed to fetch factory events", error);
    }
  }

  public addOrUpdateEvent(event: Event): void {
    const matchIndex = (this.events ?? []).findIndex((e: Event) => eventIdentitiesMatch(event, e));

    if (this.events && matchIndex >= 0) {
      this.events[matchIndex] = event;
    } else {
      this.addEvent(event);
    }
    this.notifyAction(observer => observer.onEventSetUpdate(this));
  }

  public async deactivateEvent(event: Event): Promise<void> {
    // Set inactive for fast UI update. State will recover via subs if deactivation fails in cloud side
    event.eventState = EventState.Inactive;
    const eventPayload = {
      deviceId: event.deviceId,
      timestamp: event.timestamp,
    };

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      await client.mutate(
        EventsDeactivateDocument,
        {
          payload: eventPayload,
        },
      );
    } catch (error) {
      console.log(error);
    }
  }

  public async addObserver(observer: EventObserver): Promise<void> {
    if (!this.events) {
      await this.fetch();
    }
    super.addObserver(observer);
    EventsSubscriptionManager.instance.addListener(this);
  }

  public removeObserver(observer: EventObserver): void {
    super.removeObserver(observer);
    EventsSubscriptionManager.instance.removeListener(this);
  }
  
  private addEvent(event: Event): void {
    if (!this.events) this.events = [];
    this.events.push(event);
    this.events.sort(EventSet.eventOrdering);
    const timestamp = toNumber(event.timestamp);
    const { endTimestamp } = this.getTimePeriod();

    if (endTimestamp && timestamp && timestamp > endTimestamp) {
      this.getTimePeriod().endTimestamp = timestamp;
    }
  }
}
