/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Maybe } from "../../types/aliases";
import { Event } from "../../generated/gqlEvents";
import BaseObservable from "../observer/BaseObservable";
import TimeSeriesSet from "../utils/TimeSeriesSet";
import TimePeriod from "../utils/TimePeriod";
import { toNumber } from "../../utils/functions";

export interface EventObserver {
  onEventSetUpdate(eventSet: EventSet): void;
}

export abstract class EventSet extends BaseObservable<EventObserver> implements TimeSeriesSet<Event>{
  private readonly id: string;
  private readonly startTimestamp?: number;
  private readonly endTimestamp?: number;
  protected events?: Event[];

  protected constructor(id: string, startTimestamp?: number, endTimestamp?: number) {
    super();
    this.id = id;
    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
  }

  public getId(): string {
    return this.id;
  }

  public getData(): Maybe<Event[]> {
    return this.events;
  }

  public getTimePeriod(): Partial<TimePeriod> {
    return { startTimestamp: this.startTimestamp, endTimestamp: this.endTimestamp };
  }

  public abstract fetch(count?: number): Promise<void>;
  
  public abstract addOrUpdateEvent(data: Event): void;

  public abstract deactivateEvent(event: Event): Promise<void>;

  public static eventOrdering(a: Event, b: Event): number {
    const at = toNumber(a.timestamp);
    const bt = toNumber(b.timestamp);
    if (at === undefined && bt === undefined) return 0;
    if (at === undefined) return -1;
    if (bt === undefined) return 1;
    return at - bt;
  }
}

export default EventSet;
