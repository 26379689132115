/* Copyright */

import { Maybe } from "../types/aliases";
import Crossing from "../client/groups/Crossing/Crossing";
import Customer from "../client/groups/Customer/Customer";
import Factory from "../client/groups/Factory/Factory";
import Device from "../data/device/Device";
import DeviceGroup from "../data/device/DeviceGroup";

export interface FactorySummary {
  customer?: Customer;
  factory?: Factory;
  amountOfCrossings?: number;
}

/**
 * GeneralDataService provides data for views to show in various tables.
 */
export default class GeneralDataService {
  /**
   * Get a summary for a factory. Summary lists the owner of the factory,
   * factory name and amount of crossings in a factory.
   * @param resource Resource should be Device or DeviceGroup
   */
  public static async getFactorySummary(resource: Device | DeviceGroup): Promise<Maybe<FactorySummary>> {
    let factory: Maybe<Factory> = undefined;
  
    if (Factory.instanceOf(resource)) {
      factory = resource;
    }
  
    if (Crossing.instanceOf(resource)) {
      factory = await resource.getParentGroup();
    }
  
    if (Device.instanceOf(resource)) {
      const parent = await resource.getParentGroup();
  
      if (Crossing.instanceOf(parent)) {
        factory = await parent.getParentGroup();
      }
  
      if (Factory.instanceOf(parent)) {
        factory = parent;
      }
    }
  
    if (factory) {
      const customer = await factory.getParentGroup();
      const amountOfCrossings = (await factory.getGroups()).length;
  
      return {
        factory,
        customer,
        amountOfCrossings,
      };
    }
  }
}
