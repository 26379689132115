/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Device, { DeviceParameters } from "./Device";
import { Maybe } from "../../types/aliases";
import { HasEntityRelations } from "../utils/EntityRelationCache";
import AWSBackend from "../backend/AWSBackend";
import DeviceFactory from "./DeviceFactory";
import { MainUnitHW } from "../../client/devices/MainUnitHW/MainUnitHW";
import { CapsuleHW } from "../../client/devices/CapsuleHW/CapsuleHW";
import { SignHW } from "../../client/devices/SignHw/SignHW";
import { SpeedLimitSignHW } from "../../client/devices/SpeedLimitSignHW/SpeedLimitSignHW";

const DEVICE_CONSTRUCTORS = {
  [MainUnitHW.type]: MainUnitHW,
  [CapsuleHW.type]: CapsuleHW,
  [SignHW.type]: SignHW,
  [SpeedLimitSignHW.type]: SpeedLimitSignHW,
};

type DeviceType = keyof typeof DEVICE_CONSTRUCTORS;

export default class AWSDeviceFactory implements DeviceFactory<AWSBackend>{
  public createDevice(backend: AWSBackend, type: DeviceType, params: DeviceParameters): Maybe<Device & HasEntityRelations> {
    const ctor = DEVICE_CONSTRUCTORS[type];

    if (ctor) {
      return new ctor(backend, params);
    } else {
      console.error(`Could not locate device implementation for type ${type}`);
    }
  }
}
