/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { timePeriodIsEmpty } from "../data/utils/TimePeriod";
import { ResourceChangeType, ResourceRouterProps, parseResourceAndTime, Resource, ResourceType } from "./NavigationUtils";
import NavigationCache, { ResourceChangeHandler } from "./NavigationCache";

interface ResourceChangeResultHandlerParams {
  stateResetCallback: () => void;
  resourceChangeCallback: (resource: Resource, params?: Record<string, unknown>) => Promise<void>;
}

export class ResourceChangeResultHandler implements ResourceChangeHandler {
  private params: ResourceChangeResultHandlerParams;

  private readonly resourceResolveHandlers: Record<ResourceChangeType, (props: ResourceRouterProps) => Promise<void>> = {
    [ResourceChangeType.ChangedToNone]: async (props) => {
      const info = parseResourceAndTime(props);
      this.params.stateResetCallback();

      if (!timePeriodIsEmpty(info.timePeriod)) {
        await NavigationCache.getInstance().navigateToDeviceAndTime(props);
      }
    },
    [ResourceChangeType.ChangedToNew]: async (props) => {
      if (props.match.params.id && props.match.params.type) {
        await this.params.resourceChangeCallback({ id: props.match.params.id, type: props.match.params.type });
      }
    },
    [ResourceChangeType.StayedNone]: async (_props) => {
      // NO-OP: nothing needs to be done here, since nothing changes
    },
    [ResourceChangeType.StayedSame]: async (_props) => {
      // NO-OP: nothing needs to be done here, since nothing changes
    },
    [ResourceChangeType.WillRestore]: async () => {
      const deviceId = NavigationCache.getInstance().getSelectedDevice()?.getId();
      const groupId = NavigationCache.getInstance().getSelectedGroup()?.getId();
      if (deviceId) await this.params.resourceChangeCallback({ id: deviceId, type: ResourceType.Device });
      else if (groupId) await this.params.resourceChangeCallback({ id: groupId, type: ResourceType.Group });
    },
  };

  public constructor(params: ResourceChangeResultHandlerParams) {
    this.params = params;
  }

  public async handle(result: ResourceChangeType, props: ResourceRouterProps): Promise<void> {
    await this.resourceResolveHandlers[result]?.(props);
  }
}
