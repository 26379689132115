/* Copyright */
import { Event } from "../../generated/gqlEvents";

export interface EventRule {
  ruleId: string;
  eventId: string;
  name: string;
  rule: string;
  templates?: string | null;
}

export function eventIdentitiesMatch(e1: Event, e2: Event): boolean {
  return e1.deviceId === e2.deviceId && e1.factoryId === e2.factoryId && e1.crossingId === e2.crossingId && e1.eventId === e2.eventId && e1.timestamp === e2.timestamp;
}
