/* Copyright */
import { PartialNull } from "../../../types/aliases";
import { DeviceStateProperties } from "../../../data/device/DeviceStateProperties";

export class MainUnitHWStateProperties extends DeviceStateProperties {
  public isServiceMode: boolean;

  public constructor(deviceProperties: PartialNull<MainUnitHWStateProperties>) {
    super(deviceProperties);
    this.isServiceMode = deviceProperties.isServiceMode ?? false;
  }
}
