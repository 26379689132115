/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import DeviceState from "../../../data/device/DeviceState";
import { DeviceStateProperties } from "../../../data/device/DeviceStateProperties";
import { ReferenceHWState } from "../../../client/devices/ReferenceHW/ReferenceHWState";
import { Maybe } from "../../../types/aliases";
import BatteryCharging20 from "../../../assets/baseline-battery_charging_20-24px.svg";
import BatteryCharging30 from "../../../assets/baseline-battery_charging_30-24px.svg";
import BatteryCharging50 from "../../../assets/baseline-battery_charging_50-24px.svg";
import BatteryCharging60 from "../../../assets/baseline-battery_charging_60-24px.svg";
import BatteryCharging80 from "../../../assets/baseline-battery_charging_80-24px.svg";
import BatteryCharging90 from "../../../assets/baseline-battery_charging_90-24px.svg";
import BatteryChargingFull from "../../../assets/baseline-battery_charging_full-24px.svg";
import BatteryAlert from "../../../assets/baseline-battery_alert-24px.svg";
import Battery20 from "../../../assets/baseline-battery_20-24px.svg";
import Battery30 from "../../../assets/baseline-battery_30-24px.svg";
import Battery50 from "../../../assets/baseline-battery_50-24px.svg";
import Battery60 from "../../../assets/baseline-battery_60-24px.svg";
import Battery80 from "../../../assets/baseline-battery_80-24px.svg";
import Battery90 from "../../../assets/baseline-battery_90-24px.svg";
import BatteryFull from "../../../assets/baseline-battery_full-24px.svg";
import Wifi4 from "../../../assets/baseline-signal_wifi_4_bar-24px.svg";
import Wifi3 from "../../../assets/baseline-signal_wifi_3_bar-24px.svg";
import Wifi2 from "../../../assets/baseline-signal_wifi_2_bar-24px.svg";
import Wifi1 from "../../../assets/baseline-signal_wifi_1_bar-24px.svg";
import Cellular4 from "../../../assets/baseline-signal_cellular_4_bar-24px.svg";
import Cellular3 from "../../../assets/baseline-signal_cellular_3_bar-24px.svg";
import Cellular2 from "../../../assets/baseline-signal_cellular_2_bar-24px.svg";
import Cellular1 from "../../../assets/baseline-signal_cellular_1_bar-24px.svg";

enum Modem {
  Cellular = "cellular",
  Wifi = "wifi",
  Ethernet = "ethernet",
}


export function getPowerSupplyStateIcon(deviceState?: DeviceState<DeviceStateProperties>): Maybe<string> {

  if (deviceState && ReferenceHWState.instanceOf(deviceState)) {

    if (deviceState.batterySoc == null) {
      return;
    }

    if (deviceState.chargerConnected) {
      if (deviceState.batterySoc < 30) {
        return BatteryCharging20;
      } else if (deviceState.batterySoc < 50) {
        return BatteryCharging30;
      } else if (deviceState.batterySoc < 60) {
        return BatteryCharging50;
      } else if (deviceState.batterySoc < 80) {
        return BatteryCharging60;
      } else if (deviceState.batterySoc < 90) {
        return BatteryCharging80;
      } else if (deviceState.batterySoc < 98) {
        return BatteryCharging90;
      }
      return BatteryChargingFull;
    } else {
      if (deviceState.batterySoc < 10) {
        return BatteryAlert;
      } else if (deviceState.batterySoc < 30) {
        return Battery20;
      } else if (deviceState.batterySoc < 50) {
        return Battery30;
      } else if (deviceState.batterySoc < 60) {
        return Battery50;
      } else if (deviceState.batterySoc < 80) {
        return Battery60;
      } else if (deviceState.batterySoc < 90) {
        return Battery80;
      } else if (deviceState.batterySoc < 98) {
        return Battery90;
      }
      return BatteryFull;
    }
  }
}

export function getSignalStrengthIcon(deviceState?: DeviceState<DeviceStateProperties>): Maybe<string> {

  if (deviceState && ReferenceHWState.instanceOf(deviceState)) {

    if (deviceState.signalStrength == null) {
      return;
    }

    if (deviceState.modem === Modem.Wifi) {
      if (deviceState.signalStrength > -25) {
        return Wifi4;
      } else if (deviceState.signalStrength > -50) {
        return Wifi3;
      } else if (deviceState.signalStrength > -75) {
        return Wifi2;
      }
      return Wifi1;
    }

    if (deviceState.modem === Modem.Cellular) {
      if (deviceState.signalStrength > -25) {
        return Cellular4;
      } else if (deviceState.signalStrength > -50) {
        return Cellular3;
      } else if (deviceState.signalStrength > -75) {
        return Cellular2;
      }
      return Cellular1;
    }
  }
}
