/* Copyright */

import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import clsx from "clsx";

import { Maybe } from "../../types/aliases";
import CustomButton from "./custom-button";
import { translations } from "../../generated/translationHelper";

interface Props {
  titles?: [Maybe<string>, Maybe<string>, Maybe<string>];
  children?: Maybe<JSX.Element>;
  backDisabled?: boolean;
  forwardDisabled?: boolean;
  onBackClicked?: () => void;
  onForwardClicked?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: "2px solid gray",
    borderRadius: 20,
    height: "100%",
    minWidth: "400px",
  },
  title: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    height: 40,
    borderRadius: "18px 18px 0 0",
  },
  centerTitle: {
    borderLeft: "1px solid white",
    borderRight: "1px solid white",
  },
  titleItem: {
    textAlign: "center",
  },
  header: {},
  content: {
    padding: "32px 16px",
  },
  children: {
    // 40 + 32 + 32 + 50 + 16
    height: "calc(100% - 170px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  buttons: {
    height: 50,
  },
}));

export default function NavigationContainer(props: Props): JSX.Element {
  const {
    titles,
    children,
    backDisabled,
    forwardDisabled: showDisabled,
    onBackClicked: onBackClick,
    onForwardClicked: onShowClick,
  } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.container}>
      <Grid item container className={classes.header}>
        <Grid item xs className={classes.title}>
          <Grid container justifyContent="space-around" alignItems="center" className={classes.title}>
            {titles && titles[0] &&
              <Grid item className={classes.titleItem} xs>
                {titles[0]}
              </Grid>
            }
            {titles && titles[1] &&
              <Grid item className={clsx(classes.centerTitle, classes.titleItem)} xs>
                {titles[1]}
              </Grid>
            }
            {titles && titles[2] &&
              <Grid item className={classes.titleItem} xs>
                {titles[2]}
              </Grid>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs container direction="column" className={classes.content}>
        <Grid item xs container direction="column" justifyContent="flex-start" className={classes.children}>
          {children}
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between" className={classes.buttons}>
          <Grid item>
            <CustomButton
              variant="contained"
              color="secondary"
              onClick={onBackClick}
              disabled={backDisabled}
            >
              {translations.common.buttons.back()}
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              variant="contained"
              color="secondary"
              onClick={onShowClick}
              disabled={showDisabled}
            >
              {translations.common.buttons.show()}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
