/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { events } from "openlayers";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import Device, { DeviceObserver } from "../../data/device/Device";
import { DrawerState } from "../../data/utils/Utils";
import DeviceGroup from "../../data/device/DeviceGroup";
import { MapService } from "../../services/MapService";
import { RailroadGroup } from "../../client/groups/RailroadGroup";
import { isDefined } from "../../utils/types";
import Factory from "../../client/groups/Factory/Factory";
import Crossing from "../../client/groups/Crossing/Crossing";
import { Maybe } from "../../types/aliases";
import { mapStore, MapState } from "../../state/MapStore";
import { Subscription } from "rxjs";
import CustomButton from "./custom-button";
import EventSet, { EventObserver } from "../../data/events/EventSet";
import { RailroadDevice } from "../../client/devices/RailroadDevice/RailroadDevice";
import { MainUnitHW } from "../../client/devices/MainUnitHW/MainUnitHW";
import { RoadDevice } from "../../client/devices/RoadDevice/RoadDevice";
import LatestData, { LatestDataObserver } from "../../data/data/LatestData";
import { ResourcePathRouterProps } from "../../types/routerprops";
import NavigationCache from "../../utils/NavigationCache";
import { idFromProps, ResourceType } from "../../utils/NavigationUtils";
import { translations } from "../../generated/translationHelper";

interface Props extends RouteComponentProps<ResourcePathRouterProps> {
  drawerState: DrawerState;
  isSelectionEnabled: boolean;
  devices?: Device[];
  groups?: DeviceGroup[];
  // Zoom map to the given resource
  zoomToResource?: Device | DeviceGroup;
}

interface State {
  showIconLabels: boolean;
  isMounted: boolean;
}

const ZOOM_DEFAULT = 6;
const FACTORY_ZOOM_LEVEL = 14;
const CROSSING_ZOOM_LEVEL = 18;

class MapComponent extends Component<Props, State> implements EventObserver, DeviceObserver, LatestDataObserver {
  private mapService: MapService = new MapService();
  private subscription?: Subscription;
  private eventSetMap = new Map<string, EventSet>();
  private latestDataMap = new Map<string, LatestData>();
  private mainUnits = new Map<string, MainUnitHW>();

  public constructor(props: Props) {
    super(props);
    this.state = {
      showIconLabels: false,
      isMounted: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.subscription = mapStore.state.subscribe(this.handleMapUpdate);
    this.renderMap();
    await this.updateMapContents();
    this.setState({ isMounted: true });
  }

  public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    await this.updateMapContents(prevProps, prevState);
    await this.zoomMapOnResource(prevProps);

    if (prevProps.groups !== this.props.groups || prevProps.devices !== this.props.devices) {
      const mainUnits = await this.resolveMainUnits();
      this.addMainUnitObservers(mainUnits);
    }
  }

  public async componentWillUnmount(): Promise<void> {
    this.subscription?.unsubscribe();
    this.removeDeviceEventObservers(this.props.devices);
    this.removeGroupEventObservers(this.props.groups);
    this.removeMainUnitObservers();
    this.removeDeviceDataObservers(this.props.devices);
  }

  public async onEventSetUpdate(eventSet: EventSet): Promise<void> {
    const groupIds = this.props.groups?.map(x => x.getId()) ?? [];
    const deviceIds = this.props.devices?.map(x => x.getId()) ?? [];

    if ([...groupIds, ...deviceIds].includes(eventSet.getId())) {
      await this.addMapDeviceLayer(this.props.devices);
      await this.addMapDeviceGroupLayer(this.props.groups);
    }
  }

  public async onDeviceStateUpdated(device: Device): Promise<void> {
    if (this.mainUnits.has(device.getId())) {
      await this.addMapDeviceLayer(this.props.devices);
      await this.addMapDeviceGroupLayer(this.props.groups);
    }
  }

  public async onDataUpdate(latestData: LatestData): Promise<void> {
    const groupIds = this.props.groups?.map(x => x.getId()) ?? [];
    const deviceIds = this.props.devices?.map(x => x.getId()) ?? [];

    if ([...groupIds, ...deviceIds].includes(latestData.getId())) {
      await this.addMapDeviceLayer(this.props.devices);
      await this.addMapDeviceGroupLayer(this.props.groups);
    }
  }

  private async addDeviceDataObservers(): Promise<void> {
    for (const device of this.props.devices ?? []) {
      if (RoadDevice.instanceOf(device)) {
        const latestData = device.getLatestData();

        if (!latestData.getData()) await latestData.fetch();
        latestData.addObserver(this);
        this.latestDataMap.set(device.getId(), latestData);
      }
    }
  }

  private async addGroupEventObservers(): Promise<void> {
    for (const group of this.props.groups ?? []) {
      if (RailroadGroup.instanceOf(group)) {
        const eventSet = group.getEventSet();
        if (!eventSet.getData()) await eventSet.fetch();
        eventSet.addObserver(this);
  
        if (eventSet) {
          this.eventSetMap.set(group.getId(), eventSet);
        }
      }
    }
  }

  private removeDeviceDataObservers(devices?: Device[]): void {
    for (const device of devices ?? []) {
      this.latestDataMap.get(device.getId())?.removeObserver(this);
      this.latestDataMap.delete(device.getId());
    }
  }

  private addMainUnitObservers(mainUnits: MainUnitHW[]): void {
    for (const mainUnit of mainUnits) {
      mainUnit.addObserver(this);
      this.mainUnits.set(mainUnit.getId(), mainUnit);
    }
  }

  private removeMainUnitObservers(): void {
    this.mainUnits.forEach((mainUnit, key) => {
      mainUnit.removeObserver(this);
      this.mainUnits.delete(key);
    });
  }
  
  private async addMapDeviceLayer(devices?: Device[]): Promise<void> {
    const roadDevices = devices?.filter(this.isDefinedRoadDevice) ?? [];
    const railroadDevices = devices?.filter(this.isDefinedRailroadDevice) ?? [];
    await this.mapService.addDeviceLayer([...roadDevices, ...railroadDevices], this.state.showIconLabels, this.getSelectedResourceIdOrNone());
  }

  private async addMapDeviceGroupLayer(groups?: DeviceGroup[]): Promise<void> {
    const locationGroups = groups?.filter(this.isDefinedRailroadGroup) ?? [];
    await this.mapService.addGroupLayer(locationGroups, this.state.showIconLabels, this.getSelectedResourceIdOrNone());
  }

  private async resolveMainUnits(): Promise<MainUnitHW[]> {
    if (this.props.devices?.length) {
      const mainUnit = this.props.devices.find(MainUnitHW.instanceOf);
      if (mainUnit) return [mainUnit];
    } else if (this.props.groups && this.props.groups.length) {
      const crossings = this.props.groups.filter(Crossing.instanceOf);
      const mainUnits = [];

      for (const crossing of crossings) {
        const mainUnit = await crossing.getMainUnit();
        if (mainUnit) mainUnits.push(mainUnit);
      }
      return mainUnits;
    }
    return [];
  }

  private removeGroupEventObservers(groups: Maybe<DeviceGroup[]>): void {
    for (const group of groups ?? []) {
      this.eventSetMap.get(group.getId())?.removeObserver(this);
      this.eventSetMap.delete(group.getId());
    }
  }

  private async addDeviceEventObservers(): Promise<void> {
    for (const device of this.props.devices ?? []) {
      const eventSet = device.getEventSet();
      await eventSet?.fetch();
      eventSet?.addObserver(this);

      if (eventSet) {
        this.eventSetMap.set(device.getId(), eventSet);
      }
    }
  }

  private removeDeviceEventObservers(devices: Maybe<Device[]>): void {
    for (const device of devices ?? []) {
      this.eventSetMap.get(device.getId())?.removeObserver(this);
      this.eventSetMap.delete(device.getId());
    }
  }

  private getSelectedResourceIdOrNone(): Maybe<string> {
    return idFromProps(this.props);
  }

  /**
   * Handle changes in the observable map state.
   * @param state New map state
   */
  private handleMapUpdate = (state: MapState): void => {
    if (state.onLocationUpdate) {
      const coordinates = this.mapService.getNewLocation();
      state.onLocationUpdate(coordinates);
      return;
    }

    this.mapService.editMode = state.editMode;

    if (this.props.devices?.length && this.state.isMounted) {
      this.addMapDeviceLayer(this.props.devices);
    }

    this.mapService.toggleMapEditMode(state.editMode);
  };

  private isDefinedRailroadDevice = (device: Device): device is RailroadDevice => RailroadDevice.instanceOf(device) && isDefined(device);
  private isDefinedRailroadGroup = (deviceGroup: DeviceGroup): deviceGroup is RailroadGroup => RailroadGroup.instanceOf(deviceGroup) && isDefined(deviceGroup);
  private isDefinedRoadDevice = (device: Device): device is RoadDevice => RoadDevice.instanceOf(device) && isDefined(device);
  
  /**
   * Update resources on the map and set map size if the drawer state has changed.
   * @param prevProps Previous props
   * @param prevState Previous state
   */
  private updateMapContents = async (prevProps?: Props, prevState?: State): Promise<void> => {
    if (prevProps?.devices !== this.props.devices || prevState?.showIconLabels !== this.state.showIconLabels || prevProps?.zoomToResource !== this.props.zoomToResource) {
      if (prevProps?.devices !== this.props.devices) {
        this.removeDeviceEventObservers(prevProps?.devices);
        this.removeDeviceDataObservers(prevProps?.devices);
        await Promise.all([this.addDeviceEventObservers(), this.addDeviceDataObservers()]);
      }
      await this.addMapDeviceLayer(this.props.devices);
    }

    if (prevProps?.groups !== this.props.groups || prevState?.showIconLabels !== this.state.showIconLabels || prevProps?.zoomToResource !== this.props.zoomToResource) {
      if (prevProps?.groups !== this.props.groups) {
        this.removeGroupEventObservers(prevProps?.groups);
        await this.addGroupEventObservers();
      }
      await this.addMapDeviceGroupLayer(this.props.groups);
    }

    if (this.props.drawerState !== prevProps?.drawerState && this.props.drawerState !== DrawerState.Closed) {
      this.mapService.updateSize();
    }
  };

  /**
   * Zoom map once "zoomToResource" property changes.
   * @param prevProps Previous props
   */
  private zoomMapOnResource = async (prevProps?: Props): Promise<void> => {
    if (prevProps?.zoomToResource !== this.props.zoomToResource) {
      const zoomLevel = this.getZoomLevel(this.props.zoomToResource);

      if (RailroadGroup.instanceOf(this.props.zoomToResource) || RailroadDevice.instanceOf(this.props.zoomToResource) || RoadDevice.instanceOf(this.props.zoomToResource)) {
        const [latitude, longitude] = await this.props.zoomToResource?.getLocation() ?? [];

        if (latitude != null && longitude != null) {
          this.mapService.zoomMap(zoomLevel, longitude, latitude);
        }
      } else {
        this.mapService.zoomMap(zoomLevel);
      }
    }
  };

  private selectResourceOnMap = async (event: events.Event): Promise<void> => {
    if (this.props.isSelectionEnabled && event !== null) {
      const feature = event.target.getFeatures().array_[0];

      if (feature) {
        if (feature.values_.properties.type === ResourceType.Device) {
          await NavigationCache.getInstance().navigateToDevice(this.props, feature.values_.properties.id);
        }
        else if (feature.values_.properties.type === ResourceType.Group) {
          await NavigationCache.getInstance().navigateToGroup(this.props, feature.values_.properties.id);
        }
      }
    }
  };

  /**
   * Get the resource specific zoom level.
   */
  private getZoomLevel = (resource: Maybe<DeviceGroup | Device>): number => {
    if (Factory.instanceOf(resource)) {
      return FACTORY_ZOOM_LEVEL;
    }

    if (Crossing.instanceOf(resource) || RailroadDevice.instanceOf(resource) || RoadDevice.instanceOf(resource)) {
      return CROSSING_ZOOM_LEVEL;
    }

    return ZOOM_DEFAULT;
  };

  private toggleDeviceNameVisibility = (): void => {
    this.setState((prevState) => ({ showIconLabels: !prevState.showIconLabels }));
  };

  private renderMap(): void {
    this.mapService.createMap("status-map");
    this.mapService.addSelectInteraction(this.selectResourceOnMap);
  }

  public render(): JSX.Element {
    const buttonText = this.state.showIconLabels ? translations.common.buttons.hideDisplayNames() : translations.common.buttons.showDisplayNames();
    return (
      <Fragment>
        <div id="status-map">
          <div id="status-button">
            <CustomButton onClick={this.toggleDeviceNameVisibility}>{buttonText}</CustomButton>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(MapComponent);
