/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import { EventRepositoryListener } from "./EventRepositoryListener";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe, Nullable } from "../../types/aliases";
import {
  Event,
  EventMetadata,
  EventsFeedDocument,
  EventsFeedSubscription,
  EventsMetadataListDocument,
} from "../../generated/gqlEvents";
import AbstractSetSubscriptionManager from "../utils/subscriptions/AbstractSetSubscriptionManager";
 
export default class EventsRepository extends AbstractSetSubscriptionManager<EventRepositoryListener, EventsFeedSubscription> {
  public static readonly EVENT_AGE_DAYS = 365;
  private static __instance: EventsRepository = new EventsRepository();
  private events: Event[] = [];
  private eventMetadata: Map<string, EventMetadata> = new Map<string, EventMetadata>();
  
  private constructor() {
    super(Service.EVENTS, EventsFeedDocument);
  }
    
  public static get instance(): EventsRepository {
    return EventsRepository.__instance;
  }
  
  public async init(): Promise<void> {
    this.eventMetadata = new Map();
    await this.fetchEventMetadata();
    this.subscribeOnce();
  }

  public uninit(): void {
    this.unsubscribe();

    if (this.eventMetadata) {
      this.eventMetadata.clear();
    }
  }

  public getEventDescription(eventId: string): string {
    const description = this.eventMetadata.get(eventId)?.description;
    return description ?? eventId;
  }

  protected subscriptionHandler(result: Maybe<EventsFeedSubscription>): void {
    if (!result?.eventsFeed) return;

    const event = result.eventsFeed.item;
    const isNew = this.handleEvent(event);
    this.forEachListener((listener: EventRepositoryListener) => {
      if (isNew) {
        listener.onEvent(event);
      } else {
        listener.onEventStateChanged(event);
      }
    });
  }

  private async fetchEventMetadata(): Promise<void> {
    let nextToken: Nullable<string> = null;

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const metadataResponse = await client.query(
          EventsMetadataListDocument,
          {
            nextToken,
          },
          {
            fetchPolicy: "network-only",
          },
        );
        // cast is required or response's type inference becomes cyclic
        nextToken = (metadataResponse.data.eventsMetadataList?.nextToken ?? null) as Nullable<string>;
        metadataResponse.data.eventsMetadataList?.eventMetadataItems.forEach((metadata) => {
          this.eventMetadata.set(metadata.eventId, metadata);
        });
      } while (nextToken);
    } catch (error) {
      console.error("Error", error);
    }
  }

  private handleEvent(event: Event): boolean {
    const index = this.events.findIndex(EventsRepository.getIsSameEventComparator(event));
    const isNew = index === -1;

    if (isNew) {
      this.events.push(event);
    } else {
      this.events[index] = event;
    }
    return isNew;
  }

  public static getIsSameEventComparator(event: Event): (e: Event) => boolean {
    return (e: Event): boolean => event.deviceId === e.deviceId
      && event.eventId === e.eventId
      && event.timestamp === e.timestamp;
  }

}
