/* Copyright */

import { createTheme } from "@material-ui/core/styles";
import { yellow } from "@material-ui/core/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffc21c",
    },
    secondary: {
      main: "#4d9cb2",
    },
    warning: {
      main: yellow[500],
    },
  },
  typography: {
    fontFamily: [
      "\"Open Sans\"",
      "sans-serif",
    ].join(","),
  },
});
