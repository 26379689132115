/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { FormControl, InputLabel, MenuItem, Select, PropTypes, SelectProps } from "@material-ui/core";
import React, { ChangeEvent, Component } from "react";
import { Maybe } from "../../types/aliases";

type Selection = { key: string; label: string | number | boolean };

interface Props {
  selectionList: Selection[];
  onSelect: (selection?: number) => void;
  currentSelection?: number;
  variant?: SelectProps["variant"];
  label?: string;
  disabled?: boolean;
  emptySelectionItem?: string;
  fullWidth?: boolean;
  error?: boolean;
  "data-testid"?: string;
  margin?: PropTypes.Margin;
}

interface State {
  selected?: string;
}

export default class DropdownSelection extends Component<Props, State> {
  
  public constructor(props: Props) {
    super(props);
    this.state = {
      selected: this.getSelection(this.props.currentSelection),
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.currentSelection !== this.props.currentSelection) {
      this.setState({
        selected: this.getSelection(this.props.currentSelection),
      });
    }
  }

  private getSelection(index?: number): Maybe<string> {
    return index != null ? this.props.selectionList[index]?.key : undefined;
  }

  private getLabel(): Maybe<JSX.Element> {
    if (this.props.label) {
      return (
        <InputLabel id="dropdown-label">
          {this.props.label}
        </InputLabel>
      );
    }
  }

  private getValue(): string {
    return this.state.selected ?? "";
  }

  private getSelectionList(): Maybe<JSX.Element[]> {
    if (this.props.selectionList?.length) {
      return this.props.selectionList.map((selection: Selection, index: number) => {
        return (
          <MenuItem key={selection.key} value={selection.key} data-testid={this.props["data-testid"] ? `${this.props["data-testid"]}-${index}` : undefined}>{selection.label}</MenuItem>
        );
      });
    }
  }

  private getEmptySelection(): Maybe<JSX.Element> {
    if (this.props.emptySelectionItem) {
      return (
        <MenuItem value="">
          <em>{this.props.emptySelectionItem}</em>
        </MenuItem>
      );
    }
  }

  private handleSelection = (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
    let index: Maybe<number> = undefined;
    const value = (event.target?.value as string) ?? undefined;

    if (value !== undefined) {
      const foundIndex = this.props.selectionList.findIndex((selection) => selection.key === value);
      index = foundIndex !== -1 ? foundIndex : undefined;
    }
    this.setState({ selected: value });
    this.props.onSelect(index);
  };

  public render(): JSX.Element {
    return (
      <FormControl
        margin={this.props.margin}
        fullWidth={this.props.fullWidth}
      >
        {this.getLabel()}
        <Select
          value={this.getValue()}
          onChange={this.handleSelection}
          disabled={this.props.disabled}
          displayEmpty={true}
          data-testid={this.props["data-testid"]}
          variant={this.props.variant}
          fullWidth={this.props.fullWidth}
        >
          {this.getEmptySelection()}
          {this.getSelectionList()}
        </Select>
      </FormControl>
    );
  }
}
