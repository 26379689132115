/* Copyright */

import Data from "../../../data/data/Data";

export enum SubDeviceError {
  OK = "ok",
  ERROR = "err",
  LOW_BATTERY = "low-bat",
  LEAN = "lean",
}

export enum MainDeviceError {
  OK = "ok",
  ERROR = "error",
  DEVICE_NOT_RESPONDING = "dev-no-resp",
  DEVICE_ERROR = "dev-err",
}

interface SubDeviceStatus {
  error: `${SubDeviceError}`;
  id: number;
}

export interface MainUnitHWData extends Data {
  deviceId: string;
  result: `${MainDeviceError}`;
  duration: number;
  humidity: number;
  temp: number;
  timestamp: number;
  devices: SubDeviceStatus[];
}
