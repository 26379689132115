/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import Loader from "../../ui/loader";
import { Maybe } from "../../../types/aliases";
import AuthWrapper from "../../../data/auth/AuthWrapper";
import CustomButton from "../../ui/custom-button";
import PasswordField from "../../ui/password-field";
import { translations } from "../../../generated/translationHelper";
import { isErrorWithCode } from "../../../data/utils/ErrorUtils";

interface Props {
}

interface State {
  isLoading: boolean;
  username: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string; 
  error?: string;
}

export default class PasswordForm extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      username: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  public componentDidMount(): void {
    this.getloggedInUserName();
  }

  private async getloggedInUserName(): Promise<void> {
    const username = await AuthWrapper.getCurrentAuthenticatedUsername();
    this.setState({ username });
  }

  private handlePasswordSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.submitNewPassword(this.state.oldPassword, this.state.newPassword);
      this.setState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error("handlePasswordSubmit", error);
      if (isErrorWithCode(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  private handleErrorCode(code?: string): void {      
    switch (code) {
      case "Attempt limit exceeded, please try after some time.":
        this.setErrorMessage(translations.common.texts.tooManyAttempts());
        break;
      case "Incorrect username or password.":
        this.setErrorMessage(translations.user.texts.incorrectCredentials());
        break;
      case "Network error":
        this.setErrorMessage(translations.common.texts.networkError());
        break;
      case "Password did not conform with policy: Password must have numeric characters":
        this.setErrorMessage(translations.common.texts.passwordMustHaveNumbers());
        break;
      case "Password did not conform with policy: Password must have lowercase characters":
        this.setErrorMessage(translations.common.texts.passwordMustHaveLowercaseCharacters());
        break;
      case "1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "2 validation errors detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password did not conform with policy: Password not long enough":
        this.setErrorMessage(translations.common.texts.passwordMustBeLongEnough());
        break;
      case "Invalid session for the user, session is expired.":
        this.setErrorMessage(translations.common.texts.userSessionExpired());
        break;
      default:
        this.setErrorMessage(translations.common.texts.unableToPerformAction());
        break;
    }
  }

  private setErrorMessage(error?: string): void {
    this.setState({ error });
  }
    
  private renderPasswordValidationMessage(): Maybe<JSX.Element> {
    if (this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0 && this.state.newPassword !== this.state.confirmPassword) {
      return (
        <Grid item={true} container={true} justifyContent="center">
          <span>{translations.common.texts.passwordsNotMatching()}</span>
        </Grid>
      );
    }
  }
    
  private renderPasswordSubmitMessage(): Maybe<JSX.Element> {
    if (this.state.error) {
      return (
        <Grid item={true} container={true} justifyContent="center">
          <span data-testid="change-pass-err">{translations.common.texts.errorOccurred({ error: this.state.error })}</span>
        </Grid>
      );
    }
  }
    
  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoading) {
      return <Grid item={true} container={true} justifyContent="center"><Loader /></Grid>;
    }
  }

  private renderInputs(): JSX.Element {
    const isEnabled = this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0;
    return (
      <Grid item={true} xs={12} sm={10} md={8}>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={translations.user.inputs.oldPassword()}
            inputProps={{ "data-testid": "old-password-field" }}
            autoComplete="current-password"
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ oldPassword: password });
            }}
          />
        </Grid>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={translations.common.inputs.newPassword()}
            inputProps={{ "data-testid": "new-password-field" }}
            autoComplete="current-password"
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ newPassword: password });
            }}
          />
        </Grid>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={translations.common.inputs.confirmNewPassword()}
            inputProps={{ "data-testid": "confirm-password-field" }}
            autoComplete="current-password"
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ confirmPassword: password });
            }}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <CustomButton
            disabled={!isEnabled}
            variant="contained"
            data-testid="confirm-change-password"
            color="secondary"
            onClick={this.handlePasswordSubmit}
          >
            {translations.user.buttons.confirmPasswordChange()}
          </CustomButton>
        </Grid>
      </Grid>
    );
  }

  public render(): JSX.Element { 
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} container={true} justifyContent="center">
          <Typography variant="h6" style={{ fontWeight: "bold" }}>{translations.user.texts.enterOldPasswordAndNewPassword()}</Typography>
        </Grid>
        <Grid item={true} container={true} spacing={2} justifyContent="center">
          {this.renderInputs()}
        </Grid>
        {this.renderPasswordValidationMessage()}
        {this.renderLoader()}
        {this.renderPasswordSubmitMessage()}
      </Grid>
    );
  }
}
