/* Copyright */
import SignIconBlue from "../../../assets/Sign-Blue-24x24.svg";
import SignIconYellow from "../../../assets/Sign-Yellow-24x24.svg";
import SignIconRed from "../../../assets/Sign-Red-24x24.svg";
import { SignHWState } from "./SignHWState";
import { SignHWStateProperties } from "./SignHWStateProperties";
import { ResourceStatus } from "../../ResourceStatus";
import { BatteryOperated, RailroadDevice } from "../RailroadDevice/RailroadDevice";
import { SignHWData } from "./SignHWData";
import { Maybe } from "../../../types/aliases";
import { DeviceParameters } from "../../../data/device/Device";
import AWSBackend from "../../../data/backend/AWSBackend";
import TimePeriod from "../../../data/utils/TimePeriod";

export class SignHW extends RailroadDevice<SignHWData, SignHWState> {
  public static type = "Sign";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(SignHW.type, backend, params);
  }

  public getIcon(): string {
    return SignIconBlue;
  }

  public async getStatusIcon(timePeriod: TimePeriod): Promise<string> {
    const status = await this.getStatus(timePeriod);

    switch (status) {
      case ResourceStatus.WARNING:
        return SignIconYellow;
      case ResourceStatus.ERROR:
        return SignIconRed;
      case ResourceStatus.NONE:
      default:
        return this.getIcon();
    }
  }


  public getBatteryPercentage(): Maybe<number> {
    if (this.getBattery() === BatteryOperated.True) {
      const maxCapacity = this.getState()?.batteryMaxCapacity;
      const batteryCapacity = this.getState()?.batteryCapacity;
      return batteryCapacity != null && maxCapacity != null ? Math.floor(batteryCapacity / maxCapacity * 100) : undefined;
    }
  }


  public createState(timestamp: number, reported?: Partial<SignHWStateProperties>, desired?: Partial<SignHWStateProperties>): SignHWState {
    return new SignHWState(this.getId(), new SignHWStateProperties(reported ?? {}), new SignHWStateProperties(desired ?? {}), timestamp);
  }

  public static instanceOf(value: unknown): value is SignHW {
    return value instanceof SignHW;
  }
}
