/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Box, Dialog, DialogProps, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import CustomButton from "./custom-button";
import { translations } from "../../generated/translationHelper";

interface Props {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  title?: string;
  disableAccept?: false | boolean;
  acceptButtonText?: string;
  cancelButtonText?: string;
  maxWidth?: DialogProps["maxWidth"];
}

/**
 * Simple "form" style dialog, where
 * <FormDialog>
 *   <ChildComponent1 />
 *   <ChildComponent2 />
 * </FormDialog>
 *
 * are placed within a <List>
 */
export default class FormDialog extends Component<Props> {

  public constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const ACCEPT_TEXT = translations.common.buttons.accept();
    const CANCEL_TEXT = translations.common.buttons.cancel();
    return (
      <Dialog
        onClose={this.props.onCancel}
        open={this.props.isOpen}
        maxWidth={this.props.maxWidth}
      >
        <Box padding={3} style={{ overflow: "hidden" }}>
          <Grid container={true} direction={"column"} spacing={3}>
            {this.props.title &&
              <Grid item={true} container={true} xs={12} justifyContent="center">
                <Typography variant="h6">{this.props.title}</Typography>          
              </Grid>
            }
            <Grid item={true} container={true} xs={12} justifyContent="center">
              {this.props.children}
            </Grid>
            <Grid item={true} container={true} justifyContent="center" spacing={6}>
              <Grid item={true}>
                <CustomButton
                  variant="contained"
                  color="secondary"
                  className="button left"
                  onClick={this.props.onAccept}
                  disabled={this.props.disableAccept ?? false}
                >
                  {this.props.acceptButtonText ?? ACCEPT_TEXT}
                </CustomButton>
              </Grid>
              <Grid item={true}>
                <CustomButton
                  variant="contained"
                  color="secondary"
                  className="button"
                  onClick={this.props.onCancel}
                >
                  {this.props.cancelButtonText ?? CANCEL_TEXT}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    );
  }
}
