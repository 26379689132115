/* Copyright */
import { Maybe } from "../../types/aliases";
import { Attribute } from "./Attribute";

type AttributeValue = Maybe<string | number | boolean>;
type AttributeRecord = Record<string, AttributeValue>;

export default class AttributesBuilder {
  private attributes?: Map<string, string>;

  public constructor() {
    this.attributes = new Map();
  }

  public reset(): void {
    this.attributes = undefined;
  }

  public setAttribute(key: string, value?: AttributeValue | AttributeValue[] | AttributeRecord): AttributesBuilder {
    if (value) {
      if (!this.attributes) {
        this.initAttributes();
      }
      this.attributes!.set(key, typeof value !== "string" ? JSON.stringify(value) : value);
    }
    return this;
  }

  public getAttributes(): Maybe<Required<Attribute>[]> {
    if (this.attributes) {
      return [...this.attributes.entries()].map((attribute) => ({ key: attribute[0], value: attribute[1] }));
    }
  }

  private initAttributes(): void {
    this.attributes = new Map();
  }
}
