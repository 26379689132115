/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import AWSUser from "./AWSUser";
import Organization, {
  CreateOrganizationParameters,
  CreateUserParameters,
  OrganizationParameters,
} from "./Organization";
import { AWSOrganizationBackend } from "./AWSOrganizationBackend";
import User from "./User";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import {
  OrganizationsDeleteDocument,
  OrganizationsOrganizationsListDocument,
  OrganizationsPolicyGroupsListDocument,
  OrganizationsUpdateDocument,
  OrganizationsUsersListDocument,
  OrganizationsUsersRemoveDocument,
  ResultType,
} from "../../generated/gqlUsers";
import { verifyUserType } from "./AWSTypeUtils";
import AWSPolicyGroup from "./AWSPolicyGroup";
import { throwGQLError } from "../utils/Utils";
import { HasEntityRelations, RelationChange } from "../utils/EntityRelationCache";
import { Maybe } from "../../types/aliases";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";

export default class AWSOrganization extends Organization implements HasEntityRelations {
  public readonly entityType = AWSOrganization;
  private usersSemaphore = new PromiseSemaphore(() => this.backendFetchUsers());
  private policyGroupSemaphore = new PromiseSemaphore(() => this.backendFetchPolicyGroups());
  private childrenSemaphore = new PromiseSemaphore(() => this.backendFetchChildOrganizations());

  public constructor(private readonly backend: AWSOrganizationBackend, parameters: OrganizationParameters) {
    super(parameters);
  }

  public async changeName(name: string): Promise<void> {
    await this.backendChangeName(name);
    this.name = name;
    this.notifyAction(observer => observer.onNameChange?.(this));
  }

  public async getUsers(): Promise<User[]> {
    await this.usersSemaphore.guard();
    const result = this.backend.entityRelationCache.listFor(this, AWSUser);
    result.sort(User.alphabeticUserOrdering);
    return result;
  }

  public async createOrganization(parameters: CreateOrganizationParameters): Promise<Organization> {
    const newOrganization = await this.backend.createOrganization(this, parameters);

    await this.getChildOrganizations();
    this.backend.entityRelationCache.link(this, newOrganization);

    return newOrganization;
  }

  public async getChildOrganizations(): Promise<Organization[]> {
    await this.childrenSemaphore.guard();
    return this.backend.entityRelationCache
      .listFor(this, AWSOrganization)
      // the relationship set will include parent node, so that needs to be removed
      .filter((potentialChild) => potentialChild.id.startsWith(this.id));
  }
  
  public async getParentOrganization(): Promise<Maybe<AWSOrganization>> {
    if (this.parentId) {
      return this.backend.getOrganization(this.parentId);
    }
  }

  public async createUser(parameters: CreateUserParameters): Promise<User> {
    try {
      const newUser: AWSUser = await this.backend.createUser(this, parameters);
      this.backend.entityRelationCache.link(this, newUser);
      return newUser;
    } catch (error) {
      console.error("Failed to create new user: " + error);
      throw error;
    }
  }

  public addUser(user: User): Promise<void> {
    verifyUserType(user);
    throw new Error("Method not implemented.");
  }

  public async removeUser(user: User): Promise<void> {
    verifyUserType(user);

    const removed = await this.backendRemoveUser(user.getId());

    if (removed) {
      this.backend.entityRelationCache.unlink(this, user as AWSUser);
    }
  }

  public async getPolicyGroups(): Promise<AWSPolicyGroup[]> {
    await this.policyGroupSemaphore.guard();
    return this.backend.entityRelationCache.listFor(this, AWSPolicyGroup);
  }

  public async delete(): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.mutate(
      OrganizationsDeleteDocument,
      {
        organizationId: this.id,
      },
    );

    if (response.data?.organizationsDelete?.result !== ResultType.Ok) {
      throw new Error(response.data?.organizationsDelete?.failureReason ?? "Failed to delete organization");
    }

    await this.backend.cleanEntityFromCaches(this.id);
    this.notifyAction(observer => observer.onDeleted?.(this));
    this.clearObservers();
  }

  public async onRelationChange(change: RelationChange): Promise<void> {
    // using semaphore.invoked() to check if some external entity is interested in the particular updates
    // otherwise we'd end up greedily loading resources (such as the whole organization tree)
    if (change.ofType(AWSOrganization) && this.childrenSemaphore.invoked()) {
      const children = await this.getChildOrganizations();
      this.notifyAction(observer => observer.onChildrenChange?.(children, this));
    } else if (change.ofType(AWSUser) && this.usersSemaphore.invoked()) {
      const users = await this.getUsers();
      this.notifyAction(observer => observer.onUsersChange?.(users, this));
    } else if (change.ofType(AWSPolicyGroup) && this.policyGroupSemaphore.invoked()) {
      const groups = await this.getPolicyGroups();
      this.notifyAction(observer => observer.onPolicyGroupsChange?.(groups, this));
    }
  }
  

  private async backendChangeName(name: string): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    await client.mutate(
      OrganizationsUpdateDocument,
      {
        payload: {
          id: this.id,
          name,
        },
      });
  }

  private async backendFetchUsers(): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.query(
      OrganizationsUsersListDocument,
      {
        organizationId: this.id,
        // TODO: token
      },
      {
        fetchPolicy: "network-only",
      },
    );

    if (!response.data.organizationsUsersList) {
      throwGQLError(response, "Failed to get organization's users");
    }

    // TODO:  in order to improve performance here, we need to decide what data should be duplicated
    //        from user to the organization-user link row, and use that to construct a simplified view to the user
    //        ... or we can just resolve the users on the service side, but that is as expensive as OrganizationUtils.
    //            maybe a little faster
    //        ... or we could add some smart indexes here and there for specific use-cases, such as this (although,
    //            custom indexing org-users relationship is pretty hard)
    //
    //        Currently, the backend does a lot of caching, so that helps with repeated requests
    const maybeUsers = await Promise.all(response.data.organizationsUsersList.users.map((id) => this.backend.getUser(id)));
    const users = maybeUsers.filter(AWSUser.instanceOf);

    this.backend.entityRelationCache.replaceTypedLinks(this, AWSUser, users);
  }

  private async backendFetchPolicyGroups(): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.query(
      OrganizationsPolicyGroupsListDocument,
      {
        organizationId: this.id,
        // TODO: next token
      },
      {
        fetchPolicy: "network-only",
      },
    );

    if (!response.data.organizationsPolicyGroupsList) {
      throwGQLError(response, "Failed to get organization's policy groups");
    }

    // TODO: this is also very slow, much like backendGetUsers
    const maybePolicyGroups = await Promise.all(response.data.organizationsPolicyGroupsList.groups.map((id) => this.backend.getPolicyGroup(id)));
    const policyGroups = maybePolicyGroups.filter(AWSPolicyGroup.instanceOf);

    this.backend.entityRelationCache.replaceTypedLinks(this, AWSPolicyGroup, policyGroups);
  }

  private async backendFetchChildOrganizations(): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.query(
      OrganizationsOrganizationsListDocument,
      {
        organizationId: this.id,
        // TODO: next token
      },
      {
        fetchPolicy: "network-only",
      },
    );

    if (!response.data.organizationsOrganizationsList) {
      throwGQLError(response, "Failed to get organization's child organizations");
    }

    // TODO: this is also very slow, much like backendGetUsers
    const organizations = await Promise.all(response.data.organizationsOrganizationsList.organizations.map((id) => this.backend.getOrganization(id)));
    const children = organizations.filter(AWSOrganization.instanceOf);
    // keeps the potential parent in the cache, otherwise we are in trouble
    this.backend.entityRelationCache
      .replaceTypedLinks(this, AWSOrganization, children, record => record.entity.id === this.parentId);
  }

  private async backendRemoveUser(id: string): Promise<boolean> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.mutate(
      OrganizationsUsersRemoveDocument,
      {
        userId: id,
        organizationId: this.id,
      },
    );

    return response.data?.organizationsUsersRemove?.result === ResultType.Ok;
  }

  public static instanceOf(value: unknown): value is AWSOrganization {
    return value instanceof AWSOrganization;
  }
}
