/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import EventSet from "./EventSet";
import { EventsFeedDocument, EventsFeedSubscription } from "../../generated/gqlEvents";
import AbstractMapSubscriptionManager from "../utils/subscriptions/AbstractMapSubscriptionManager";
import { Maybe } from "../../types/aliases";

export default class EventsSubscriptionManager extends AbstractMapSubscriptionManager<EventSet, EventsFeedSubscription> {
  private static __instance: EventsSubscriptionManager = new EventsSubscriptionManager();
  
  private constructor() {
    super(Service.EVENTS, EventsFeedDocument);
  }

  public static get instance(): EventsSubscriptionManager {
    return this.__instance;
  }

  protected subscriptionHandler(result: Maybe<EventsFeedSubscription>): void {
    if (!result) return;
    const event = result.eventsFeed?.item;

    if (event) {
      this.getListener(event.deviceId)?.addOrUpdateEvent(event);
      if (event.crossingId) this.getListener(event.crossingId)?.addOrUpdateEvent(event);
      if (event.factoryId) this.getListener(event.factoryId)?.addOrUpdateEvent(event);
    }
  }
}
