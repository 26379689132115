/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { TableCell, TableRow, Typography } from "@material-ui/core";
import React, { Component, ReactNode, Fragment } from "react";
import DropdownSelection from "../../../ui/dropdown-selection";
import { UserOrganizationAction } from "./user-organization-action";
import User, { UserObserver } from "../../../../data/organization/User";
import PolicyGroup from "../../../../data/organization/PolicyGroup";
import { Maybe } from "../../../../types/aliases";
import Loader from "../../../ui/loader";
import ErrorDialog from "../../../ui/error-dialog";
import CustomButton from "../../../ui/custom-button";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  organizationId: string;
  user: User;
  userAction: UserOrganizationAction;
  actionCallback: (action: UserOrganizationAction, user: User) => void;
  availablePolicyGroups: PolicyGroup[];
  enablePolicySelection: boolean;
  ["data-testid"]: string;
}

interface State {
  loading: boolean;
  selectedPolicy?: number;
  isOwner?: boolean;
  error?: string;
}

export default class UserListItem extends Component<Props, State> implements UserObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.props.user.addObserver(this);
    await this.resolveCurrentPolicy();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.organizationId !== this.props.organizationId) {
      await this.resolveCurrentPolicy();
    }
  }
  
  public componentWillUnmount(): void {
    this.props.user.removeObserver(this);
  }

  private async resolveCurrentPolicy(): Promise<void> {
    this.setState({
      loading: true,
    });
    const policyGroups = await this.props.user.getPolicyGroups(this.props.organizationId);
    this.setSelectedPolicyGroup(policyGroups);
    this.setState({ loading: false });
  }

  public onPolicyGroupsChange(groups: PolicyGroup[]): void {
    this.setSelectedPolicyGroup(groups);
  }
  
  private setSelectedPolicyGroup(usersPolicyGroups: PolicyGroup[]): void {
    if (usersPolicyGroups.length === 0) {
      this.setState({ isOwner: this.props.user.getPolicies().includes("*") });
    } else if (usersPolicyGroups.length > 1) {
      console.warn(`User '${this.props.user.getId()}' has an unsupported amount of policy groups`);
    } else {
      this.setState({
        selectedPolicy: this.props.availablePolicyGroups
          .findIndex(policyGroup => policyGroup === usersPolicyGroups[0]) ?? null,
        isOwner: false,
      });
    }
  }

  private takeUserAction = (): void => {
    this.props.actionCallback(this.props.userAction, this.props.user);
  };

  private handlePermissionSelection = async (index?: number): Promise<void> => {
    const oldPolicyGroup = this.state.selectedPolicy != null ? this.props.availablePolicyGroups[this.state.selectedPolicy] : undefined;
    const newPolicyGroup = index != null ? this.props.availablePolicyGroups[index] : undefined;

    // no change
    if (oldPolicyGroup?.getId() === newPolicyGroup?.getId()) {
      return;
    }
    
    this.setState({ loading: true });
    
    const notAvailable = translations.common.texts.notAvailable();
    
    try {
      await oldPolicyGroup?.removeUser(this.props.user);
    } catch (err) {
      console.error("Could not remove user from policy group", err);
      this.setState({
        error: translations.admin.texts.failedToRemoveUserFromPolicyGroup({ policyGroupName: oldPolicyGroup?.getName() ?? notAvailable }),
        loading: false,
      });
      return;
    }

    try {
      await newPolicyGroup?.addUser(this.props.user);

      this.setState({ selectedPolicy: index });
    } catch (err) {
      console.error("Could not add user to policy group", err);
      this.setState({ error: translations.admin.texts.failedToAddUserToPolicyGroup({ policyGroupName: newPolicyGroup?.getName() ?? notAvailable }) });
      // restore the previous policy group, since we removed the user from it already
      oldPolicyGroup?.addUser(this.props.user);
    } finally {
      this.setState({ loading: false });
    }
  };

  private renderActionButton(): Maybe<JSX.Element> {
    let buttonText: Maybe<string>;

    if (this.props.userAction === UserOrganizationAction.DeleteUser) {
      buttonText = translations.common.buttons.delete();
    } else if (this.props.userAction === UserOrganizationAction.RemoveFromOrganization) {
      buttonText = translations.common.buttons.remove();
    }

    if (buttonText && this.state.isOwner === false) {
      return (
        <CustomButton
          onClick={this.takeUserAction}
          variant="contained"
          color="secondary"
          data-testid={`${this.props["data-testid"]}-action-button`}
        >
          {buttonText}
        </CustomButton>
      );
    }
  }

  private renderPermissions(): ReactNode {
    if (this.state.loading) {
      return (
        <Loader size="small" />
      );
    }
    
    if (this.state.isOwner) {
      return (
        <Typography variant={"subtitle1"}>
          {translations.admin.texts.owner()}
        </Typography>
      );
    } else {
      return (
        <DropdownSelection
          onSelect={this.handlePermissionSelection}
          selectionList={this.props.availablePolicyGroups.map((pg) => ({ key: pg.getId(), label: pg.getName() }))}
          currentSelection={this.state.selectedPolicy}
          disabled={!this.props.enablePolicySelection}
          data-testid={`${this.props["data-testid"]}-policy-group`}
        />
      );
    }
    
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <TableRow key={this.props.user.getId()}>
          <TableCell component="th" scope="row">{this.props.user.getUsername()}</TableCell>
          <TableCell align="center">{this.renderPermissions()}</TableCell>
          <TableCell align="right">{this.renderActionButton()}</TableCell>
        </TableRow>
        <ErrorDialog errorMsg={this.state.error} onClose={():void => this.setState({ error: undefined })} data-testid="change-policy-group-error"/>
      </Fragment>
    );
  }
}
