/* Copyright */
import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";

import CustomTable, { Cell, Row } from "../../ui/custom-table";
import { RoadDevice } from "../../../client/devices/RoadDevice/RoadDevice";
import LatestData, { LatestDataObserver } from "../../../data/data/LatestData";
import { RoadDeviceData } from "../../../client/devices/RoadDevice/RoadDeviceData";
import { Maybe } from "../../../types/aliases";
import Loader from "../../ui/loader";
import SpeedLimitScheduler from "./speed-limit-scheduler";
import { SpeedLimitSignHW } from "../../../client/devices/SpeedLimitSignHW/SpeedLimitSignHW";
import { SpeedLimitSignHWState } from "../../../client/devices/SpeedLimitSignHW/SpeedLimitSignHWState";
import Device, { DeviceObserver } from "../../../data/device/Device";
import { translations } from "../../../generated/translationHelper";
import { getDynamicTranslation } from "../../../locales/localizationUtils";

interface Props {
  resource: RoadDevice;
}

export default function RoadContent(props: Props): JSX.Element {
  const [latestData, setLatestData] = useState<RoadDeviceData | undefined>();
  const [deviceState, setDeviceState] = useState<SpeedLimitSignHWState | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const loadingTasks = useRef(0);

  const latestDataObserver = useRef<LatestDataObserver>({
    onDataUpdate: (dataClient: LatestData<RoadDeviceData>): void => {
      setLatestData(dataClient.getData()); 
    },
  });

  const deviceStateObserver = useRef<DeviceObserver>({
    onDeviceStateUpdated: (device: Device): void => {
      if (SpeedLimitSignHW.instanceOf(device)) setDeviceState(device.getState());
    },
  });

  const addToLoadingQueue = (): void => {
    loadingTasks.current++;
    setIsLoading(loadingTasks.current > 0);
  };

  const removeFromLoadingQueue = (): void => {
    loadingTasks.current--;
    setIsLoading(loadingTasks.current > 0);
  };

  const setLatestDataClient = async (dataClient: LatestData<RoadDeviceData>): Promise<void> => {
    if (!dataClient.getData()) {
      addToLoadingQueue();
      await dataClient.fetch();
      removeFromLoadingQueue();
    }
    setLatestData(dataClient.getData());
  };

  const setDeviceStateClient = async (device: SpeedLimitSignHW): Promise<void> => {
    if (!device.getState()) {
      addToLoadingQueue();
      await device.init();
      removeFromLoadingQueue();
    }
    setDeviceState(device.getState());
  };

  useEffect(() => {
    const currentLatestDataObserver = latestDataObserver.current;
    const currentDeviceStateObserver = deviceStateObserver.current;
    const dataClient = props.resource.getLatestData();    
    setLatestDataClient(dataClient);

    if (SpeedLimitSignHW.instanceOf(props.resource)) {
      setDeviceStateClient(props.resource);
      props.resource.addObserver(deviceStateObserver.current);
    }
    dataClient.addObserver(latestDataObserver.current);

    return (): void => {
      dataClient.removeObserver(currentLatestDataObserver);
      props.resource.removeObserver(currentDeviceStateObserver);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resource]);

  const renderLoader = (): Maybe<JSX.Element> => {
    if (isLoading) {
      return <Loader/>;
    }
  };

  const getHeaders = (): Cell<RoadDeviceData>[] => {
    if (latestData) {
      return [{ id: "measurement", value: translations.common.texts.measurement() }, { id: "value", value: translations.common.texts.value() }];
    }
    return [];
  };

  const getBody = (): Row<RoadDeviceData>[] => {
    if (latestData) {
      const invalidKeys: (keyof RoadDeviceData)[] = ["sessionId", "type"];
      const filteredLatestData = Object.entries(latestData).filter((entry) => !invalidKeys.includes(entry[0]));
      return filteredLatestData.map((entry) => ({
        id: entry[0],
        cells: [
          {
            id: entry[0],
            value: getDynamicTranslation(translations.common.data, entry[0]),
          },
          {
            id: entry[0] + "_value",
            value: entry[1],
          },
        ],
      }));
    }
    return [];
  };

  return renderLoader() ?? (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <CustomTable
          header={getHeaders()}
          body={getBody()}
          title={translations.status.texts.status()}
          emptyTableText={translations.common.texts.noDataAvailable()}
        />
      </Grid>
      { deviceState &&
        <Grid item>
          <SpeedLimitScheduler state={deviceState} />
        </Grid>
      }
    </Grid>
  );
}
