/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import DataSet from "./DataSet";
import { DataUtil } from "./Data";
import { Maybe } from "../../types/aliases";
import { DevicesMeasurementsUpdateFeedDocument, DevicesMeasurementsUpdateFeedSubscription } from "../../generated/gqlData";
import AbstractMapSubscriptionManager from "../utils/subscriptions/AbstractMapSubscriptionManager";

// REFACTOR: Could LatestDataSubscriptionManager and DataSubscriptionManager be
// combined through Generics?
export default class DataSubscriptionManager extends AbstractMapSubscriptionManager<DataSet, DevicesMeasurementsUpdateFeedSubscription> {
  private static __instance = new DataSubscriptionManager();

  private constructor() {
    super(Service.DATA, DevicesMeasurementsUpdateFeedDocument);
  }

  public static get instance(): DataSubscriptionManager {
    return this.__instance;
  }

  protected subscriptionHandler(result: Maybe<DevicesMeasurementsUpdateFeedSubscription>): void {
    if (!result?.devicesMeasurementsUpdateFeed) return;

    const dataItem = DataUtil.parseDataFragment(result.devicesMeasurementsUpdateFeed.item);

    if (dataItem) {
      this.getListener(dataItem.deviceId)?.appendData(dataItem);
    }
  }
}
