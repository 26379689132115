/* Copyright */

import { Grid } from "@material-ui/core";
import React from "react";
import { RoadDevice } from "../../../client/devices/RoadDevice/RoadDevice";

interface Props {
  resource: RoadDevice;
}

export default function RoadContent(_props: Props): JSX.Element {
  return (
    <Grid container direction="column" spacing={3}>
      
    </Grid>
  );
}
