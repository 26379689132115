/* Copyright */
import AWSThing from "../../../data/device/AWSThing";
import DeviceState from "../../../data/device/DeviceState";
import { Maybe } from "../../../types/aliases";
import { CoordinateLocation } from "../RailroadDevice/RailroadDevice";
import { RoadDeviceData } from "./RoadDeviceData";

export abstract class RoadDevice<TData extends RoadDeviceData = RoadDeviceData, TState extends DeviceState = DeviceState> extends AWSThing<TData, TState> {

  public async getLocation(): Promise<Maybe<CoordinateLocation>> {
    const latestDataClient = this.getLatestData();

    if (!latestDataClient.getData()) await latestDataClient.fetch();

    const latestData = latestDataClient.getData();

    if (latestData?.latitude && latestData?.longitude) {
      return [latestData.latitude, latestData.longitude];
    }
  }
  
  public static instanceOf(value: unknown): value is RoadDevice {
    return value instanceof RoadDevice;
  }
}
