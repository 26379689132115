/*
*  Copyright (C) 2021 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { TOptionsBase } from "i18next";
import { t } from "../locales/localisator";

type ValueRecord = Record<string, string | number | boolean>;
export type OptionParams = Omit<TOptionsBase, "defaultValue"> & ValueRecord;

export const translations = {
  "logIn": {
    "texts": {
      "enterVerificationCodeAndPassword": (options?: OptionParams): string => t("logIn.texts.enterVerificationCodeAndPassword", options),
      "emailFormatNotValid": (options?: OptionParams): string => t("logIn.texts.emailFormatNotValid", options),
      "emailNotVerified": (options?: OptionParams): string => t("logIn.texts.emailNotVerified", options),
      "temporaryPasswordExpired": (options?: OptionParams): string => t("logIn.texts.temporaryPasswordExpired", options),
      "passwordCannotBeEmpty": (options?: OptionParams): string => t("logIn.texts.passwordCannotBeEmpty", options),
      "usernameCannotBeEmpty": (options?: OptionParams): string => t("logIn.texts.usernameCannotBeEmpty", options),
      "passwordReset": (options?: OptionParams): string => t("logIn.texts.passwordReset", options),
      "passwordCannotBeResetCurrently": (options?: OptionParams): string => t("logIn.texts.passwordCannotBeResetCurrently", options),
      "verificationCodeSentToEmail": (options?: OptionParams): string => t("logIn.texts.verificationCodeSentToEmail", options),
      "invalidCode": (options?: OptionParams): string => t("logIn.texts.invalidCode", options),
      "codeExpired": (options?: OptionParams): string => t("logIn.texts.codeExpired", options),
      "newPasswordRequired": (options?: OptionParams): string => t("logIn.texts.newPasswordRequired", options),
      "incorrectCredentials": (options?: OptionParams): string => t("logIn.texts.incorrectCredentials", options),
      "userNotLoggedIn": (options?: OptionParams): string => t("logIn.texts.userNotLoggedIn", options)
    },
    "inputs": {
      "confirmationCode": (options?: OptionParams): string => t("logIn.inputs.confirmationCode", options),
      "password": (options?: OptionParams): string => t("logIn.inputs.password", options)
    },
    "buttons": {
      "logIn": (options?: OptionParams): string => t("logIn.buttons.logIn", options),
      "backToLogIn": (options?: OptionParams): string => t("logIn.buttons.backToLogIn", options),
      "confirmLogIn": (options?: OptionParams): string => t("logIn.buttons.confirmLogIn", options),
      "forgotPassword": (options?: OptionParams): string => t("logIn.buttons.forgotPassword", options),
      "verificationCode": (options?: OptionParams): string => t("logIn.buttons.verificationCode", options)
    },
  },
  "deviceDrawer": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceDrawer.texts.title", options),
      "noDevicesOrGroupsFound": (options?: OptionParams): string => t("deviceDrawer.texts.noDevicesOrGroupsFound", options)
    },
  },
  "deviceSettings": {
    "tabs": {
      "general": (options?: OptionParams): string => t("deviceSettings.tabs.general", options),
      "attributes": (options?: OptionParams): string => t("deviceSettings.tabs.attributes", options),
      "otaUpdate": (options?: OptionParams): string => t("deviceSettings.tabs.otaUpdate", options)
    },
    "buttons": {
      "installOta": (options?: OptionParams): string => t("deviceSettings.buttons.installOta", options),
      "cancelOta": (options?: OptionParams): string => t("deviceSettings.buttons.cancelOta", options)
    },
    "texts": {
      "noAttributesDefined": (options?: OptionParams): string => t("deviceSettings.texts.noAttributesDefined", options),
      "HWID": (options?: OptionParams): string => t("deviceSettings.texts.HWID", options),
      "canSee": (options?: OptionParams): string => t("deviceSettings.texts.canSee", options),
      "organization": (options?: OptionParams): string => t("deviceSettings.texts.organization", options),
      "serialNumber": (options?: OptionParams): string => t("deviceSettings.texts.serialNumber", options),
      "disableModemSleep": (options?: OptionParams): string => t("deviceSettings.texts.disableModemSleep", options),
      "updateInterval": (options?: OptionParams): string => t("deviceSettings.texts.updateInterval", options),
      "measurementInterval": (options?: OptionParams): string => t("deviceSettings.texts.measurementInterval", options),
      "firmwareVersion": (options?: OptionParams): string => t("deviceSettings.texts.firmwareVersion", options),
      "resetCount": (options?: OptionParams): string => t("deviceSettings.texts.resetCount", options),
      "size": (options?: OptionParams): string => t("deviceSettings.texts.size", options),
      "version": (options?: OptionParams): string => t("deviceSettings.texts.version", options),
      "description": (options?: OptionParams): string => t("deviceSettings.texts.description", options),
      "otaId": (options?: OptionParams): string => t("deviceSettings.texts.otaId", options),
      "noUpdateAvailable": (options?: OptionParams): string => t("deviceSettings.texts.noUpdateAvailable", options),
      "noInstallationInProgress": (options?: OptionParams): string => t("deviceSettings.texts.noInstallationInProgress", options),
      "availableOtaPackages": (options?: OptionParams): string => t("deviceSettings.texts.availableOtaPackages", options),
      "otaUpdateProcess": (options?: OptionParams): string => t("deviceSettings.texts.otaUpdateProcess", options),
      "deviceFirmwareInformation": (options?: OptionParams): string => t("deviceSettings.texts.deviceFirmwareInformation", options),
      "ledColor": (options?: OptionParams): string => t("deviceSettings.texts.ledColor", options),
      "toggleLed": (options?: OptionParams): string => t("deviceSettings.texts.toggleLed", options)
    },
  },
  "status": {
    "texts": {
      "activeDevices": (options?: OptionParams): string => t("status.texts.activeDevices", options),
      "showActiveDevicesWithin": (options?: OptionParams): string => t("status.texts.showActiveDevicesWithin", options),
      "locationAvailable": (options?: OptionParams): string => t("status.texts.locationAvailable", options),
      "locationUnavailable": (options?: OptionParams): string => t("status.texts.locationUnavailable", options),
      "crossingDevices": (options?: OptionParams): string => t("status.texts.crossingDevices", options),
      "devices": (options?: OptionParams): string => t("status.texts.devices", options),
      "status": (options?: OptionParams): string => t("status.texts.status", options),
      "weekdayTurnSchedules": (options?: OptionParams): string => t("status.texts.weekdayTurnSchedules", options),
      "dateTurnSchedules": (options?: OptionParams): string => t("status.texts.dateTurnSchedules", options),
      "turnScheduleEditor": (options?: OptionParams): string => t("status.texts.turnScheduleEditor", options),
      "formatInDDMM": (options?: OptionParams): string => t("status.texts.formatInDDMM", options),
      "firstActivity": (options?: OptionParams): string => t("status.texts.firstActivity", options),
      "lastActivity": (options?: OptionParams): string => t("status.texts.lastActivity", options),
      "totalMessages": (options?: OptionParams): string => t("status.texts.totalMessages", options),
      "totalDuration": (options?: OptionParams): string => t("status.texts.totalDuration", options),
      "dailyMessageCountAverage": (options?: OptionParams): string => t("status.texts.dailyMessageCountAverage", options),
      "lastXhoursTotalDuration": (options?: OptionParams): string => t("status.texts.lastXhoursTotalDuration", options),
      "dailyUsage": (options?: OptionParams): string => t("status.texts.dailyUsage", options),
      "temperature": (options?: OptionParams): string => t("status.texts.temperature", options),
      "humidity": (options?: OptionParams): string => t("status.texts.humidity", options),
      "remainingBattery": (options?: OptionParams): string => t("status.texts.remainingBattery", options),
      "usedBatteryCapacity": (options?: OptionParams): string => t("status.texts.usedBatteryCapacity", options),
      "remainingBatteryCapacity": (options?: OptionParams): string => t("status.texts.remainingBatteryCapacity", options),
      "batteryMaxCapacity": (options?: OptionParams): string => t("status.texts.batteryMaxCapacity", options),
      "batteryCurrentAverage": (options?: OptionParams): string => t("status.texts.batteryCurrentAverage", options),
      "batteryCurrentDrawPerSecond": (options?: OptionParams): string => t("status.texts.batteryCurrentDrawPerSecond", options),
      "batteryDailyUsageOnAverage": (options?: OptionParams): string => t("status.texts.batteryDailyUsageOnAverage", options),
      "batteryLifetimeEstimate": (options?: OptionParams): string => t("status.texts.batteryLifetimeEstimate", options),
      "batteryExpirationEstimate": (options?: OptionParams): string => t("status.texts.batteryExpirationEstimate", options)
    },
    "inputs": {
      "fiveMin": (options?: OptionParams): string => t("status.inputs.fiveMin", options),
      "fifteenMin": (options?: OptionParams): string => t("status.inputs.fifteenMin", options),
      "hour": (options?: OptionParams): string => t("status.inputs.hour", options),
      "sixHours": (options?: OptionParams): string => t("status.inputs.sixHours", options),
      "day": (options?: OptionParams): string => t("status.inputs.day", options),
      "week": (options?: OptionParams): string => t("status.inputs.week", options),
      "month": (options?: OptionParams): string => t("status.inputs.month", options)
    },
    "buttons": {
      "addWeekdaySchedule": (options?: OptionParams): string => t("status.buttons.addWeekdaySchedule", options),
      "addDateSchedule": (options?: OptionParams): string => t("status.buttons.addDateSchedule", options),
      "saveScheduleChanges": (options?: OptionParams): string => t("status.buttons.saveScheduleChanges", options),
      "revertScheduleChanges": (options?: OptionParams): string => t("status.buttons.revertScheduleChanges", options)
    },
  },
  "history": {
    "texts": {
      "pleaseSelectDeviceAndTimePeriod": (options?: OptionParams): string => t("history.texts.pleaseSelectDeviceAndTimePeriod", options),
      "noTimeSeriesDataFound": (options?: OptionParams): string => t("history.texts.noTimeSeriesDataFound", options),
      "deviceSelected": (options?: OptionParams): string => t("history.texts.deviceSelected", options),
      "notEnoughDataForVisualisation": (options?: OptionParams): string => t("history.texts.notEnoughDataForVisualisation", options),
      "dragToZoomRightClickToReset": (options?: OptionParams): string => t("history.texts.dragToZoomRightClickToReset", options),
      "live": (options?: OptionParams): string => t("history.texts.live", options),
      "session": (options?: OptionParams): string => t("history.texts.session", options)
    },
    "buttons": {
      "exportAsCSV": (options?: OptionParams): string => t("history.buttons.exportAsCSV", options)
    },
  },
  "user": {
    "texts": {
      "chooseLanguage": (options?: OptionParams): string => t("user.texts.chooseLanguage", options),
      "doYouWantToLogOut": (options?: OptionParams): string => t("user.texts.doYouWantToLogOut", options),
      "enterFirstnameAndLastname": (options?: OptionParams): string => t("user.texts.enterFirstnameAndLastname", options),
      "enterPhoneNumber": (options?: OptionParams): string => t("user.texts.enterPhoneNumber", options),
      "enterOldPasswordAndNewPassword": (options?: OptionParams): string => t("user.texts.enterOldPasswordAndNewPassword", options),
      "youAreAboutToLogOut": (options?: OptionParams): string => t("user.texts.youAreAboutToLogOut", options),
      "invalidPhoneNumberFormat": (options?: OptionParams): string => t("user.texts.invalidPhoneNumberFormat", options),
      "invalidName": (options?: OptionParams): string => t("user.texts.invalidName", options),
      "logOut": (options?: OptionParams): string => t("user.texts.logOut", options),
      "incorrectCredentials": (options?: OptionParams): string => t("user.texts.incorrectCredentials", options)
    },
    "buttons": {
      "changeLanguage": (options?: OptionParams): string => t("user.buttons.changeLanguage", options),
      "confirmNameChange": (options?: OptionParams): string => t("user.buttons.confirmNameChange", options),
      "updatePhoneNumber": (options?: OptionParams): string => t("user.buttons.updatePhoneNumber", options),
      "logOut": (options?: OptionParams): string => t("user.buttons.logOut", options),
      "confirmPasswordChange": (options?: OptionParams): string => t("user.buttons.confirmPasswordChange", options)
    },
    "inputs": {
      "firstname": (options?: OptionParams): string => t("user.inputs.firstname", options),
      "lastname": (options?: OptionParams): string => t("user.inputs.lastname", options),
      "phonenumber": (options?: OptionParams): string => t("user.inputs.phonenumber", options),
      "oldPassword": (options?: OptionParams): string => t("user.inputs.oldPassword", options)
    },
    "languages": {
      "en": (options?: OptionParams): string => t("user.languages.en", options),
      "fi": (options?: OptionParams): string => t("user.languages.fi", options)
    },
  },
  "admin": {
    "texts": {
      "groups": (options?: OptionParams): string => t("admin.texts.groups", options),
      "devices": (options?: OptionParams): string => t("admin.texts.devices", options),
      "addNewDevice": (options?: OptionParams): string => t("admin.texts.addNewDevice", options),
      "addNewGroup": (options?: OptionParams): string => t("admin.texts.addNewGroup", options),
      "groupName": (options?: OptionParams): string => t("admin.texts.groupName", options),
      "failedToAddDevice": (options?: OptionParams): string => t("admin.texts.failedToAddDevice", options),
      "failedToAddGroup": (options?: OptionParams): string => t("admin.texts.failedToAddGroup", options),
      "failedToRemoveGroup": (options?: OptionParams): string => t("admin.texts.failedToRemoveGroup", options),
      "newNotification": (options?: OptionParams): string => t("admin.texts.newNotification", options),
      "selectUser": (options?: OptionParams): string => t("admin.texts.selectUser", options),
      "selectEventType": (options?: OptionParams): string => t("admin.texts.selectEventType", options),
      "addNotification": (options?: OptionParams): string => t("admin.texts.addNotification", options),
      "failedToAddNotification": (options?: OptionParams): string => t("admin.texts.failedToAddNotification", options),
      "notificationManagement": (options?: OptionParams): string => t("admin.texts.notificationManagement", options),
      "removeSubscription": (options?: OptionParams): string => t("admin.texts.removeSubscription", options),
      "removeGroup": (options?: OptionParams): string => t("admin.texts.removeGroup", options),
      "delete": (options?: OptionParams): string => t("admin.texts.delete", options),
      "failedToDeleteSubscription": (options?: OptionParams): string => t("admin.texts.failedToDeleteSubscription", options),
      "organisationsChildren": (options?: OptionParams): string => t("admin.texts.organisationsChildren", options),
      "organisationDetails": (options?: OptionParams): string => t("admin.texts.organisationDetails", options),
      "organisationsPolicyGroups": (options?: OptionParams): string => t("admin.texts.organisationsPolicyGroups", options),
      "noUsers": (options?: OptionParams): string => t("admin.texts.noUsers", options),
      "failedToDeleteUser": (options?: OptionParams): string => t("admin.texts.failedToDeleteUser", options),
      "failedToCreateUser": (options?: OptionParams): string => t("admin.texts.failedToCreateUser", options),
      "userAlreadyExists": (options?: OptionParams): string => t("admin.texts.userAlreadyExists", options),
      "deleteUser": (options?: OptionParams): string => t("admin.texts.deleteUser", options),
      "removeUser": (options?: OptionParams): string => t("admin.texts.removeUser", options),
      "policyGroup": (options?: OptionParams): string => t("admin.texts.policyGroup", options),
      "invalidEmailAddress": (options?: OptionParams): string => t("admin.texts.invalidEmailAddress", options),
      "policyGroupMustBeSelected": (options?: OptionParams): string => t("admin.texts.policyGroupMustBeSelected", options),
      "createNewUser": (options?: OptionParams): string => t("admin.texts.createNewUser", options),
      "failedToRemoveUserFromPolicyGroup": (options?: OptionParams): string => t("admin.texts.failedToRemoveUserFromPolicyGroup", options),
      "failedToAddUserToPolicyGroup": (options?: OptionParams): string => t("admin.texts.failedToAddUserToPolicyGroup", options),
      "owner": (options?: OptionParams): string => t("admin.texts.owner", options),
      "organisations": (options?: OptionParams): string => t("admin.texts.organisations", options),
      "organisation": (options?: OptionParams): string => t("admin.texts.organisation", options),
      "users": (options?: OptionParams): string => t("admin.texts.users", options),
      "policies": (options?: OptionParams): string => t("admin.texts.policies", options),
      "notifications": (options?: OptionParams): string => t("admin.texts.notifications", options),
      "deviceIdentifier": (options?: OptionParams): string => t("admin.texts.deviceIdentifier", options),
      "type": (options?: OptionParams): string => t("admin.texts.type", options),
      "event": (options?: OptionParams): string => t("admin.texts.event", options),
      "user": (options?: OptionParams): string => t("admin.texts.user", options),
      "noNotificationsAvailable": (options?: OptionParams): string => t("admin.texts.noNotificationsAvailable", options),
      "notificationFromUser": (options?: OptionParams): string => t("admin.texts.notificationFromUser", options),
      "failedToDeleteOrganization": (options?: OptionParams): string => t("admin.texts.failedToDeleteOrganization", options),
      "failedToDeleteOrganizationHasChildren": (options?: OptionParams): string => t("admin.texts.failedToDeleteOrganizationHasChildren", options),
      "deleteOrganization": (options?: OptionParams): string => t("admin.texts.deleteOrganization", options),
      "deleteOrganizationConfirmation": (options?: OptionParams): string => t("admin.texts.deleteOrganizationConfirmation", options),
      "groupType": (options?: OptionParams): string => t("admin.texts.groupType", options),
      "generalSettings": (options?: OptionParams): string => t("admin.texts.generalSettings", options),
      "crossingId": (options?: OptionParams): string => t("admin.texts.crossingId", options)
    },
    "buttons": {
      "addDevice": (options?: OptionParams): string => t("admin.buttons.addDevice", options),
      "addSubGroup": (options?: OptionParams): string => t("admin.buttons.addSubGroup", options),
      "removeGroup": (options?: OptionParams): string => t("admin.buttons.removeGroup", options),
      "editDevice": (options?: OptionParams): string => t("admin.buttons.editDevice", options)
    },
    "inputs": {
      "user": (options?: OptionParams): string => t("admin.inputs.user", options),
      "policyGroup": (options?: OptionParams): string => t("admin.inputs.policyGroup", options),
      "eventType": (options?: OptionParams): string => t("admin.inputs.eventType", options),
      "emailAlert": (options?: OptionParams): string => t("admin.inputs.emailAlert", options),
      "textMessageAlert": (options?: OptionParams): string => t("admin.inputs.textMessageAlert", options),
      "organisationName": (options?: OptionParams): string => t("admin.inputs.organisationName", options),
      "selectChild": (options?: OptionParams): string => t("admin.inputs.selectChild", options),
      "newOrganisationsName": (options?: OptionParams): string => t("admin.inputs.newOrganisationsName", options)
    },
  },
  "events": {
    "data": {
      "severity": (options?: OptionParams): string => t("events.data.severity", options),
      "sensor": (options?: OptionParams): string => t("events.data.sensor", options),
      "sensorValue": (options?: OptionParams): string => t("events.data.sensorValue", options),
      "metadata": (options?: OptionParams): string => t("events.data.metadata", options),
      "state": (options?: OptionParams): string => t("events.data.state", options),
      "type": (options?: OptionParams): string => t("events.data.type", options),
      "date": (options?: OptionParams): string => t("events.data.date", options)
    },
    "texts": {
      "events": (options?: OptionParams): string => t("events.texts.events", options),
      "noEventsFound": (options?: OptionParams): string => t("events.texts.noEventsFound", options),
      "selectDevice": (options?: OptionParams): string => t("events.texts.selectDevice", options)
    },
    "buttons": {
      "acknowledge": (options?: OptionParams): string => t("events.buttons.acknowledge", options)
    },
  },
  "analytics": {
    "inputs": {
      "onlySelectedDevice": (options?: OptionParams): string => t("analytics.inputs.onlySelectedDevice", options),
      "period": (options?: OptionParams): string => t("analytics.inputs.period", options),
      "today": (options?: OptionParams): string => t("analytics.inputs.today", options),
      "yesterday": (options?: OptionParams): string => t("analytics.inputs.yesterday", options),
      "lastXdays": (options?: OptionParams): string => t("analytics.inputs.lastXdays", options)
    },
    "texts": {
      "dashboardLoadError": (options?: OptionParams): string => t("analytics.texts.dashboardLoadError", options),
      "dashboardCreateError": (options?: OptionParams): string => t("analytics.texts.dashboardCreateError", options)
    },
  },
  "service": {
    "texts": {
      "upcomingServices": (options?: OptionParams): string => t("service.texts.upcomingServices", options),
      "pastServices": (options?: OptionParams): string => t("service.texts.pastServices", options),
      "noUpcomingServicesFound": (options?: OptionParams): string => t("service.texts.noUpcomingServicesFound", options),
      "noPastServicesFound": (options?: OptionParams): string => t("service.texts.noPastServicesFound", options),
      "service": (options?: OptionParams): string => t("service.texts.service", options),
      "servicedBy": (options?: OptionParams): string => t("service.texts.servicedBy", options),
      "registerAService": (options?: OptionParams): string => t("service.texts.registerAService", options),
      "viewAService": (options?: OptionParams): string => t("service.texts.viewAService", options),
      "serviceCompleted": (options?: OptionParams): string => t("service.texts.serviceCompleted", options),
      "nextService": (options?: OptionParams): string => t("service.texts.nextService", options),
      "dateOfService": (options?: OptionParams): string => t("service.texts.dateOfService", options),
      "dateOfNextService": (options?: OptionParams): string => t("service.texts.dateOfNextService", options),
      "description": (options?: OptionParams): string => t("service.texts.description", options),
      "severity": (options?: OptionParams): string => t("service.texts.severity", options),
      "errorCode": (options?: OptionParams): string => t("service.texts.errorCode", options),
      "diagnosticIssuesBoundToService": (options?: OptionParams): string => t("service.texts.diagnosticIssuesBoundToService", options)
    },
    "buttons": {
      "registerService": (options?: OptionParams): string => t("service.buttons.registerService", options),
      "enableServiceMode": (options?: OptionParams): string => t("service.buttons.enableServiceMode", options),
      "disableServiceMode": (options?: OptionParams): string => t("service.buttons.disableServiceMode", options)
    },
  },
  "common": {
    "texts": {
      "measurement": (options?: OptionParams): string => t("common.texts.measurement", options),
      "value": (options?: OptionParams): string => t("common.texts.value", options),
      "side": (options?: OptionParams): string => t("common.texts.side", options),
      "weekday": (options?: OptionParams): string => t("common.texts.weekday", options),
      "date": (options?: OptionParams): string => t("common.texts.date", options),
      "day": (options?: OptionParams): string => t("common.texts.day", options),
      "time": (options?: OptionParams): string => t("common.texts.time", options),
      "name": (options?: OptionParams): string => t("common.texts.name", options),
      "total": (options?: OptionParams): string => t("common.texts.total", options),
      "due": (options?: OptionParams): string => t("common.texts.due", options),
      "unit": (options?: OptionParams): string => t("common.texts.unit", options),
      "type": (options?: OptionParams): string => t("common.texts.type", options),
      "id": (options?: OptionParams): string => t("common.texts.id", options),
      "pleaseSelectResource": (options?: OptionParams): string => t("common.texts.pleaseSelectResource", options),
      "username": (options?: OptionParams): string => t("common.texts.username", options),
      "status": (options?: OptionParams): string => t("common.texts.status", options),
      "service": (options?: OptionParams): string => t("common.texts.service", options),
      "history": (options?: OptionParams): string => t("common.texts.history", options),
      "analytics": (options?: OptionParams): string => t("common.texts.analytics", options),
      "events": (options?: OptionParams): string => t("common.texts.events", options),
      "user": (options?: OptionParams): string => t("common.texts.user", options),
      "admin": (options?: OptionParams): string => t("common.texts.admin", options),
      "errorOccurred": (options?: OptionParams): string => t("common.texts.errorOccurred", options),
      "notAvailable": (options?: OptionParams): string => t("common.texts.notAvailable", options),
      "sort": (options?: OptionParams): string => t("common.texts.sort", options),
      "error": (options?: OptionParams): string => t("common.texts.error", options),
      "noDataAvailable": (options?: OptionParams): string => t("common.texts.noDataAvailable", options),
      "passwordsNotMatching": (options?: OptionParams): string => t("common.texts.passwordsNotMatching", options),
      "tooManyAttempts": (options?: OptionParams): string => t("common.texts.tooManyAttempts", options),
      "networkError": (options?: OptionParams): string => t("common.texts.networkError", options),
      "userSessionExpired": (options?: OptionParams): string => t("common.texts.userSessionExpired", options),
      "unableToPerformAction": (options?: OptionParams): string => t("common.texts.unableToPerformAction", options),
      "passwordMustHaveNumbers": (options?: OptionParams): string => t("common.texts.passwordMustHaveNumbers", options),
      "passwordMustHaveLowercaseCharacters": (options?: OptionParams): string => t("common.texts.passwordMustHaveLowercaseCharacters", options),
      "passwordMustBeLongEnough": (options?: OptionParams): string => t("common.texts.passwordMustBeLongEnough", options),
      "of": (options?: OptionParams): string => t("common.texts.of", options),
      "showColumns": (options?: OptionParams): string => t("common.texts.showColumns", options),
      "filters": (options?: OptionParams): string => t("common.texts.filters", options),
      "crossings": (options?: OptionParams): string => t("common.texts.crossings", options),
      "crossing": (options?: OptionParams): string => t("common.texts.crossing", options),
      "customer": (options?: OptionParams): string => t("common.texts.customer", options),
      "factory": (options?: OptionParams): string => t("common.texts.factory", options),
      "normal": (options?: OptionParams): string => t("common.texts.normal", options),
      "location": (options?: OptionParams): string => t("common.texts.location", options)
    },
    "inputs": {
      "from": (options?: OptionParams): string => t("common.inputs.from", options),
      "to": (options?: OptionParams): string => t("common.inputs.to", options),
      "custom": (options?: OptionParams): string => t("common.inputs.custom", options),
      "none": (options?: OptionParams): string => t("common.inputs.none", options),
      "newPassword": (options?: OptionParams): string => t("common.inputs.newPassword", options),
      "email": (options?: OptionParams): string => t("common.inputs.email", options),
      "confirmNewPassword": (options?: OptionParams): string => t("common.inputs.confirmNewPassword", options),
      "search": (options?: OptionParams): string => t("common.inputs.search", options),
      "displayedRows": (options?: OptionParams): string => t("common.inputs.displayedRows", options),
      "rowsPerPage": (options?: OptionParams): string => t("common.inputs.rowsPerPage", options)
    },
    "data": {
      "angle": (options?: OptionParams): string => t("common.data.angle", options),
      "timestamp": (options?: OptionParams): string => t("common.data.timestamp", options),
      "description": (options?: OptionParams): string => t("common.data.description", options),
      "temperature": (options?: OptionParams): string => t("common.data.temperature", options),
      "humidity": (options?: OptionParams): string => t("common.data.humidity", options),
      "pressure": (options?: OptionParams): string => t("common.data.pressure", options),
      "batteryLevel": (options?: OptionParams): string => t("common.data.batteryLevel", options),
      "battVoltage": (options?: OptionParams): string => t("common.data.battVoltage", options),
      "latitude": (options?: OptionParams): string => t("common.data.latitude", options),
      "longitude": (options?: OptionParams): string => t("common.data.longitude", options),
      "speed": (options?: OptionParams): string => t("common.data.speed", options),
      "deviceId": (options?: OptionParams): string => t("common.data.deviceId", options),
      "displayName": (options?: OptionParams): string => t("common.data.displayName", options),
      "rssi": (options?: OptionParams): string => t("common.data.rssi", options),
      "heapSize": (options?: OptionParams): string => t("common.data.heapSize", options),
      "sessionId": (options?: OptionParams): string => t("common.data.sessionId", options),
      "type": (options?: OptionParams): string => t("common.data.type", options),
      "monday": (options?: OptionParams): string => t("common.data.monday", options),
      "tuesday": (options?: OptionParams): string => t("common.data.tuesday", options),
      "wednesday": (options?: OptionParams): string => t("common.data.wednesday", options),
      "thursday": (options?: OptionParams): string => t("common.data.thursday", options),
      "friday": (options?: OptionParams): string => t("common.data.friday", options),
      "saturday": (options?: OptionParams): string => t("common.data.saturday", options),
      "sunday": (options?: OptionParams): string => t("common.data.sunday", options),
      "A": (options?: OptionParams): string => t("common.data.A", options),
      "B": (options?: OptionParams): string => t("common.data.B", options),
      "HIGH": (options?: OptionParams): string => t("common.data.HIGH", options),
      "MEDIUM": (options?: OptionParams): string => t("common.data.MEDIUM", options),
      "LOW": (options?: OptionParams): string => t("common.data.LOW", options),
      "inactive": (options?: OptionParams): string => t("common.data.inactive", options),
      "low-bat": "common.data.low-bat",
      "error": (options?: OptionParams): string => t("common.data.error", options),
      "ok": (options?: OptionParams): string => t("common.data.ok", options),
      "subDeviceError": (options?: OptionParams): string => t("common.data.subDeviceError", options),
      "subDeviceNotResponding": (options?: OptionParams): string => t("common.data.subDeviceNotResponding", options),
      "lowBattery": (options?: OptionParams): string => t("common.data.lowBattery", options),
      "deviceLeaning": (options?: OptionParams): string => t("common.data.deviceLeaning", options)
    },
    "buttons": {
      "showDisplayNames": (options?: OptionParams): string => t("common.buttons.showDisplayNames", options),
      "hideDisplayNames": (options?: OptionParams): string => t("common.buttons.hideDisplayNames", options),
      "search": (options?: OptionParams): string => t("common.buttons.search", options),
      "submit": (options?: OptionParams): string => t("common.buttons.submit", options),
      "ok": (options?: OptionParams): string => t("common.buttons.ok", options),
      "accept": (options?: OptionParams): string => t("common.buttons.accept", options),
      "create": (options?: OptionParams): string => t("common.buttons.create", options),
      "delete": (options?: OptionParams): string => t("common.buttons.delete", options),
      "remove": (options?: OptionParams): string => t("common.buttons.remove", options),
      "show": (options?: OptionParams): string => t("common.buttons.show", options),
      "back": (options?: OptionParams): string => t("common.buttons.back", options),
      "add": (options?: OptionParams): string => t("common.buttons.add", options),
      "save": (options?: OptionParams): string => t("common.buttons.save", options),
      "apply": (options?: OptionParams): string => t("common.buttons.apply", options),
      "close": (options?: OptionParams): string => t("common.buttons.close", options),
      "confirm": (options?: OptionParams): string => t("common.buttons.confirm", options),
      "cancel": (options?: OptionParams): string => t("common.buttons.cancel", options),
      "settings": (options?: OptionParams): string => t("common.buttons.settings", options),
      "history": (options?: OptionParams): string => t("common.buttons.history", options),
      "nextPage": (options?: OptionParams): string => t("common.buttons.nextPage", options),
      "previousPage": (options?: OptionParams): string => t("common.buttons.previousPage", options),
      "print": (options?: OptionParams): string => t("common.buttons.print", options),
      "downloadCsv": (options?: OptionParams): string => t("common.buttons.downloadCsv", options),
      "filterTable": (options?: OptionParams): string => t("common.buttons.filterTable", options),
      "viewColumns": (options?: OptionParams): string => t("common.buttons.viewColumns", options),
      "reset": (options?: OptionParams): string => t("common.buttons.reset", options)
    },
  },
};
