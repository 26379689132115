/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from "react";
import { Grid, Tab, Box, Tabs } from "@material-ui/core";
import AuthWrapper from "../../data/auth/AuthWrapper";
import DeviceGroup from "../../data/device/DeviceGroup";
import InlineBox from "../ui/inline-box";
import TabPanel from "../ui/tab-panel";
import BackendFactory from "../../data/BackendFactory";
import Organization from "../../data/organization/Organization";
import ViewAccessMethods from "../../ViewAccessMethods";
import OrganizationSelector from "./components/organization-selector";
import Permissions from "./components/permissions";
import DeviceManagement from "./components/device-management";
import OrganizationManagement from "./components/organization-management";
import UserList from "./components/user-list";
import MapDrawer from "../drawers/map-drawer";
import { DrawerState } from "../../data/utils/Utils";
import Device from "../../data/device/Device";
import accessControlled from "../access-control/access-controlled";
import NotificationManagement from "./components/notification-management";
import { translations } from "../../generated/translationHelper";

interface Props {}

interface State {
  rootDeviceGroups: DeviceGroup[];
  selectedTab: number;
  homeOrganization?: Organization;
  selectedOrganization?: Organization;
  currentUserId?: string;
  searchFilter?: string;
  errorMsg?: string;
  selectedDevice?: Device;
}

type TabPageProps = {currentPage: number; index: number};

const TabPage: FunctionComponent<TabPageProps> = (props: PropsWithChildren<TabPageProps>): ReactElement => {
  return (
    <TabPanel
      value={props.currentPage}
      index={props.index}
    >
      <InlineBox>
        {props.children}
      </InlineBox>
    </TabPanel>
  );
};

export class AdminView extends Component<Props, State> {
  private readonly organizationBackend = BackendFactory.getOrganizationBackend();

  public constructor(props: Props) {
    super(props);
    this.state = {
      rootDeviceGroups: [],
      selectedTab: 0,
    };
  }

  public async componentDidMount(): Promise<void> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();

    if (!claims) {
      throw new Error("Failed to resolve current user!");
    }

    const [homeOrganization, rootDeviceGroups] = await Promise.all([
      this.organizationBackend.getCurrentHomeOrganization(),
      BackendFactory.getBackend().getRootDeviceGroups(),
    ]);

    this.setState({
      rootDeviceGroups,
      homeOrganization,
      currentUserId: claims.userId,
      selectedOrganization: homeOrganization,
    });
  }

  private handleOrganizationSelected = (organization: Organization): void => {
    this.setState({
      selectedOrganization: organization,
    });
  };

  private handleGroupsUpdate = async (): Promise<void> => {
    this.setState({ rootDeviceGroups: await BackendFactory.getBackend().getRootDeviceGroups() });
  };
      
  private handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    console.log(`Changing page from ${this.state.selectedTab} to ${value}`);
    this.setState({ selectedTab: value });
  };

  private renderOrganizationStructure(): ReactNode {
    if (this.state.selectedOrganization) {
      return (
        <OrganizationManagement
          organization={this.state.selectedOrganization}
        />
      );
    }
  }

  private renderOrganizationPolicies(): ReactNode {
    if (this.state.selectedOrganization) {
      return (
        <Permissions
          organization={this.state.selectedOrganization}
        />
      );
    }
  }

  private renderUsersList(): ReactNode {
    if (this.state.selectedOrganization && this.state.currentUserId) {
      return (
        <UserList
          organization={this.state.selectedOrganization}
          currentUserId={this.state.currentUserId}
        />
      );
    }
  }

  private renderDeviceList(): ReactNode {
    return (
      <DeviceManagement
        groups={this.state.rootDeviceGroups}
        onDeviceSelect={(device?: Device): void => this.setState({ selectedDevice: device })}
        onGroupsUpdate={this.handleGroupsUpdate}
      />
    );
  }

  private renderOrganizationSelector(): ReactNode {
    if (this.state.homeOrganization) {
      return (
        <OrganizationSelector
          rootOrganization={this.state.homeOrganization}
          organizationSelected={this.handleOrganizationSelected}
        />
      );
    }
  }

  private renderNotificationManager(): ReactNode {
    if (this.state.selectedOrganization && this.state.currentUserId) {
      return (
        <NotificationManagement
          organization={this.state.selectedOrganization}
          currentUserId={this.state.currentUserId}
        />
      );
    }
  }

  private renderContent(content: JSX.Element | JSX.Element[], withMap?: boolean): ReactNode {
    if (withMap) {
      return (
        <MapDrawer
          initialDrawerState={DrawerState.Closed}
          devices={this.state.selectedDevice ? [this.state.selectedDevice] : undefined}
          zoomToResource={this.state.selectedDevice}
          isSelectionEnabled={false}
        >
          {content}
        </MapDrawer>
      );
    }
    return content;
  }

  public render(): ReactNode {
    return this.renderContent(
      <Box style={{ margin: "1rem" }}>
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} md={12} lg={10} xl={8}>
            {this.renderOrganizationSelector()}
            <Tabs
              value={this.state.selectedTab}
              onChange={this.handlePageChange}
            >
              <Tab label={translations.admin.texts.users()} data-testid="users-tab"/>
              <Tab label={translations.admin.texts.devices()} data-testid="devices-tab"/>
              <Tab label={translations.admin.texts.organisation()} data-testid="organization-tab"/>
              <Tab label={translations.admin.texts.policies()} />
              <Tab label={translations.admin.texts.notifications()} />
            </Tabs>
            <TabPage currentPage={this.state.selectedTab} index={0}>
              {this.renderUsersList()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={1}>
              {this.renderDeviceList()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={2}>
              {this.renderOrganizationStructure()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={3}>
              {this.renderOrganizationPolicies()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={4}>
              {this.renderNotificationManager()}
            </TabPage>
          </Grid>
        </Grid>
      </Box>,
      this.state.selectedTab === 1);
  }
}

export default accessControlled(AdminView, ViewAccessMethods.hasAdminAccess);
