/* Copyright */
import { MainUnitHWState } from "./MainUnitHWState";
import { MainUnitHWStateProperties } from "./MainUnitHWStateProperties";
import MainUnitIconBlue from "../../../assets/MainUnit-Blue-24x24.svg";
import MainUnitIconYellow from "../../../assets/MainUnit-Yellow-24x24.svg";
import MainUnitIconRed from "../../../assets/MainUnit-Red-24x24.svg";
import MainUnitIconGray from "../../../assets/MainUnit-Gray-24x24.svg";
import { RailroadDevice } from "../RailroadDevice/RailroadDevice";
import { ResourceStatus } from "../../ResourceStatus";
import { MainUnitHWData } from "./MainUnitHWData";
import AppSyncClientFactory from "../../../data/backend/AppSyncClientFactory";
import { Service } from "../../../data/backend/AppSyncClientProvider";
import { DevicesSummaryGetDocument } from "../../../generated/gqlData";
import SummaryData, { SummaryDataFragment } from "../../data/SummaryData";
import { Maybe } from "../../../types/aliases";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";
import TimePeriod from "../../../data/utils/TimePeriod";

export class MainUnitHW extends RailroadDevice<MainUnitHWData, MainUnitHWState> {
  public static type = "MainUnit";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(MainUnitHW.type, backend, params);
  }

  public getIcon(): string {
    return MainUnitIconBlue;
  }

  public async getStatusIcon(timePeriod: TimePeriod): Promise<string> {
    const status = await this.getStatus(timePeriod);

    switch (status) {
      case ResourceStatus.WARNING:
        return MainUnitIconYellow;
      case ResourceStatus.ERROR:
        return MainUnitIconRed;
      case ResourceStatus.SERVICE:
        return MainUnitIconGray;
      case ResourceStatus.NONE:
      default:
        return this.getIcon();
    }
  }

  public async getStatus(timePeriod: TimePeriod): Promise<ResourceStatus> {
    if (this.getState()?.isServiceMode) {
      return ResourceStatus.SERVICE;
    }
    return await super.getStatus(timePeriod);
  }

  public async getSummaryData(): Promise<Maybe<SummaryData>> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
      const devicesSummaryResponse = await client.query(
        DevicesSummaryGetDocument,
        {
          deviceId: this.getId(),
        },
        {
          fetchPolicy: "network-only",
        },
      );

      if (devicesSummaryResponse.data.devicesSummaryGet) {
        const devicesSummaryFragment: SummaryDataFragment = {
          id: devicesSummaryResponse.data.devicesSummaryGet.id,
          totalMessages: devicesSummaryResponse.data.devicesSummaryGet.totalMessages ?? undefined,
          totalDuration: devicesSummaryResponse.data.devicesSummaryGet.totalDuration ?? undefined,
          timestamp: devicesSummaryResponse.data.devicesSummaryGet.timestamp ?? undefined,
        };
        return new SummaryData(devicesSummaryFragment); 
      }
    } catch (error) {
      console.error("getSummaryData", error);
    }
  }

  public createState(timestamp: number, reported?: Partial<MainUnitHWStateProperties>, desired?: Partial<MainUnitHWStateProperties>): MainUnitHWState {
    return new MainUnitHWState(this.getId(), new MainUnitHWStateProperties(reported ?? {}), new MainUnitHWStateProperties(desired ?? {}), timestamp);
  }

  public static instanceOf(value: unknown): value is MainUnitHW {
    return value instanceof MainUnitHW;
  }
}
