/* Copyright */
import { BehaviorSubject, Subject, Observable } from "rxjs";

import { CoordinateLocation } from "../client/devices/RailroadDevice/RailroadDevice";

export interface MapState {
  // Is the map in edit mode? In edit mode icons on map can be moved around.
  editMode?: boolean;
  // A callback when the location of a map item (assuming the map has only one item) is updated.
  onLocationUpdate?: (coordinates?: CoordinateLocation) => void;
}

/**
 * MapStore maintains map state and makes it available through observables.
 */
class MapStore {
  private _state: MapState = {};
  private _subject: Subject<MapState> = new BehaviorSubject(this._state);

  public state: Observable<MapState> = this._subject.asObservable();

  public setState(state: MapState): void {
    this._state = {
      ...this._state,
      ...state,
    };
    this._subject.next(this._state);
  }
}

export const mapStore = new MapStore();
