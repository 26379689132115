/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Box, Grid, IconButton, Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { Component, Fragment } from "react";
import Device from "../../../../data/device/Device";
import DeviceGroup from "../../../../data/device/DeviceGroup";
import SearchBar from "../../../ui/search-bar";
import ErrorDialog from "../../../ui/error-dialog";
import Loader from "../../../ui/loader";
import AddDevicePopup from "./add-device-popup";
import { Maybe } from "../../../../types/aliases";
import { mapStore } from "../../../../state/MapStore";
import { CoordinateLocation, RailroadDevice } from "../../../../client/devices/RailroadDevice/RailroadDevice";
import DeviceTree from "../../../device-browsing/device-tree";
import { getCaseInsensitiveSearchFilter } from "../../../device-browsing/helpers/search-filter";
import CustomButton from "../../../ui/custom-button";
import { getDisplayName } from "../../../../data/utils/Utils";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  groups: DeviceGroup[];
  onDeviceSelect?: (device?: Device) => void;
  onGroupsUpdate?: () => void;
}

interface State {
  searchFilter: string;
  tabValue: number;
  showAddDevicePopup: boolean;
  loading: boolean;
  selectedDevice?: Device;
  deviceEditMode?: boolean;
  parentGroup?: DeviceGroup;
  errorMsg?: string;
}

export default class DeviceManagement extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      searchFilter: "",
      tabValue: 0,
      showAddDevicePopup: false,
      loading: false,
    };
  }

  public componentWillUnmount(): void {
    mapStore.setState({ editMode: false });
  }

  private onSearchTriggered(searchString: string): void {
    console.log("onSearchTriggered: " + searchString);
    this.setState({ searchFilter: searchString });
  }

  private handleClosePopup = (): void => {
    // TODO: If popups needed in sequence, then split this
    this.setState({
      showAddDevicePopup: false,
      parentGroup: undefined,
      errorMsg: undefined,
    });
  };

  private handleDeviceEditModeChange = (editMode: boolean): void => {
    this.setState({ deviceEditMode: editMode });
    mapStore.setState({ editMode });
  };

  private handleDeviceSaveClicked = (): void => {
    mapStore.setState({ onLocationUpdate: this.deviceSaveReady });
  };

  private deviceSaveReady = async (coordinates?: CoordinateLocation): Promise<void> => {
    this.setState({ loading: true });

    if (RailroadDevice.instanceOf(this.state.selectedDevice)) {
      await this.state.selectedDevice?.updateLocation(coordinates);
    }

    this.setState({
      deviceEditMode: false,
      loading: false,
    });
    mapStore.setState({
      onLocationUpdate: undefined,
      editMode: false,
    });
  };

  private handleDeviceSelect = (device?: Device): void => {
    if (this.props.onDeviceSelect) {
      this.setState({ selectedDevice: device });
      this.props.onDeviceSelect(device);
    }
  };

  private renderAddDevicePopup(): Maybe<JSX.Element> {
    if (this.state.parentGroup) {
      return (
        <AddDevicePopup
          open={this.state.showAddDevicePopup}
          targetGroup={this.state.parentGroup}
          onClose={(success): void => {
            this.handleClosePopup();

            if (success) {
              this.props.onGroupsUpdate?.();
            }
          }}
        />
      );
    }
  }

  private renderPopups(): Maybe<JSX.Element> {
    if (this.state.errorMsg) {
      return (
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={this.handleClosePopup}
        />
      );
    } else {
      return this.renderAddDevicePopup();
    }
  }

  private renderTreeBrowserMode(): JSX.Element {
    return (
      <Fragment>
        <SearchBar
          searchString={this.state.searchFilter}
          onSearchTriggered={(searchString: string): void => this.onSearchTriggered(searchString)}
          className="admin-search-bar-container"
        />
        <DeviceTree
          groups={this.props.groups}
          searchFilter={getCaseInsensitiveSearchFilter(this.state.searchFilter)}
          editMode={true}
          selectedDevice={this.state.selectedDevice?.getId()}
          onDeviceSelect={!this.state.deviceEditMode ? this.handleDeviceSelect : undefined}
          onGroupSelect={undefined}
          data-testid="devices-tree"
        />
      </Fragment>
    );
  }

  private renderDeviceBrowserMode(): JSX.Element {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <Fragment>
          {this.renderTreeBrowserMode()}
          {this.renderEditDeviceButton()}
          {this.renderAddDeviceButton()}
        </Fragment>
      );
    }
  }

  private renderEditDeviceButton(): Maybe<JSX.Element> {
    if (this.state.deviceEditMode) {
      return (
        <Box m={2}>
          <Grid container spacing={3}>
            <Grid item>
              <CustomButton
                variant="contained"
                color="secondary"
                className="button"
                onClick={this.handleDeviceSaveClicked}
              >
                {translations.common.buttons.save()}
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                variant="contained"
                color="secondary"
                className="button"
                onClick={(): void => this.handleDeviceEditModeChange(false)}
              >
                {translations.common.buttons.cancel()}
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
      );
    }

    if (this.state.selectedDevice && RailroadDevice.instanceOf(this.state.selectedDevice)) {
      return (
        <Box m={2}>
          <Grid container spacing={3}>
            <Grid item>
              <CustomButton
                variant="contained"
                color="secondary"
                className="button"
                onClick={(): void => this.handleDeviceEditModeChange(true)}
              >
                {translations.admin.buttons.editDevice()}
              </CustomButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">{getDisplayName(this.state.selectedDevice)}</Typography>
            </Grid>
          </Grid>
        </Box>
      );
    }
  }

  private handleAddDeviceIconClick = (): void => {
    this.setState({
      parentGroup: this.props.groups?.[0],
      showAddDevicePopup: true,
    });
  };

  private renderAddDeviceButton(): Maybe<JSX.Element> {
    if (this.props.groups && !this.state.loading) {
      return (
        <IconButton
          title={translations.admin.buttons.addDevice()}
          onClick={this.handleAddDeviceIconClick}
          style={{ backgroundColor: "#e6e6e6", marginLeft: "3rem", marginTop: "1rem" }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <Table className="org-groups-table">
          <TableHead>
            <TableRow>
              <TableCell>{translations.admin.texts.groups()} / {translations.admin.texts.devices()}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {this.renderDeviceBrowserMode()}
        {this.renderPopups()}
      </Fragment>
    );
  }
}
