/* Copyright */
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceGroupParameters } from "../../../data/device/DeviceGroup";
import { RailroadGroup } from "../RailroadGroup";
import { Maybe } from "../../../types/aliases";
import Customer from "../Customer/Customer";
import { ResourceStatus } from "../../ResourceStatus";
import LocationIconBlue from "../../../assets/Location-Blue-24x24.svg";
import LocationIconYellow from "../../../assets/Location-Yellow-24x24.svg";
import LocationIconRed from "../../../assets/Location-Red-24x24.svg";
import AWSFactoryEventSet from "../../../data/events/AWSFactoryEventSet";
import { EventSet } from "../../../data/events/EventSet";
import Crossing from "../Crossing/Crossing";

export default class Factory extends RailroadGroup {
  public static readonly type = "Factory";
  private eventSet?: EventSet;

  public constructor(backend: AWSBackend, params: DeviceGroupParameters) {
    super(backend, params);
  }

  public getIcon(): string {
    return LocationIconBlue;
  }

  public async getStatusIcon(): Promise<string> {
    const status = await this.getResourceStatus();

    switch (status) {
      case ResourceStatus.WARNING:
        return LocationIconYellow;
      case ResourceStatus.ERROR:
        return LocationIconRed;
      case ResourceStatus.NONE:
      default:
        return this.getIcon();
    }
  }

  public getEventSet(startTimestamp?: number, endTimestamp?: number): EventSet {
    if (!this.eventSet || this.eventSet.getTimePeriod().startTimestamp !== startTimestamp || this.eventSet.getTimePeriod().endTimestamp !== endTimestamp) {
      this.eventSet = new AWSFactoryEventSet(this.getId(), startTimestamp, endTimestamp);
    }
    return this.eventSet;
  }

  public async getGroups(): Promise<Crossing[]> {
    const childGroups = await super.getGroups();
    const crossings = childGroups.filter((Crossing.instanceOf));
    return crossings;
  }

  public async getParentGroup(): Promise<Maybe<Customer>> {
    const parent = await super.getParentGroup();

    if (Customer.instanceOf(parent)) {
      return parent;
    }
  }

  public static instanceOf(value: unknown): value is Factory {
    return value instanceof Factory;
  }
}
