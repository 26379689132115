/* Copyright */
import { Dialog, Grid, Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

import { DateSchedule, SpeedLimitSignHWState, WeekdaySchedule } from "../../../client/devices/SpeedLimitSignHW/SpeedLimitSignHWState";
import { Maybe } from "../../../types/aliases";
import CustomButton from "../../ui/custom-button";
import CustomTable, { Cell, Row } from "../../ui/custom-table";
import ScheduleEditor, { SchedulingMode } from "./schedule-editor";
import { translations } from "../../../generated/translationHelper";
import { getDynamicTranslation } from "../../../locales/localizationUtils";

interface Props {
  state: SpeedLimitSignHWState;
}

interface EditMode {
  mode: SchedulingMode;
  default?: WeekdaySchedule | DateSchedule;
}

interface AlertMessage {
  type: Color;
  message: string;
}

export default function SpeedLimitScheduler(props: Props): JSX.Element {
  const [weekdaySchedules, setWeekdaySchedules] = useState<Maybe<WeekdaySchedule[]>>();
  const [editMode, setEditMode] = useState<Maybe<EditMode>>();
  const [dateSchedules, setDateSchedules] = useState<Maybe<DateSchedule[]>>();
  const [alert, setAlert] = useState<Maybe<AlertMessage>>();

  const initializeWeekdaySchedules = (weekdaySchedules?: WeekdaySchedule[]): void => {
    setWeekdaySchedules(weekdaySchedules ?? props.state.weekdaySchedules);
  };

  const initializeDateSchedules = (dateSchedules?: DateSchedule[]): void => {
    setDateSchedules(dateSchedules ?? props.state.dateSchedules);
  };

  useEffect(() => {
    initializeWeekdaySchedules();
    initializeDateSchedules();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state]);

  const getWeekdayTableHeader = (): Cell<WeekdaySchedule>[] => {
    return [
      {
        id: "side",
        value: translations.common.texts.side(),
      },
      {
        id: "weekday",
        value: translations.common.texts.weekday(),
      },
      {
        id: "time",
        value: translations.common.texts.time(),
      },
    ];
  };

  const getDateTableHeader = (): Cell<DateSchedule>[] => {
    return [
      {
        id: "side",
        value: translations.common.texts.side(),
      },
      {
        id: "date",
        value: translations.common.texts.date(),
      },
      {
        id: "time",
        value: translations.common.texts.time(),
      },
    ];
  };

  const getWeekdayTableBody = (): Row<WeekdaySchedule>[] => {
    return (weekdaySchedules ?? []).map((schedule) => ({
      id: schedule.side + schedule.weekday + schedule.time,
      cells: [
        {
          id: "side",
          value: getDynamicTranslation(translations.common.data, schedule.side),
        },
        {
          id: "weekday",
          value: getDynamicTranslation(translations.common.data, schedule.weekday),
        },
        {
          id: "time",
          value: schedule.time,
        },
      ],
      source: schedule,
    }));
  };

  const getDateTableBody = (): Row<DateSchedule>[] => {
    return (dateSchedules ?? []).map((schedule) => ({
      id: schedule.side + schedule.date + schedule.time,
      cells: [
        {
          id: "side",
          value: schedule.side,
        },
        {
          id: "date",
          value: schedule.date,
        },
        {
          id: "time",
          value: schedule.time,
        },
      ],
      source: schedule,
    }));
  };

  const handleEdit = (oldSchedule: DateSchedule | WeekdaySchedule, newSchedule: DateSchedule | WeekdaySchedule): void => {
    try {
      if (oldSchedule.kind === "Weekday" && newSchedule.kind === "Weekday") {
        props.state.removeWeekdaySchedules(oldSchedule);
        props.state.addWeekdaySchedules(newSchedule);
        initializeWeekdaySchedules();
      }

      if (oldSchedule.kind === "Date" && newSchedule.kind === "Date") {
        props.state.removeDateSchedules(oldSchedule);
        props.state.addDateSchedules(newSchedule);
        initializeDateSchedules();
      }       
    } catch (error) {
      console.error("handleEdit", error);
      setAlert({ type: "error", message: `Editing schedule failed: ${(error as Error).message}` });
    } finally {
      setEditMode(undefined);
    }
  };

  const handleAdd = (schedule: DateSchedule | WeekdaySchedule): void => {
    try {
      if (schedule.kind === "Weekday") {
        props.state.addWeekdaySchedules(schedule);
        initializeWeekdaySchedules();
      }

      if (schedule.kind === "Date") {
        props.state.addDateSchedules(schedule);
        initializeDateSchedules();
      }
    } catch (error) {
      console.error("handleAdd", error);
      setAlert({ type: "error", message: `Adding schedule failed. ${(error as Error).message}` });
    } finally {
      setEditMode(undefined);
    }
  };

  const handleDelete = (schedule: DateSchedule | WeekdaySchedule): void => {
    try {
      if (schedule.kind === "Weekday") {
        props.state.removeWeekdaySchedules(schedule);
        initializeWeekdaySchedules();
      }

      if (schedule.kind === "Date") {
        props.state.removeDateSchedules(schedule);
        initializeDateSchedules();
      }
    } catch (error) {
      console.error("handleDelete", error);
      setAlert({ type: "error", message: `Removing schedule failed. ${(error as Error).message}` });
    } finally {
      setEditMode(undefined);
    }
  };

  const handleSave = async (): Promise<void> => {
    try {
      await props.state.store();
      setAlert({ type: "success", message: "Local changes successfully saved!" });
    } catch (error) {
      console.error("handleSave", error);
      setAlert({ type: "error", message: `Saving local changes failed. ${(error as Error).message}` });
    }
  };


  const handleRevert = (): void => {
    try {
      props.state.revert();
      setAlert({ type: "success", message: "Local changes successfully reverted!" });
    } catch (error) {
      console.error("handleRevert", error);
      setAlert({ type: "error", message: `Reverting local changes failed. ${(error as Error).message}` });
    } finally {
      initializeDateSchedules();
      initializeWeekdaySchedules();
    }
  };

  const handleAlertDismiss = (): void => {
    setAlert(undefined);
  };

  return (
    <Grid container={true} direction="column" spacing={3}>
      {alert &&
        <Snackbar open={!!alert} autoHideDuration={6000} onClose={handleAlertDismiss}>
          <Alert onClose={handleAlertDismiss} severity={alert.type}>
            {alert.message}
          </Alert>
        </Snackbar>
      }
      <Grid item={true} container={true} direction="column" spacing={1}>
        <Grid item={true}>
          <CustomTable 
            title={translations.status.texts.weekdayTurnSchedules()}
            header={getWeekdayTableHeader()}
            body={getWeekdayTableBody()}
            onRowSelect={(row): void => setEditMode({ mode: SchedulingMode.Weekday, default: row.source })}
            emptyTableText={translations.common.texts.noDataAvailable()}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <CustomButton 
            variant="contained"
            color="secondary"
            onClick={(): void => setEditMode({ mode: SchedulingMode.Weekday })}
          >
            {translations.status.buttons.addWeekdaySchedule()}
          </CustomButton>
        </Grid>
      </Grid>
      <Grid item={true} container={true} direction="column" spacing={1}>
        <Grid item={true}>
          <CustomTable 
            title={translations.status.texts.dateTurnSchedules()}
            header={getDateTableHeader()}
            body={getDateTableBody()}
            onRowSelect={(row): void => setEditMode({ mode: SchedulingMode.Date, default: row.source })}
            emptyTableText={translations.common.texts.noDataAvailable()}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <CustomButton 
            variant="contained"
            color="secondary"
            onClick={(): void => setEditMode({ mode: SchedulingMode.Date })}
          >
            {translations.status.buttons.addDateSchedule()}
          </CustomButton>
        </Grid>
      </Grid>
      <Grid item={true} container={true} justifyContent="center" spacing={1}>
        <Grid item={true}>
          <CustomButton 
            variant="contained"
            color="secondary"
            onClick={handleSave}
          >
            {translations.status.buttons.saveScheduleChanges()}
          </CustomButton>
        </Grid>
        <Grid item={true}>
          <CustomButton 
            variant="contained"
            color="secondary"
            onClick={handleRevert}
          >
            {translations.status.buttons.revertScheduleChanges()}
          </CustomButton>
        </Grid>
      </Grid>
      {editMode?.mode && 
        <Dialog open={!!editMode.mode} maxWidth="sm" fullWidth={true}>
          <ScheduleEditor
            mode={editMode?.mode}
            default={editMode.default}
            onClose={(): void => setEditMode(undefined)}
            onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Dialog>
      }
    </Grid>
  );
}
