/* Copyright */
import { Maybe } from "../types/aliases";
import { CapsuleHW } from "../client/devices/CapsuleHW/CapsuleHW";
import { MainUnitHW } from "../client/devices/MainUnitHW/MainUnitHW";
import { SignHW } from "../client/devices/SignHw/SignHW";
import Crossing from "../client/groups/Crossing/Crossing";
import Factory from "../client/groups/Factory/Factory";
import LatestData from "../data/data/LatestData";
import Device from "../data/device/Device";
import { convertTimestampToString, DateTimeFormatTarget, timestampToMilliseconds } from "../data/utils/Utils";
import Battery100Icon from "../assets/battery-full24x24.svg";
import Battery25Icon from "../assets/battery-25percentage24x24.svg";
import Battery75Icon from "../assets/battery-75percentage24x24.svg";
import Battery50Icon from "../assets/battery-50percentage24x24.svg";
import Battery0Icon from "../assets/battery-empty24x24.svg";
import ACDCIcon from "../assets/AC-DC-24x24.svg";
import MainUnitIconBlue from "../assets/MainUnit-Blue-24x24.svg";
import SignIconBlue from "../assets/Sign-Blue-24x24.svg";
import CapsuleIconBlue from "../assets/Cyclope-1-Blue-24x24.svg";
import ClientProperties from "../data/clientSpecific/ClientProperties";
import { BatteryOperated, RailroadDevice } from "../client/devices/RailroadDevice/RailroadDevice";
import { ResourceStatus } from "../client/ResourceStatus";
import { Cell, Row, RowState } from "../components/ui/custom-table";
import SummaryData from "../client/data/SummaryData";
import DataSet from "../data/data/DataSet";
import { MainDeviceError, MainUnitHWData, SubDeviceError } from "../client/devices/MainUnitHW/MainUnitHWData";
import { translations } from "../generated/translationHelper";

const dayInMillis = 24 * 60 * 60 * 1000;

export interface CrossingData {
  summaryRows: Row[];
  deviceRows: Row[];
  latestData?: LatestData;
}

/**
 * StatusDataService provides data for the Status view to show in various tables.
 */
export class StatusDataService {
  /**
   * Get crossing data.
   * @param crossing Crossing resource
   * @returns crossing data
   *   - data rows
   *   - device rows
   *   - a reference to the LatestData
   */
  public static async getCrossingData(crossing: Crossing): Promise<CrossingData> {
    let dataRows: Row[] = [];
    let latestData: Maybe<LatestData<MainUnitHWData>>;
    let dataSet: Maybe<DataSet<MainUnitHWData>>;
    const deviceRows: Row[] = [];
    const devices: RailroadDevice[] = await crossing.getDevices();
    const mainUnit: Maybe<MainUnitHW> = devices.find(MainUnitHW.instanceOf);

    if (mainUnit) {
      const now = Date.now();
      latestData = mainUnit.getLatestData();
      dataSet = mainUnit.getDataSet(now - dayInMillis, now);
      const summary = await mainUnit.getSummaryData();
      if (!latestData.getData()) await latestData.fetch();
      if (!dataSet.getData()) await dataSet.fetch();

      if (latestData && summary) {
        dataRows = StatusDataService.parseDisplayedData(dataSet, latestData, summary, crossing);
      }
      const mainDeviceError = latestData.getData()?.result;

      for (const device of devices.sort(StatusDataService.sortById)) {
        const id = device.getNumericId() ?? "";
        const batt = device.getBattery();
        let icon = ACDCIcon;
  
        if (batt === BatteryOperated.True && (CapsuleHW.instanceOf(device) || SignHW.instanceOf(device))) {
          icon = StatusDataService.resolveBatteryIcon(device.getBatteryPercentage());
        }
        const state: RowState = StatusDataService.resolveRowState(await device.getStatus());
        const error = (latestData.getData()?.devices ?? []).find((item) => item.id === id)?.error;
        deviceRows.push({
          id: device.getId(),
          cells: [
            {
              id: "type",
              value: device.getType(),
            },
            {
              id: "id",
              value: !MainUnitHW.instanceOf(device) ? id : "",
            },
            {
              id: "error",
              value: !MainUnitHW.instanceOf(device) ? StatusDataService.resolveDeviceStatus(error) : StatusDataService.resolveMainDeviceStatus(mainDeviceError),
            },
            {
              id: "icon",
              value: icon,
            },
          ],
          state,
        });
      }
    }

    return {
      summaryRows: dataRows,
      deviceRows,
      latestData,
    };
  }

  /**
   * Get crossing device data header cells.
   * @returns cell array
   */
  public static getCrossingDeviceDataHeader(): Cell[] {
    return [
      {
        id: "type",
        value: translations.common.texts.type(),
      },
      {
        id: "id",
        value: translations.common.texts.id(),
      },
      {
        id: "status",
        value: translations.common.texts.status(),
      },
      {
        id: "powerStatus",
        value: "",
      },
    ];
  }

  /**
   * Get factory data. List device counts by type per crossing.
   * @param factory Factory resource
   * @returns row array
   */
  public static async getFactoryData(factory: Factory): Promise<Row[]> {
    const dataRows: Row[] = [];
    const crossings: Crossing[] = await factory.getGroups();

    for (const crossing of crossings) {
      const deviceMap: Map<string, number> = new Map();
      const devices: Device[] = await crossing.getDevices();
      const row: Row = {
        id: crossing.getId(),
        cells: [
          {
            id: "name",
            value: crossing.getName(),
          },
        ],
        state: "NONE",
      };
      devices.forEach((device: Device) => {
        const type: string = device.getType();
        const count: number = deviceMap.get(type) ?? 0;
        deviceMap.set(type, count + 1);
      });
      let total = 0;
      deviceMap.forEach((value: number) => total += value);
      row.cells.push(
        {
          id: "total",
          value: total,
        },
        {
          id: "mainUnitCount",
          value: deviceMap.get(MainUnitHW.type) ?? 0,
        },
        {
          id: "signCount",
          value: deviceMap.get(SignHW.type) ?? 0,
        },
        {
          id: "capsuleCount",
          value: deviceMap.get(CapsuleHW.type) ?? 0,
        },
      );
      row.state = StatusDataService.resolveRowState(await crossing.getResourceStatus());
      dataRows.push(row);
    }
    return dataRows;
  }

  /**
   * Get factory data header cells.
   * @returns cell array
   */
  public static getFactoryDataHeader(): Cell[] {
    return [
      {
        id: "name",
        value: translations.common.texts.name(),
      },
      {
        id: "total",
        value: translations.common.texts.total(),
      },
      {
        id: "mainUnitIcon",
        value: MainUnitIconBlue,
      },
      {
        id: "signIcon",
        value: SignIconBlue,
      },
      {
        id: "capsuleIcon",
        value: CapsuleIconBlue,
      },
    ];
  }

  public static async getDeviceData(device: RailroadDevice): Promise<Row[]> {
    const dataRows: Row[] = [
      {
        id: "id",
        cells: [
          {
            id: "idTitle",
            value: translations.common.texts.id(),
          },
          {
            id: "id",
            value: device.getNumericId() ?? "",
          },
        ],
      },
      {
        id: "type",
        cells: [
          {
            id: "typeTitle",
            value: translations.common.texts.type(),
          },
          {
            id: "type",
            value: device.getType(),
          },
        ],
      },
    ];

    if ((CapsuleHW.instanceOf(device) || SignHW.instanceOf(device)) && device.getBattery() === BatteryOperated.True) {
      dataRows.push(...await StatusDataService.getSubDeviceData(device));
    }
    return dataRows;
  }

  private static parseDisplayedData(dataSet: DataSet<MainUnitHWData>, latestData: LatestData<MainUnitHWData>, summary: SummaryData, crossing: Crossing): Row[] {
    const dataRows: Row[] = [];
    const lastDayMessageDuration = dataSet.getData()?.map(item => item.duration).reduce((previousDuration, nextDuration) => previousDuration + nextDuration, 0) ?? 0;
    const latest = latestData.getData();
    const lastActivityInMs = latest?.timestamp ? timestampToMilliseconds(latest.timestamp) : undefined;
    const lastActivity = lastActivityInMs ? convertTimestampToString(lastActivityInMs, DateTimeFormatTarget.StatusTable) : "N/A";
    const firstActivityInMillis = Number(crossing.getFirstActivity()) * 1000;
    const firstActivity = firstActivityInMillis ? convertTimestampToString(firstActivityInMillis, DateTimeFormatTarget.StatusTable) : "N/A";
    const temperature = ClientProperties.formatValue(latest?.temp, "", 2) ?? "N/A";
    const humidity = ClientProperties.formatValue(latest?.humidity, "", 2) ?? "N/A";
    const dayInMillis = 24 * 60 * 60 * 1000;
    const age = (Date.now() - firstActivityInMillis) / dayInMillis;
    const totalDurationString = StatusDataService.getMillisInHoursMinutesSeconds(summary.totalDuration * 1000);
    const totalLastDayDuration = StatusDataService.getMillisInHoursMinutesSeconds(lastDayMessageDuration * 1000);

    dataRows.push(
      {
        id: "firstActivity",
        cells: [
          {
            id: "firstActivityTitle",
            value: translations.status.texts.firstActivity(),
          },
          {
            id: "firstActivity",
            value: firstActivity,
          },
        ], 
      },
      {
        id: "lastActivity",
        cells: [
          {
            id: "lastActivityTitle",
            value: translations.status.texts.lastActivity(),
          },
          {
            id: "lastActivity",
            value: lastActivity,
          },
        ],
      },
      {
        id: "totalMessages",
        cells: [
          {
            id: "totalMessagesTitle",
            value: translations.status.texts.totalMessages(),
          },
          {
            id: "totalMessages",
            value: summary.totalMessages,
          },
        ],
      },
      {
        id: "totalDuration",
        cells: [
          {
            id: "totalDurationTitle",
            value: translations.status.texts.totalDuration(),
          },
          {
            id: "totalDuration",
            value: totalDurationString,
          },
        ],
      },
      {
        id: "dailyMessageCountOnAverage",
        cells: [
          {
            id: "dailyMessageCountOnAverageTitle",
            value: translations.status.texts.dailyMessageCountAverage(),
          },
          {
            id: "dailyMessageCountOnAverage",
            value: (summary.totalMessages / age).toFixed(2),
          },
        ],
      },
      {
        id: "lastDayDurationCount",
        cells: [
          {
            id: "lastDayDurationCountTitle",
            value: translations.status.texts.lastXhoursTotalDuration({ hours: 24 }),
          },
          {
            id: "lastDayDurationCount",
            value: totalLastDayDuration,
          },
        ],
      },
      {
        id: "dailyUsageOnAverage",
        cells: [
          {
            id: "dailyUsageOnAverageTitle",
            value: translations.status.texts.dailyUsage(),
          },
          {
            id: "dailyUsageOnAverage",
            value: `${(summary.totalDuration / age).toFixed(2)} s`,
          },
        ],
      },
      {
        id: "measurements",
        cells: [
          {
            id: "temperature",
            value: translations.status.texts.temperature({ temperature }),
          },
          {
            id: "humidity",
            value: translations.status.texts.humidity({ humidity }),
          },
        ],
      },
    );

    return dataRows;
  }

  private static sortById(a: Device, b: Device): number {
    if (a.getId() < b.getId()) {
      return -1;
    }

    if (a.getId() > b.getId()) {
      return 1;
    }

    return 0;
  }

  private static async getSubDeviceData(device: CapsuleHW | SignHW): Promise<Row[]> {
    const crossing = await device.getParentGroup();
    const mainUnit = await crossing?.getMainUnit();
    const summary = await mainUnit?.getSummaryData();
    const remainingBattery = device.getState()?.batteryCapacity;
    const batteryMaxCapacity = device.getState()?.batteryMaxCapacity;
    const batteryAverageCurrent = device.getState()?.batteryAverageCurrent;
    const batteryCurrentPerSec = device.getState()?.batteryCurrentPerSec;
    const batteryStatusRows: Row[] = [
      {
        id: "remainingBatteryPercentage",
        cells: [
          {
            id: "remainingBatteryPercentageTitle",
            value: translations.status.texts.remainingBattery(),
          },
          {
            id: "remainingBatteryPercentage",
            value: `${remainingBattery != null && batteryMaxCapacity != null ? (remainingBattery / batteryMaxCapacity * 100).toFixed(0) : translations.common.texts.notAvailable()} %`,
          },
        ],
      },
      {
        id: "batteryUsed",
        cells: [
          {
            id: "batteryUsedTitle",
            value: translations.status.texts.usedBatteryCapacity(),
          },
          {
            id: "batteryUsed",
            value: `${remainingBattery != null && batteryMaxCapacity != null ? (batteryMaxCapacity - remainingBattery).toFixed(0) : translations.common.texts.notAvailable()} mAh`,
          },
        ],
      },
      {
        id: "remainingBattery",
        cells: [
          {
            id: "remainingBatteryTitle",
            value: translations.status.texts.remainingBatteryCapacity(),
          },
          {
            id: "remainingBattery",
            value: `${remainingBattery?.toFixed(0) ?? translations.common.texts.notAvailable()} mAh`,
          },
        ],
      },
      {
        id: "batteryMaxCapacity",
        cells: [
          {
            id: "batteryMaxCapacityTitle",
            value: translations.status.texts.batteryMaxCapacity(),
          },
          {
            id: "batteryMaxCapacity",
            value: `${batteryMaxCapacity?.toFixed(0) ?? translations.common.texts.notAvailable()} mAh`,
          },
        ],
      },
      {
        id: "batteryAverageCurrent",
        cells: [
          {
            id: "batteryAverageCurrentTitle",
            value: translations.status.texts.batteryCurrentAverage(),
          },
          {
            id: "batteryAverageCurrent",
            value: `${batteryAverageCurrent?.toFixed(1) ?? translations.common.texts.notAvailable()} mA`,
          },
        ],
      },
      {
        id: "batteryCurrentPerSec",
        cells: [
          {
            id: "batteryCurrentPerSecTitle",
            value: translations.status.texts.batteryCurrentDrawPerSecond(),
          },
          {
            id: "batteryCurrentPerSec",
            value: `${batteryCurrentPerSec?.toFixed(3) ?? translations.common.texts.notAvailable()} mAh/s`,
          },
        ],
      },
    ];

    if (summary) {
      const firstActivityInMillis = Number(crossing?.getFirstActivity()) * 1000;
      const ageInDays = (Date.now() - firstActivityInMillis) / dayInMillis;
      const dailyUsage = summary.totalDuration / ageInDays;
      const batteryDailyUsage = batteryCurrentPerSec ? batteryCurrentPerSec * dailyUsage : undefined;
      const batteryLifetime = batteryMaxCapacity && batteryDailyUsage ? batteryMaxCapacity / batteryDailyUsage : undefined;
      const batteryExpiration = remainingBattery && batteryDailyUsage ? convertTimestampToString(Date.now() + (remainingBattery / batteryDailyUsage * dayInMillis), DateTimeFormatTarget.StatusTable) : undefined;
      const stats: Row[] = [{
        id: "batteryDailyUsage",
        cells: [
          {
            id: "batteryDailyUsageTitle",
            value: translations.status.texts.batteryDailyUsageOnAverage(),
          },
          {
            id: "batteryDailyUsage",
            value: `${batteryDailyUsage?.toFixed(3) ?? translations.common.texts.notAvailable()} mAh/day`,
          },
        ],
      },
      {
        id: "batteryLifetime",
        cells: [
          {
            id: "batteryLifetimeTitle",
            value: translations.status.texts.batteryLifetimeEstimate(),
          },
          {
            id: "batteryLifetime",
            value: `${batteryLifetime?.toFixed(0) ?? translations.common.texts.notAvailable()} days`,
          },
        ],
      },
      {
        id: "batteryExpiration",
        cells: [
          {
            id: "batteryExpirationTitle",
            value: translations.status.texts.batteryExpirationEstimate(),
          },
          {
            id: "batteryExpiration",
            value: `${batteryExpiration ?? translations.common.texts.notAvailable()}`,
          },
        ],
      }];
      batteryStatusRows.push(...stats);
    }
    return batteryStatusRows;
  }

  private static resolveBatteryIcon(percentage?: number): string {
    if (percentage !== undefined) {
      if (percentage > 75) return Battery100Icon;
      else if (percentage > 50) return Battery75Icon;
      else if (percentage > 25) return Battery50Icon;
      else if (percentage > 0) return Battery25Icon;
      return Battery0Icon;
    }
    return "N/A";
  }

  private static resolveRowState(status?: ResourceStatus): RowState {
    if (status === ResourceStatus.SERVICE) return "SERVICE";
    else if (status === ResourceStatus.ERROR) return "ERROR";
    else if (status === ResourceStatus.WARNING) return "WARNING";
    else return "NONE";
  }

  private static resolveDeviceStatus(status?: `${SubDeviceError}`): string {
    switch (status) {
      case SubDeviceError.OK:
        return translations.common.data.ok();
      case SubDeviceError.ERROR:
        return translations.common.data.error();
      case SubDeviceError.LEAN:
        return translations.common.data.deviceLeaning();
      case SubDeviceError.LOW_BATTERY:
        return translations.common.data.lowBattery();
      default:
        return translations.common.texts.notAvailable();
    }
  }

  private static resolveMainDeviceStatus(status?: `${MainDeviceError}`): string {
    switch (status) {
      case MainDeviceError.OK:
        return translations.common.data.ok();
      case MainDeviceError.ERROR:
        return translations.common.data.error();
      case MainDeviceError.DEVICE_ERROR:
        return translations.common.data.subDeviceError();
      case MainDeviceError.DEVICE_NOT_RESPONDING:
        return translations.common.data.subDeviceNotResponding();
      default:
        return translations.common.texts.notAvailable();
    }
  }

  private static getMillisInHoursMinutesSeconds(millis: number): string {
    const hourInMillis = 60 * 60 * 1000;
    const hours = millis / hourInMillis;
    const minutes = (hours % 1) * 60;
    const seconds = (minutes % 1) * 60;
    return `${parseInt(hours.toString())}h ${parseInt(minutes.toString())}m ${parseInt(seconds.toString())}s`;
  }
}
