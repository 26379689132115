/* Copyright */
import "date-fns";
import React, { ChangeEvent, Component, Fragment, ReactNode } from "react";
import { Box, Checkbox, createStyles, FormControlLabel, Grid, StyleRules, TextField, WithStyles, withStyles } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";
import { Theme } from "@material-ui/core/styles";
import { green, yellow } from "@material-ui/core/colors";

import { Event } from "../../../generated/gqlEvents";
import AuthWrapper from "../../../data/auth/AuthWrapper";
import CustomButton from "../../ui/custom-button";
import CustomTable from "../../ui/custom-table";
import ServiceDataService from "../../../services/ServiceDataService";
import { translations } from "../../../generated/translationHelper";

export enum Mode {
  VIEW,
  REGISTER,
}

export interface ServiceDetails {
  unitLabel: string;
  servicedBy: string;
  description: string;
  isReady: boolean;
  serviceDate: Date;
  nextServiceDate?: Date;
  link?: Event[];
}

export interface InputServiceDetails {
  unitLabel: string;
  servicedBy?: string;
  description?: string;
  isReady?: boolean;
  serviceDate?: Date;
  nextServiceDate?: Date;
  link?: Event[];
}

interface Props extends WithStyles<typeof styles> {
  onClose?: () => void;
  onSubmit?: (service: ServiceDetails) => void;
  service: InputServiceDetails;
  mode: Mode;
}

interface State extends Omit<ServiceDetails, "unitLabel"> {
  isNextServiceDateValid: boolean;
  isServiceDateValid: boolean;
  hasDescriptionBeenEdited: boolean;
  hasServiceDateBeenEdited: boolean;
  hasNextServiceDateBeenEdited: boolean;
}

const styles = (theme: Theme): StyleRules => createStyles({
  container: {
    border: "2px solid gray",
    borderRadius: 20,
    height: "100%",
    minWidth: "400px",
  },
  title: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    height: 40,
    borderRadius: "18px 18px 0 0",
  },
  centerTitle: {},
  header: {},
  content: {
    padding: "32px 16px",
  },
  buttons: {
    height: 50,
  },
});

const SubmitButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(CustomButton);

const CancelButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: yellow[700],
    "&:hover": {
      backgroundColor: yellow[800],
    },
  },
}))(CustomButton);

class ServiceEditor extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      servicedBy: props.service?.servicedBy ?? "",
      description: props.service?.description ?? "",
      serviceDate: props.service?.serviceDate ?? new Date(Date.now()),
      isReady: props.service?.isReady ?? false,
      nextServiceDate: props.service?.nextServiceDate,
      isNextServiceDateValid: true,
      isServiceDateValid: true,
      hasDescriptionBeenEdited: false,
      hasNextServiceDateBeenEdited: false,
      hasServiceDateBeenEdited: false,
    };
  }

  public componentDidMount(): void {
    this.setUserFullName();
  }

  private async setUserFullName(): Promise<void> {
    if (!this.state.servicedBy && this.props.mode === Mode.REGISTER) {
      const firstName = await AuthWrapper.getGivenName();
      const lastName = await AuthWrapper.getFamilyName();

      if (firstName || lastName) {
        const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
        this.setState({ servicedBy: fullName });
      }
    }
  }

  private handleClose = (): void => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private handleSubmit = (): void => {
    if (this.props.onSubmit) {
      const { servicedBy, description, serviceDate, isReady, nextServiceDate } = this.state;
      this.props.onSubmit({
        unitLabel: this.props.service.unitLabel,
        serviceDate,
        servicedBy,
        description,
        isReady,
        nextServiceDate,
        link: this.props.service.link,
      });
    }
  };

  private getTitle = (): string => {
    switch (this.props.mode) {
      case Mode.REGISTER:
        return "Register a service";
      case Mode.VIEW:
        return "View a service";
    }
  };

  private handleTextChange = (newText: string, key: keyof Pick<State, "servicedBy" | "description">): void => {
    this.setState({ [key]: newText } as Pick<State, typeof key>);
  };

  private handleDateChange = (key: keyof Pick<State, "nextServiceDate" | "serviceDate">, newDate?: string): void => {
    if (newDate) {
      const date = new Date(newDate);
      this.setState({ [key]: date } as Pick<State, typeof key>);
    }
  };

  private handleDateChangeErrorState = (key: keyof Pick<State, "isNextServiceDateValid" | "isServiceDateValid">, isValid: boolean): void => {
    this.setState({ [key]: isValid } as Pick<State, typeof key>);
  };

  private isDateValid = (date: MaterialUiPickersDate): boolean => {
    return date !== null && date.toString() !== "Invalid Date";
  };

  private isSubmitDisabled = (): boolean => {
    return !this.state.isServiceDateValid || this.state.servicedBy.length === 0 || (this.state.hasNextServiceDateBeenEdited && !this.state.isNextServiceDateValid) || this.state.description.length === 0;
  };

  private isReadOnly(): boolean {
    return this.props.mode === Mode.VIEW;
  }

  public render(): ReactNode {
    const { classes } = this.props;
    return (
      <Box m={3} className="box-root">
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.container}>
          <Grid item container className={classes.header}>
            <Grid item xs>
              <Grid container justifyContent="space-around" alignItems="center" className={classes.title}>
                <Grid item className={classes.centerTitle}>
                  {this.getTitle()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container className={classes.content} spacing={3}>
            <Grid item container direction="column" spacing={3}>
              <Grid item container justifyContent="space-between" alignItems="center" spacing={3}>
                <Grid item>
                  <TextField 
                    label={translations.common.texts.unit()}
                    variant="outlined"
                    value={this.props.service?.unitLabel ?? "N/A"}
                    disabled={true}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label={translations.service.texts.servicedBy()}
                    variant="outlined"
                    value={this.state.servicedBy}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => this.handleTextChange(event.target.value, "servicedBy")}
                    disabled={this.isReadOnly()}
                    error={this.props.mode === Mode.REGISTER && this.state.servicedBy.length === 0}
                  />
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      error={!this.state.isServiceDateValid}
                      variant="inline"
                      inputVariant="outlined"
                      format="dd.MM.yyyy"
                      label={translations.service.texts.dateOfService()}
                      value={this.state.serviceDate ?? null}
                      onFocus={(): void => this.setState({ hasServiceDateBeenEdited: true })}
                      onChange={(date: MaterialUiPickersDate): void => {
                        const isValid = this.isDateValid(date);
                        this.handleDateChangeErrorState("isServiceDateValid", isValid);
                        this.handleDateChange("serviceDate", isValid ? date!.toISOString() : undefined);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disabled={this.isReadOnly()}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label={translations.service.texts.description()}
                  variant="outlined"
                  multiline
                  fullWidth
                  error={this.state.hasDescriptionBeenEdited && this.state.description.length === 0}
                  value={this.state.description}
                  rows={4}
                  disabled={this.isReadOnly()}
                  onFocus={(): void => this.setState({ hasDescriptionBeenEdited: true })}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void => this.handleTextChange(event.target.value, "description")}
                />
              </Grid>
              {this.props.service.link && this.props.service.link?.length > 0 &&
              <Grid item>
                <CustomTable
                  header={ServiceDataService.getLinkedDiagnosticIssuesHeader()}
                  body={ServiceDataService.getLinkedDiagnosticIssues(this.props.service.link)}
                  title={translations.service.texts.diagnosticIssuesBoundToService()}
                />
              </Grid>
              }
              <Grid item container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox 
                      checked={this.state.isReady}
                      onChange={(): void => this.setState((prevState: State): Pick<State, "nextServiceDate" | "isReady"> => {
                        if (prevState.isReady) {
                          return { nextServiceDate: undefined, isReady: !prevState.isReady };
                        }
                        return { isReady: !prevState.isReady };
                      })}
                    />}
                    label={translations.service.texts.serviceCompleted()}
                    disabled={this.isReadOnly()}
                  />
                </Grid>
                {(!this.props.service.link || this.props.service.link.length === 0) && 
                  <Fragment>
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox 
                          checked={!!this.state.nextServiceDate} 
                          onChange={(): void => this.setState((prevState: State): Pick<State, "nextServiceDate" | "hasNextServiceDateBeenEdited" | "isNextServiceDateValid"> => {
                            if (prevState.nextServiceDate) {
                              return { nextServiceDate: undefined, hasNextServiceDateBeenEdited: false, isNextServiceDateValid: true };
                            }
                            return { nextServiceDate: new Date(Date.now()), hasNextServiceDateBeenEdited: false, isNextServiceDateValid: true };
                          })}
                        />}
                        label={translations.service.texts.nextService()}
                        disabled={!this.state.isReady || this.isReadOnly()}
                      />
                    </Grid>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          error={this.state.nextServiceDate && this.state.hasNextServiceDateBeenEdited && !this.state.isNextServiceDateValid}
                          variant="inline"
                          inputVariant="outlined"
                          format="dd.MM.yyyy"
                          label={translations.service.texts.dateOfNextService()}
                          value={this.state.nextServiceDate ?? null}
                          onFocus={(): void => this.setState({ hasNextServiceDateBeenEdited: true })}
                          onChange={(date: MaterialUiPickersDate): void => {
                            const isValid = this.isDateValid(date);
                            this.handleDateChangeErrorState("isNextServiceDateValid", isValid);
                            this.handleDateChange("nextServiceDate", isValid ? date!.toISOString() : undefined);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disabled={!this.state.isReady || !this.state.nextServiceDate || this.isReadOnly()}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Fragment>
                }
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justifyContent="space-around" className={classes.buttons}>
              {this.props.mode === Mode.REGISTER &&
                <Fragment>
                  <Grid item>
                    <CancelButton 
                      variant="contained"
                      onClick={this.handleClose}
                    >
                      {translations.common.buttons.cancel()}
                    </CancelButton>
                  </Grid>
                  <Grid item>
                    <SubmitButton 
                      variant="contained"
                      disabled={this.isSubmitDisabled()}
                      onClick={this.handleSubmit}
                    >
                      {translations.common.buttons.submit()}
                    </SubmitButton>
                  </Grid>
                </Fragment>
              }
              {this.props.mode === Mode.VIEW &&
                <Grid item>
                  <CustomButton 
                    variant="contained"
                    color="secondary"
                    disabled={false}
                    onClick={this.handleClose}
                  >
                    {translations.common.buttons.close()}
                  </CustomButton>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Box>
    ); 
  }
}

export default withStyles(styles, { withTheme: true })(ServiceEditor);
