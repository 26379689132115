/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import Data, { DataUtil } from "./Data";
import DataSet, { DataObserver } from "./DataSet";
import DataSubscriptionManager from "./DataSubscriptionManager";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe, Nullable } from "../../types/aliases";
import { isDefined } from "../../utils/types";
import { DevicesMeasurementsListDocument } from "../../generated/gqlData";
import TimePeriod from "../utils/TimePeriod";

export default class AWSDataSet<TData extends Data = Data> extends DataSet<TData> {
  private data?: TData[];

  public constructor(
      private readonly deviceId: string,
      protected type: string,
      private startTimestamp: number,
      private endTimestamp: number,
  ) {
    super();
    this.deviceId = deviceId;
    this.type = type;
    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
  }

  public async fetch(): Promise<void> {
    let nextToken: Nullable<string> = null;
    let data: TData[] = [];

    try {
      do {
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
        const dataResponse = await appSyncClient.query(
          DevicesMeasurementsListDocument,
          {
            deviceId: this.deviceId,
            startTimestamp: this.startTimestamp.toString(),
            endTimestamp: this.endTimestamp.toString(),
            nextToken,
          },
        );
        // cast is required or response encounters a cyclic type inference
        nextToken = (dataResponse.data.devicesMeasurementsList?.nextToken ?? null) as Nullable<string>;
        const dataItems: TData[] = (dataResponse.data.devicesMeasurementsList?.measurementItems ?? [])
          .map((item) => DataUtil.parseDataFragment<TData>(item, this.type))
          .filter(isDefined);
        data = data.concat(dataItems);
      } while (nextToken);
      this.setData(data);
    } catch (error) {
      console.error("Error", error);
    }
  }

  public getId(): string {
    return this.deviceId;
  }

  public getData(): Maybe<TData[]> {
    return this.data ? [...this.data] : undefined;
  }

  public getType(): string {
    return this.type;
  }

  public getTimePeriod(): TimePeriod {
    return { startTimestamp: this.startTimestamp, endTimestamp: this.endTimestamp };
  }

  public setData(data: TData[]): void {
    const newData = [...data];
    newData.sort(this.timestampOrder);
    this.data = newData;
  }

  public appendData(data: TData): void {
    // if data is not yet initialized
    if (!this.data) this.data = [];
    this.data.push(data);
    this.data.sort(this.timestampOrder);

    if (data.timestamp > this.endTimestamp) {
      this.endTimestamp = data.timestamp;
    }
    this.notifyAction(observer => observer.onDataUpdate(this));
  }

  public async addObserver(observer: DataObserver): Promise<void> {
    if (!this.data) {
      await this.fetch();
    }
    super.addObserver(observer);

    if (this.observerCount === 1) {
      DataSubscriptionManager.instance.addListener(this);
    }
  }

  public removeObserver(observer: DataObserver): void {
    super.removeObserver(observer);

    if (this.observerCount === 0) {
      DataSubscriptionManager.instance.removeListener(this);
    }
  }
  
  private readonly timestampOrder = (a: TData, b: TData): number => a.timestamp - b.timestamp;
}
