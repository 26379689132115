/* Copyright */
import React, { useState } from "react";
import { withStyles, Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";

import { Nullable } from "../../types/aliases";

type ValueType = unknown;
type UnknownRecord = Record<string, unknown>;

export type RowState = keyof typeof RowStateEnum;
enum RowStateEnum {
  WARNING = "WARNING",
  ERROR = "ERROR",
  SERVICE = "SERVICE",
  NONE = "NONE",
}

export interface Cell<TData = UnknownRecord> {
  id: Extract<keyof TData, string | number>;
  value: ValueType;
  align?: "left" | "center" | "right" | "inherit" | "justify";
  hidden?: boolean;
  source?: TData;
}

export interface Row<TData = UnknownRecord> {
  id: string;
  cells: Cell<TData>[];
  hidden?: boolean;
  state?: RowState;
  source?: TData;
}

interface Props<TData> {
  title: string;
  header: Cell<TData>[];
  body: Row<TData>[];
  onRowSelect?: (row: Row<TData>) => void;
  onCellSelect?: (cell: Cell<TData>) => void;
  emptyTableText?: string;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.black,
      borderRight: "1px solid gray",
      "&:last-child": {
        borderRight: "0px",
      },
    },
    body: {
      fontSize: 14,
      borderRight: "1px solid gray",
      "&:last-child": {
        borderRight: "0px",
      },
    },
  }),
)(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: "2px solid gray",
    borderRadius: 20,
    "& .MuiTableHead-root": {
      opacity: 1.0,
    },
  },
  table: {
    tableLayout: "auto",
  },
  tableTitle: {
    fontSize: 14,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    height: 40,
  },
  tableBody: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  service: {
    backgroundColor: "#bfbfbf",
  },
}));

export default function CustomTable<TData>(props: Props<TData>): Nullable<JSX.Element> {
  const { title, header, body, emptyTableText, onRowSelect, onCellSelect } = props;
  const [selectedRowId, setSelectedRowId] = useState<string>();
  const classes = useStyles();

  const renderCell = (input: ValueType): JSX.Element => {
    if (typeof(input) === "string" && input.endsWith(".svg")) {
      return <img src={input} alt="table cell icon"/>;
    }

    return (<>{input}</>);
  };

  const handleRowClick = (row: Row<TData>): void => {
    if (onRowSelect) {
      setSelectedRowId(row.id);
      onRowSelect(row);
    }
  };

  const handleCellClick = (cell: Cell<TData>): void => {
    if (onCellSelect) {
      onCellSelect(cell);
    }
  };

  return (
    <TableContainer className={classes.container}>
      <Table aria-label="customized table" size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={5} className={classes.tableTitle}>{title}</TableCell>
          </TableRow>
          <TableRow>
            {header.filter((cell) => !cell.hidden).map((cell) => (
              <StyledTableCell key={cell.id} align={cell.align}>{renderCell(cell.value)}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          className={clsx(
            { [classes.tableBody]: !!onRowSelect || !!onCellSelect },
          )}
        >
          {body && body.length > 0 && body.filter((row) => !row.hidden).map((row) => (
            <TableRow
              key={row.id} 
              className={clsx([
                { [classes.error]: row.state === RowStateEnum.ERROR },
                { [classes.warning]: row.state === RowStateEnum.WARNING },
                { [classes.service]: row.state === RowStateEnum.SERVICE },
              ])}
              hover={!!onRowSelect}
              selected={!!onRowSelect && selectedRowId === row.id}
              onClick={(): void => handleRowClick(row)}
            >
              {row.cells.map((cell) => (
                <StyledTableCell
                  key={cell.id}
                  align={cell.align}
                  onClick={(): void => handleCellClick(cell)}
                >
                  {renderCell(cell.value)}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
          {(!body || body.length === 0) && emptyTableText &&
            <TableRow key={"emptyRow"}>
              <StyledTableCell
                colSpan={header?.length}
                component="th"
                scope="row"
                align="center"
              >
                {renderCell(emptyTableText)}
              </StyledTableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
