/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/
import { Service } from "../backend/AppSyncClientProvider";
import AWSDataSet from "../data/AWSDataSet";
import AWSLatestData from "../data/AWSLatestData";
import AWSSessionSet from "../data/AWSSessionSet";
import DataSet from "../data/DataSet";
import LatestData from "../data/LatestData";
import SessionSet from "../data/SessionSet";
import AWSDeviceEventSet from "../events/AWSDeviceEventSet";
import EventSet from "../events/EventSet";
import Device, { DeviceObserver, DeviceParameters, StatePropertiesOf } from "./Device";
import DeviceState from "./DeviceState";
import ShadowSubscriptionManager from "./ShadowSubscriptionManager";
import Data from "../data/Data";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe } from "../../types/aliases";
import AWSBackend, { narrowDownAttributeTypes } from "../backend/AWSBackend";
import { DevicesStatesGetDocument, DevicesUpdateDocument } from "../../generated/gqlDevice";
import { Attribute } from "./Attribute";
import DeviceGroup from "./DeviceGroup";
import { HasEntityRelations, RelationChange } from "../utils/EntityRelationCache";
import AWSThingGroup from "./AWSThingGroup";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";

/**
 * Base-class for typed AWS Thing implementations. Do not create this directly.
 *
 * This class is no longer abstract since TypeScript does not allow for run-time type comparison against an
 * abstract class (since abstract things do not exist in TypeScript at run-time).
 */
export default class AWSThing<TData extends Data, TState extends DeviceState> extends Device<TState> implements HasEntityRelations {
  public readonly entityType = AWSThing;
  protected state?: TState;
  private readonly groupSemaphore = new PromiseSemaphore((): Promise<void> => this.backend.linkDeviceGroupsForDevice(this));
  private readonly deviceId: string; 
  private attributes?: Attribute[];
  private parentGroupId?: string;
  private dataSet?: DataSet<TData>;
  private latestData?: LatestData<TData>;
  private sessionSet?: SessionSet;
  private eventSet?: EventSet;
  
  /*
   * DO NOT CALL DIRECTLY
   *
   * This constructor needs to be public so {@link EntityRelationCache} can use it for type checks.
   */
  public constructor(
      private readonly type: string,
      protected readonly backend: AWSBackend,
      params: DeviceParameters,
  ) {
    super();
    this.deviceId = params.deviceId;
    this.parentGroupId = params.parentGroupId;
    this.attributes = params.attributes;
  }

  public async getGroups(): Promise<DeviceGroup[]> {
    await this.groupSemaphore.guard();
    return this.backend.entityRelationCache.listFor(this, AWSThingGroup);
  }

  public getAttribute(key: string): Maybe<string> {
    return this.attributes?.find(attribute => attribute.key === key)?.value;
  }

  public getAttributes(): Attribute[] {
    return this.attributes ?? [];
  }

  public getId(): string {
    return this.deviceId;
  }

  public async getParentGroup(): Promise<Maybe<DeviceGroup>> {
    console.log("ParentGroupId", this.parentGroupId);
    return this.parentGroupId ? this.backend.getDeviceGroup(this.parentGroupId) : undefined;
  }

  public getType(): string {
    return this.type;
  }

  public getState(): Maybe<TState> {
    return this.state;
  }

  // TODO: Consider moving this functionality inside DeviceState
  public addObserver(observer: DeviceObserver): void {
    super.addObserver(observer);
    ShadowSubscriptionManager.instance.addListener(this);
  }

  public removeObserver(observer: DeviceObserver): void {
    super.removeObserver(observer);
    ShadowSubscriptionManager.instance.removeListener(this);
  }

  public onRelationChange(change: RelationChange): void {
    if (change.ofType(AWSThingGroup)) {
      this.notifyAction(observer => observer.onDeviceGroupsChanged?.(this));
    }
  }

  public async init(): Promise<void> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      const deviceStateResponse = await client.query(
        DevicesStatesGetDocument,
        {
          deviceId: this.deviceId,
        },
      );
      const { desired, reported, timestamp } = deviceStateResponse.data.devicesStatesGet ?? {};

      this.state = this.createState(timestamp ?? undefined, reported ? JSON.parse(reported) : undefined, desired ? JSON.parse(desired) : undefined);
    } catch (error) {
      console.error("init", error);
    }
  }

  public setState(timestamp?: number, current?: Partial<StatePropertiesOf<TState>>, next?: Partial<StatePropertiesOf<TState>>): void {
    // TODO: Consider implementing a method that just updates the existing state
    this.state = this.createState(timestamp, current, next);
    this.notifyAction(observer => observer.onDeviceStateUpdated?.(this));
  }

  public getSessionSet(startTimestamp: number, endTimestamp: number): SessionSet {
    if (startTimestamp !== this.sessionSet?.getTimePeriod()?.startTimestamp
    || endTimestamp !== this.sessionSet?.getTimePeriod()?.endTimestamp) {
      this.sessionSet = new AWSSessionSet(this.deviceId, startTimestamp, endTimestamp);
    }
    return this.sessionSet;
  }

  public getDataSet(startTimestamp: number, endTimestamp: number): DataSet<TData> {
    if (startTimestamp !== this.dataSet?.getTimePeriod()?.startTimestamp
        || endTimestamp !== this.dataSet?.getTimePeriod()?.endTimestamp) {
      this.dataSet = new AWSDataSet<TData>(this.deviceId, this.type, startTimestamp, endTimestamp);
    }
    return this.dataSet;
  }

  public getLatestData(): LatestData<TData> {
    if (!this.latestData) {
      this.latestData = new AWSLatestData<TData>(this.deviceId, this.type);
    }
    return this.latestData;
  }

  public getEventSet(startTimestamp?: number, endTimestamp?: number): EventSet {
    if (!this.eventSet || startTimestamp !== this.eventSet?.getTimePeriod().startTimestamp
        || endTimestamp !== this.eventSet?.getTimePeriod().endTimestamp) {
      this.eventSet = new AWSDeviceEventSet(this.deviceId, startTimestamp, endTimestamp);
    }
    return this.eventSet;
  }

  // TODO: can user set null values for attr's value?
  public async updateAttributes(attributes: Required<Attribute>[]): Promise<void> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      const response = await client.mutate(
        DevicesUpdateDocument,
        {
          deviceId: this.deviceId,
          attributes,
        },
      );
      this.attributes = narrowDownAttributeTypes(response.data?.devicesUpdate?.attr ?? []);
    } catch (error) {
      console.error("updateAttributes", error);
    }
  }

  // OVERRIDE THIS
  public createState(
      _timestamp?: number,
      _reported?: Partial<StatePropertiesOf<TState>>,
      _desired?: Partial<StatePropertiesOf<TState>>): TState {
    throw new Error("AWSThing MUST NOT be created directly");
  }

  // OVERRIDE THIS
  public getIcon(): string {
    throw new Error("AWSThing MUST NOT be created directly");
  }

  public static instanceOf(value: unknown): value is AWSThing<Data, DeviceState> {
    return value instanceof AWSThing;
  }
}
