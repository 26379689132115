/* Copyright */
import { Maybe } from "../../types/aliases";
import { HasEntityRelations } from "../utils/EntityRelationCache";
import AWSBackend from "../backend/AWSBackend";
import Crossing from "../../client/groups/Crossing/Crossing";
import Factory from "../../client/groups/Factory/Factory";
import Customer from "../../client/groups/Customer/Customer";
import DeviceGroupFactory from "./DeviceGroupFactory";
import DeviceGroup, { DeviceGroupParameters } from "./DeviceGroup";
import AWSThingGroup from "./AWSThingGroup";

const GROUP_CONSTRUCTORS = {
  [Crossing.type]: Crossing,
  [Factory.type]: Factory,
  [Customer.type]: Customer,
  Regular: AWSThingGroup,
};

type GroupType = keyof typeof GROUP_CONSTRUCTORS;

export default class AWSDeviceGroupFactory implements DeviceGroupFactory<AWSBackend>{
  public createGroup(backend: AWSBackend, type: GroupType, params: DeviceGroupParameters): Maybe<DeviceGroup & HasEntityRelations> {
    const ctor = GROUP_CONSTRUCTORS[type];

    if (ctor) {
      return new ctor(backend, params);
    } else {
      console.error(`Could not locate device implementation for type ${type}`);
    }
  }
}
