/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeView from "@material-ui/lab/TreeView";
import React, { Component, ReactNode } from "react";
import DeviceGroup from "../../data/device/DeviceGroup";
import ScrollDrag from "../ui/scroll-drag";
import Loader from "../ui/loader";
import { Maybe } from "../../types/aliases";
import DeviceTreeLevel from "./components/device-tree-level";
import SearchFilter from "./helpers/search-filter";
import { Box } from "@material-ui/core";
import Device from "../../data/device/Device";
import { translations } from "../../generated/translationHelper";

interface Props {
  groups: DeviceGroup[];
  selectedDevice?: string;
  selectedGroup?: string;
  editMode?: boolean;
  searchFilter?: SearchFilter;
  keepSingleRootExpanded?: boolean;
  "data-testid"?: string;
  onDeviceSelect?: (device?: Device) => void;
  onGroupSelect?: (group?: DeviceGroup) => void;
}

interface State {
  expandedItemIds: string[];
}

class DeviceTree extends Component<Props, State> {
  private static storedExpandedItems: string[] = [];

  public constructor(props: Props) {
    super(props);
    const expandedItemIds = this.getExpandedItems(DeviceTree.storedExpandedItems, props);
    DeviceTree.storedExpandedItems = expandedItemIds;
    this.state = { expandedItemIds };
  }

  private get keepRootsExpanded(): boolean {
    return this.props.groups.length === 1 && !!this.props.keepSingleRootExpanded;
  }

  private getExpandedItems(newExpandedItems: string[], props: Props = this.props): string[] {
    return this.keepRootsExpanded
      ? [...new Set([...newExpandedItems, ...props.groups.map(group => group.getId())])]
      : newExpandedItems;
  }

  private handleNodeToggle = (_event: unknown, expandedNodeIds: string[]): void => {
    const expandedItemIds = this.getExpandedItems(expandedNodeIds);
    DeviceTree.storedExpandedItems = expandedItemIds;
    this.setState({ expandedItemIds });
  };

  private renderLevels(): Maybe<ReactNode> {
    if (!this.props.groups.length) {
      return (<Box m={2}>{translations.deviceDrawer.texts.noDevicesOrGroupsFound()}</Box>);
    }
    return this.props.groups.map((group: DeviceGroup, index: number) => (
      <DeviceTreeLevel
        key={group.getId()}
        group={group}
        editMode={this.props.editMode}
        searchFilter={this.props.searchFilter}
        selectedDevice={this.props.selectedDevice}
        selectedGroup={this.props.selectedGroup}
        onDeviceSelect={this.props.onDeviceSelect}
        onGroupSelect={this.props.onGroupSelect}
        hideFolderControlIcon={this.keepRootsExpanded}
        data-testid={this.props["data-testid"] ? `${this.props["data-testid"]}-group-${index}` : undefined}
      />
    ));
  }

  private renderTreeView(): JSX.Element {
    return (
      <TreeView
        expanded={this.state.expandedItemIds}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeToggle={this.handleNodeToggle}
        data-testid={this.props["data-testid"]}
      >
        {this.renderLevels()}
      </TreeView>
    );
  }

  public render(): JSX.Element {
    if (!this.props.groups) {
      return <Loader/>;
    }

    if (this.props.editMode) {
      return (
        <ScrollDrag rootClass={"tree-list"}>
          {this.renderTreeView()}
        </ScrollDrag>
      );
    } else {
      return this.renderTreeView();
    }
  }
}

export default DeviceTree;
