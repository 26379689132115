/* Copyright */

import OlMap from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import Select from "ol/interaction/Select";
import Modify from "ol/interaction/Modify";
import Snap from "ol/interaction/Snap";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import Overlay from "ol/Overlay";
import Layer from "ol/layer/Layer";
import { events } from "openlayers";

import { RailroadGroup } from "../client/groups/RailroadGroup";
import { CoordinateLocation, RailroadDevice } from "../client/devices/RailroadDevice/RailroadDevice";
import { Maybe } from "../types/aliases";
import AddLocation from "../assets/add_location-24px.svg";
import { RoadDevice } from "../client/devices/RoadDevice/RoadDevice";
import { ResourceType } from "../utils/NavigationUtils";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const proj = require("ol/proj");

const DEFAULT_CENTER: [number, number] = [25.72088, 62.24147]; // Jyväskylä
const ZOOM_MIN = 2;
const ZOOM_DEFAULT = 6;
const MARKER_ANCHOR: [number, number] = [0.5, 1];
const OVERLAY_OFFSET: [number, number] = [0, 10];
const DEFAULT_ICON_SCALE = 1.7;
const ICON_SELECTION_SCALE_FACTOR = 2;

export class MapService {
  private _map?: OlMap;
  private _layers: Map<string, Layer> = new Map();
  private _groupFeatures: Map<string, Feature> = new Map();
  private _deviceFeatures: Map<string, Feature> = new Map();
  private _source?: VectorSource;
  private _overlay?: Overlay;
  private _selectInteraction?: Select;
  private _modifyInteraction?: Modify;
  private _snapInteraction?: Snap;
  private _editMode?: boolean;
  private _newLocation?: [number, number];
  private _zoomReady?: boolean = true;

  public set editMode(editMode: Maybe<boolean>) {
    this._editMode = editMode;
  }
  
  /**
   * Create a new map.
   * @param {string} target DOM element name where map is attached.
   */
  public createMap(target: string): OlMap {
    this._map = new OlMap({
      target,
      view: new View({
        center: proj.fromLonLat(DEFAULT_CENTER),
        extent: proj.transformExtent([180, -90, -180, 90], "EPSG:4326", "EPSG:3857"),
        minZoom: ZOOM_MIN,
        zoom: ZOOM_DEFAULT,
      }),
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
    });

    const id = "TILE";
    const tileLayer = new TileLayer({
      source: new OSM(),
    });

    if (this._layers.has(id)) {
      this._map.removeLayer(this._layers.get(id)!);
    }
    this._layers.set(id, tileLayer);
    this._map.addLayer(tileLayer);

    return this._map;
  }

  /**
   * Add a listener when selecting items from map.
   * @param {(evt: ol.events.Event) => void)} listener Listener that gets fired when a change is detected.
   */
  public addSelectInteraction(listener: ((evt: ol.events.Event) => void)): void {
    this._selectInteraction = new Select({
      hitTolerance: 2,
    });
    this._map?.addInteraction(this._selectInteraction);
    this._selectInteraction.on("select", listener);
  }

  /**
   * Toggle map edit mode. In edit mode the map has a modify interaction, so that the features
   * on the map can be moved around, and a snap interaction which helps in selecting a feature.
   * 
   * @param {boolean | undefined} editMode 'true' if map is editable, 'false' otherwise.
   */
  public toggleMapEditMode(editMode?: boolean): void {
    if (!editMode) {
      if (this._map && this._modifyInteraction && this._snapInteraction && this._selectInteraction) {
        console.log("Removing modify, and snap interactions");
        this._source?.clear();
        this._map.removeInteraction(this._modifyInteraction);
        this._map.removeInteraction(this._snapInteraction);
        this._newLocation = undefined;
      }
    } else {
      if (this._map && this._source) {
        console.log("Adding modify, and snap interactions");
        this._modifyInteraction = new Modify({
          source: this._source,
        });
        this._map.addInteraction(this._modifyInteraction);
        this._snapInteraction = new Snap({
          source: this._source,
        });
        this._map.addInteraction(this._snapInteraction);

        this._source.forEachFeature((feature: Feature) => {
          // Save the changed location of the feature. This assumes
          // that there only is one feature that is moved around.
          feature.on("change", (event: events.Event): void => {
            this._newLocation = event.target.getGeometry().getCoordinates();
          });
        });
      }
    }
  }

  /**
   * Visualize devices as icons on the map (the device layer).
   * @param {(RailroadDevice | RoadDevice)[]} devices Devices to show
   * @param {boolean} showLabels True if icons should have labels
   * @param {string | undefined} selectedResourceId Currently selected resource
   */
  public async addDeviceLayer(devices: (RailroadDevice | RoadDevice)[], showLabels: boolean, selectedResourceId?: string): Promise<void> {
    const features: Feature[] = [];

    for (const device of devices) {
      const [latitude, longitude] = await device.getLocation() ?? [];

      if (latitude != null && longitude != null) {
        const feature = this._deviceFeatures.get(device.getId()) ?? new Feature({
          properties: {
            id: device.getId(),
            type: ResourceType.Device,
          },
        });
        feature.setGeometry(new Point(proj.fromLonLat([longitude, latitude])));
        feature.setId(device.getId());
        const deviceState = device.getState();
        const deviceName = deviceState?.displayName ? deviceState.displayName : device.getId();
        const icon = this._editMode ? AddLocation : device.getIcon();
        const isSelected = device.getId() === selectedResourceId;
        const style = feature.getStyle() as Maybe<Style> ?? MapService.createStyle(deviceName, showLabels, icon, isSelected);

        if (feature.getStyle() && this._editMode) {
          style.setImage(MapService.createMarkerIcon(icon, MapService.getScale(isSelected)));
        }

        const newName = MapService.getName(deviceName, showLabels);
        const newZIndex = MapService.getZIndex(isSelected);
        const newScale = MapService.getScale(isSelected);

        // Update style text if it has changed
        if (style?.getText()?.getText() !== newName) {
          style.getText()?.setText(newName);
        }

        // Update style z index if it has changed
        if (style?.getZIndex() !== newZIndex) {
          style.setZIndex(newZIndex);
        }

        // Update image scale if it has changed  
        if (style?.getImage()?.getScale() !== newScale) {
          style.getImage().setScale(newScale);
        }
        feature.setStyle(style);
        this._deviceFeatures.set(device.getId(), feature);
        features.push(feature);
        // Lazy load status icon so marker rendering does not freeze
        if (!this._editMode && RailroadDevice.instanceOf(device)) MapService.loadStatusIcon(device, feature);
      }
    }

    this.addLayer("DEVICE", features);
  }

  /**
   * Visualize groups as icons on the map.
   * @param {RailroadGroup[]} groups Groups to show
   * @param {boolean} showLabels True if icons should have labels
   * @param {string | undefined} selectedResourceId Currently selected resource
   */
  public async addGroupLayer(groups: RailroadGroup[], showLabels: boolean, selectedResourceId?: string): Promise<void> {
    const features: Feature[] = [];

    for (const group of groups) {
      const [latitude, longitude] = group.getLocation() ?? [];

      if (latitude != null && longitude != null) {
        const feature = this._groupFeatures.get(group.getId()) ?? new Feature({
          properties: {
            id: group.getId(),
            type: "group",
          },
        });
        feature.setGeometry(new Point(proj.fromLonLat([longitude, latitude])));
        feature.setId(group.getId());
        const icon = group.getIcon();
        const isSelected = group.getId() === selectedResourceId;
        const style = feature.getStyle() as Maybe<Style> ?? MapService.createStyle(group.getName(), showLabels, icon, isSelected);
        const newName = MapService.getName(group.getName(), showLabels);
        const newZIndex = MapService.getZIndex(isSelected);
        const newScale = MapService.getScale(isSelected);

        // Update style text if it has changed
        if (style?.getText()?.getText() !== newName) {
          style.getText()?.setText(newName);
        }

        // Update style z index if it has changed
        if (style?.getZIndex() !== newZIndex) {
          style.setZIndex(newZIndex);
        }

        // Update image scale if it has changed  
        if (style?.getImage()?.getScale() !== newScale) {
          style.getImage().setScale(newScale);
        }
        feature.setStyle(style);
        this._groupFeatures.set(group.getId(), feature);
        features.push(feature);
        // Lazy load status icon so marker rendering does not freeze
        MapService.loadStatusIcon(group, feature);
      }
    }

    this.addLayer("GROUP", features);
  }

  /**
   * Add an overlay to a given HTML element.
   * 
   * NOTE: In order to show the overlay on map set it's position with {@link showOverlay}
   * @param {HTMLElement} parent Element where overlay is added
   */
  public addOverlay(parent: HTMLElement): void {
    this._overlay = new Overlay({
      id: "popup",
      element: parent,
      offset: OVERLAY_OFFSET,
      positioning: "top-center",
      stopEvent: false,
    });

    this._map?.addOverlay(this._overlay);
  }

  /**
   * Hide overlay.
   */
  public hideOverlay(): void {
    this._overlay?.setPosition(undefined);
  }

  /**
   * Show overlay at the given position.
   * @param {number} longitude Overlay position longitude
   * @param {number} latitude Overlay position latitude
   */
  public showOverlay(longitude: number, latitude: number): void {
    const coordinates = proj.fromLonLat([longitude, latitude]);
    this._overlay?.setPosition(coordinates);
  }

  /**
   * Zoom map to given coordinate.
   * @param {number} zoom Chosen zoom level
   * @param {number | undefined} longitude Zoom coordinate longitude
   * @param {number | undefined} latitude Zoom coordinate latitude
   */
  public zoomMap(zoom: number, longitude?: number, latitude?: number): void {
    if (this._zoomReady) {
      this._zoomReady = false;

      if (latitude != null && longitude != null) {
        const coordinates = proj.fromLonLat([longitude, latitude]);
        this._map?.getView().animate({ center: coordinates }, { zoom }, (completed: boolean) => this._zoomReady = completed);
      } else {
        this._map?.getView().animate({ zoom }, (completed: boolean) => this._zoomReady = completed);
      }
    }
  }

  /**
   * Get current zoom level.
   */
  public getZoomLevel(): number {
    return this._map?.getView().getZoom() ?? -1;
  }

  /**
   * Force update map size.
   */
  public updateSize(): void {
    this._map?.updateSize();
  }

  /**
   * Get the new updated location of the device that was moved.
   */
  public getNewLocation(): Maybe<CoordinateLocation> {
    if (this._newLocation) {
      const coordinates = proj.toLonLat(this._newLocation);
      return [coordinates[1], coordinates[0]];
    }
  }

  /**
   * Add a layer to the map. Remove it first if it already exists on the map.
   * @param {"DEVICE" | "GROUP"} id Layer ID
   * @param {Feature[]} features Layer features
   */
  private addLayer(id: "DEVICE" | "GROUP", features: Feature[]): void {
    console.log(`Adding layer ${id}, features: ${features.length}`);
    this._selectInteraction?.getFeatures().clear();
    if (!this._source) this._source = new VectorSource();
    let featuresMap: Map<string, Feature> | undefined;
  
    if (id === "DEVICE") {
      featuresMap = this._deviceFeatures;
    } else if (id === "GROUP") {
      featuresMap = this._groupFeatures;
    }
    featuresMap?.forEach((feature) => {
      // remove old features of layer
      const oldFeature = this._source?.getFeatureById(feature.getId());
      if (oldFeature) this._source?.removeFeature(oldFeature);
    });
    this._source.addFeatures(features);
    const layer = this._layers.get(id) ?? new VectorLayer();
    layer.setSource(this._source);
    this._layers.set(id, layer);
    // existing layer instance needs to be removed before adding it again
    this._map?.removeLayer(layer);
    this._map?.addLayer(layer);
  }

  /**
   * Create a styled marker.
   * @param {string} label Resource name
   * @param {boolean} showLabels True if markers should have labels
   * @param {string} iconSrc Icon file source
   * @param {boolean} isSelected boolean, true if resource is selected on map
   */
  private static createStyle(label: string, showLabels: boolean, iconSrc: string, isSelected: boolean): Style {
    return new Style({
      image: MapService.createMarkerIcon(iconSrc, MapService.getScale(isSelected)),
      zIndex: MapService.getZIndex(isSelected),
      text: MapService.createMarkerLabel(showLabels ? label : undefined),
    });
  }

  /**
   * Creates {@link Icon} instance for marker.
   * @param {string} src Image resource source
   * @param {number} scale Scale number
   * @returns {Icon} {@link Icon}
   */
  private static createMarkerIcon(src: string, scale: number): Icon {
    return new Icon({
      anchor: MARKER_ANCHOR,
      src,
      scale,
    });
  }

  /**
   * Creates {@link Text} instance for marker label.
   * @param {string | undefined} label Text to show in marker
   * @returns {Text} {@link Text}
   */
  private static createMarkerLabel(label?: string): Text {
    return new Text({
      text: label,
      rotation: 0,  // 0: horizontal
      offsetY: 9,  // 9: slightly below marker
      offsetX: 0,  // 0: centered wrt marker
      scale: 1.4,
      stroke: new Stroke({
        color: "#000000",
      }),
    });
  }

  /**
   * Gets icon scale based on selection.
   * @param {boolean | undefined} isSelected Selection on/off
   * @returns {number}
   */
  private static getScale(isSelected?: boolean): number {
    return isSelected ? DEFAULT_ICON_SCALE * ICON_SELECTION_SCALE_FACTOR : DEFAULT_ICON_SCALE;
  }

  /**
   * Gets style z-index based on selection.
   * @param {boolean | undefined} isSelected Selection on/off
   * @returns {number}
   */
  private static getZIndex(isSelected?: boolean): number {
    return isSelected ? 1 : 0;
  }

  /**
   * Get name based on visibility.
   * @param {string} name Name to show
   * @param {boolean | undefined} isVisible Visibility on/off
   * @returns 
   */
  private static getName(name: string, isVisible?: boolean): string {
    return isVisible ? name : "";
  }

  /**
   * Loads feature's status icon asynchronously.
   * @param {RailroadDevice | RailroadGroup} resource Device or group
   * @param {Feature} feature Map {@link Feature}
   */
  private static async loadStatusIcon(resource: RailroadDevice | RailroadGroup, feature: Feature): Promise<void> {
    console.log(`Loading status icon for ${resource.getId()} `);
    const endTimestamp = Date.now();
    // -30 days from current time
    const startTimestamp = Date.now() - 30 * 24 * 60 * 60 * 1000;
    const asyncIcon = await resource.getStatusIcon({ startTimestamp, endTimestamp });
    const currentStyle = feature.getStyle() as Style;
    const newStyle = new Style({
      image: MapService.createMarkerIcon(asyncIcon, currentStyle.getImage().getScale()),
      zIndex: currentStyle.getZIndex(),
      text: currentStyle.getText(),
    });
    feature.setStyle(newStyle);
  }
}
