/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { RouteComponentProps } from "react-router-dom";
import { ResourcePathRouterProps } from "../types/routerprops";
import TimePeriod from "../data/utils/TimePeriod";
import { toNumber } from "./functions";
import { Maybe } from "../types/aliases";

export enum ResourceType {
  Device = "device",
  Group = "group"
}

export type ResourceRouterProps = RouteComponentProps<ResourcePathRouterProps>;
export enum ResourceChangeType {
  ChangedToNone = "ChangedToNone",
  ChangedToNew = "ChangedToNew",
  StayedSame = "StayedSame",
  StayedNone = "StayedNone",
  WillRestore = "WillRestore",
}

export interface Resource {
  id: string;
  type: string;
}

export interface ResourceAndTime extends Resource {
  timePeriod: Partial<TimePeriod>;
}

export function idFromProps(props: ResourceRouterProps): Maybe<string> {
  return props.match.params.id;
}

export function typeFromProps(props: ResourceRouterProps): Maybe<string> {
  return props.match.params.type;
}

export function isDeviceInProps(routerProps: ResourceRouterProps): boolean {
  return !!idFromProps(routerProps) && typeFromProps(routerProps) === ResourceType.Device;
}

export function isGroupInProps(routerProps: ResourceRouterProps): boolean {
  return !!idFromProps(routerProps) && typeFromProps(routerProps) === ResourceType.Group;
}

export function isViewResourcesEnabled(routerProps: ResourceRouterProps): boolean {
  const urlParams = new URLSearchParams(routerProps.location.search);
  return urlParams.get("viewResources") === "true";
}

/**
 * Creates a new relative path from {@link ResourceRouterProps} by either replacing or removing the resource in the path
 * @param routerProps
 * @param resource
 *    id of the new resource (if empty, old resource is simply removed)
 */
export function createRelativeResourceIdUrl(routerProps: ResourceRouterProps, resource?: Resource): string {
  const oldId = routerProps.match.params.id;
  const oldType = routerProps.match.params.type;
  const oldPath = routerProps.location.pathname;
  const basePathTemp = !oldId && !oldType ? oldPath : oldPath.substring(0, oldPath.lastIndexOf(`/${oldType}/${oldId}`));
  const basePath = basePathTemp.endsWith("/") ? basePathTemp.substring(0, basePathTemp.length - 1) : basePathTemp;
  return !resource?.id || !resource?.type ? basePath : `${basePath}/${resource.type}/${resource.id}`;
}

/**
 * Collects a {@link ResourceAndTime} object from given {@link ResourceRouterProps}
 * @param props
 */
export function parseResourceAndTime(props: ResourceRouterProps): Partial<ResourceAndTime> {
  const urlParams = new URLSearchParams(props.location.search);
  const startTimestamp = urlParams.get("startTimestamp");
  const endTimestamp = urlParams.get("endTimestamp");
  return {
    id: props.match.params.id ?? undefined,
    type: props.match.params.type ?? undefined,
    timePeriod: (startTimestamp || endTimestamp) ? {
      startTimestamp: startTimestamp ? toNumber(startTimestamp) : undefined,
      endTimestamp: endTimestamp ? toNumber(endTimestamp) : undefined,
    } : undefined,
  };
}
