/* Copyright */
import { Nullable } from "../../../types/aliases";
import DeviceState from "../../../data/device/DeviceState";
import { MainUnitHWStateProperties } from "./MainUnitHWStateProperties";


export class MainUnitHWState extends DeviceState<MainUnitHWStateProperties> {
  
  public get isServiceMode(): boolean {
    return this.reported.isServiceMode;
  }

  public set isServiceMode(status: boolean) {
    this.changedValues.isServiceMode = status;
  }

  public get displayName(): Nullable<string> {
    return this.reported.displayName;
  }

  public set displayName(name: Nullable<string>) {
    this.changedValues.displayName = name;
  }

  public revert(): void {
    this.changedValues = {};
  }
}
