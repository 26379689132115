/* Copyright */
import { Button, withStyles } from "@material-ui/core";

const CustomButton = withStyles({
  root: {
    borderRadius: 10,
    minWidth: 120,
    border: "1px solid black",
  },
})(Button);

export default CustomButton;
