/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import AWSBackend from "../backend/AWSBackend";
import DeviceGroup, { DeviceGroupParameters, DeviceId } from "./DeviceGroup";
import { Maybe } from "../../types/aliases";
import { Attribute } from "./Attribute";
import Device from "./Device";
import OrganizationIcon from "../../assets/Organization-Blue-24x24.svg";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import {
  DeviceGroupsDeleteDocument,
  DeviceGroupsDevicesAddDocument,
  DeviceGroupsDevicesRemoveDocument,
} from "../../generated/gqlDevice";
import AuthWrapper from "../auth/AuthWrapper";
import { throwGQLError } from "../utils/Utils";
import { HasEntityRelations, RelationChange } from "../utils/EntityRelationCache";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";
import AWSThing from "./AWSThing";

const ORGANIZATION_KEY = "organization";
const IOT_MAX_CHILD_GROUPS = 100;

export default class AWSThingGroup extends DeviceGroup implements HasEntityRelations {
  public readonly entityType = AWSThingGroup;
  private readonly backend: AWSBackend;
  private readonly devicesSemaphore = new PromiseSemaphore(() => this.backend.getDeviceGroupDevices(this));
  private readonly childGroupsSemaphore = new PromiseSemaphore(() => this.backend.getDeviceGroups({ parent: this }));

  public constructor(backend: AWSBackend, params: DeviceGroupParameters) {
    super(params);
    this.backend = backend;
  }

  public getName(): string {
    return AWSThingGroup.getLocalisedName(this.getAttributes(), this.getId());
  }

  public getIcon(): string {
    return OrganizationIcon;
  }

  public getOrganization(): Maybe<string> {
    return this.getAttributes()
      .find((attribute) => attribute.key === ORGANIZATION_KEY)
      ?.value;
  }

  public async getParentGroup(): Promise<Maybe<DeviceGroup>> {
    return this.parentGroupId ? this.backend.getDeviceGroup(this.parentGroupId) : undefined;
  }

  public async getGroups(): Promise<DeviceGroup[]> {
    await this.childGroupsSemaphore.guard();
    return this.unguardedGetGroups();
  }

  public async getDevices(): Promise<Device[]> {
    await this.devicesSemaphore.guard();
    return this.unguardedGetDevices();
  }

  public async addDevice(device: DeviceId | Device): Promise<void> {
    const deviceId = typeof device === "string" ? device : device.getId();
 
    const devices = await this.getDevices();

    if (devices.find(dev => dev.getId() === deviceId)) {
      console.log(`Attempted to add device '${deviceId}' to group '${this.getId()}' but it was already there!`);
      return;
    }
    
    await this.backendAddDevice(deviceId);

    const addedDevice = await this.toDevice(device);

    if (AWSThing.instanceOf(addedDevice)) {
      this.backend.entityRelationCache.link(this, addedDevice);
    } else {
      throw new Error(`Failed to add device ${deviceId} to group ${this.getId()}`);
    }
  }

  public async removeDevice(device: DeviceId | Device): Promise<void> {
    const deviceId = typeof device === "string" ? device : device.getId();

    const devices = await this.getDevices();

    if (!devices.find(dev => dev.getId() === deviceId)) {
      console.log(`Attempted to remove device '${deviceId}' from group '${this.getId()}' but it was not there!`);
      return;
    }
    
    await this.backendRemoveDevice(deviceId);

    const removedDevice = await this.toDevice(device);
    
    if (AWSThing.instanceOf(removedDevice)) {
      this.backend.entityRelationCache.unlink(this, removedDevice);
    } else {
      throw new Error(`Failed to remove device ${deviceId} from group ${this.getId()}`);
    }
  }

  public async delete(): Promise<void> {
    const organizationId = await this.getOrganizationIdForRequest();

    try {
      const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      await appSyncClient.mutate(
        DeviceGroupsDeleteDocument,
        {
          groupId: this.getId(),
          organizationId: organizationId,
        },
      );
      await this.backend.removeLocal(this);
      await this.notifyDelete();
    } catch (error) {
      console.error("Failed to remove group", error);
      throw error;
    }
  }

  public childGroupCanBeAdded(): boolean {
    return this.childGroupsSemaphore.invoked() && this.unguardedGetGroups().length < IOT_MAX_CHILD_GROUPS;
  }

  public canBeDeleted(): boolean {
    const noChildGroups = this.childGroupsSemaphore.invoked() && this.unguardedGetGroups().length === 0;
    const noDevices = this.devicesSemaphore.invoked() && this.unguardedGetDevices().length === 0;

    return noChildGroups && noDevices;
  }

  /// AWSThingGroup specific public methods

  public async onRelationChange(change: RelationChange): Promise<void> {
    if (change.ofType(AWSThing)) {
      await this.notifyDeviceChange();
    } else if (change.ofType(AWSThingGroup)) {
      await this.notifyGroupChange();
    }
  }
  
  // Private methods

  private async backendAddDevice(deviceId: string): Promise<void> {
    const organizationId = await this.getOrganizationIdForRequest();
    const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    const response = await appSyncClient.mutate(
      DeviceGroupsDevicesAddDocument,
      {
        deviceId,
        groupId: this.getId(),
        overrideDynamics: false,
        organizationId,
      },
    );

    if (response.errors || !response.data?.deviceGroupsDevicesAdd) {
      throwGQLError(response, `Failed to add device ${deviceId} to group ${this.getId()}`);
    }
  }

  private async backendRemoveDevice(deviceId: string): Promise<void> {
    const organizationId = await this.getOrganizationIdForRequest();
    const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    const response = await appSyncClient.mutate(
      DeviceGroupsDevicesRemoveDocument,
      {
        deviceId,
        groupId: this.getId(),
        organizationId,
      },
    );

    if (response.errors || !response.data?.deviceGroupsDevicesRemove) {
      throwGQLError(response, `Failed to remove device ${deviceId} from group ${this.getId()}`);
    }
  }

  private async toDevice(device: DeviceId | Device): Promise<Maybe<Device>> {
    return typeof device !== "string" ? device : await this.backend.getDevice(device);
  }

  private unguardedGetGroups(): AWSThingGroup[] {
    const groupRecords = this.backend.entityRelationCache.listEntityRecordsFor(this, AWSThingGroup);
    return groupRecords
      .filter(record => record.metadata?.parentId !== record.entity.getId())
      .map(record => record.entity);
  }

  private unguardedGetDevices(): Device[] {
    return this.backend.entityRelationCache.listFor(this, AWSThing);
  }

  private async getOrganizationIdForRequest(): Promise<string> {
    const organizationId = this.getOrganization();

    if (organizationId) {
      return organizationId;
    }
    const userOrganization = await AWSThingGroup.getCurrentUserOrganization();

    if (!userOrganization) {
      throw new Error("Could not resolve organization!");
    }
    return userOrganization;
  }

  private async notifyGroupChange(): Promise<void> {
    const groups = await this.getGroups();
    this.notifyAction(observer => observer.onGroupsChanged?.(groups, this));
  }

  private async notifyDeviceChange(): Promise<void> {
    const devices = await this.getDevices();
    this.notifyAction(observer => observer.onDevicesChanged?.(devices, this));
  }

  private async notifyDelete(): Promise<void> {
    this.notifyAction(observer => observer.onDelete?.(this));
  }

  /// STATIC METHODS

  public static instanceOf(value: unknown): value is AWSThingGroup {
    return value instanceof AWSThingGroup;
  }

  private static getLocalisedName(groupAttributes: Attribute[], defaultValue: string, language = "label_default"): string {
    return groupAttributes.find((attr: Attribute) => attr.key === language)?.value ?? defaultValue;
  }

  private static async getCurrentUserOrganization(): Promise<Maybe<string>> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
    return claims?.homeOrganizationId;
  }
}
