/* Copyright */
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceGroupParameters } from "../../../data/device/DeviceGroup";
import CustomerIcon from "../../../assets/Customer-Blue-24x24.svg";
import AWSThingGroup from "../../../data/device/AWSThingGroup";
import Factory from "../Factory/Factory";

export default class Customer extends AWSThingGroup {
  public static readonly type = "Customer";

  public constructor(backend: AWSBackend, params: DeviceGroupParameters) {
    super(backend, params);
  }

  public getIcon(): string {
    return CustomerIcon;
  }

  public async getGroups(): Promise<Factory[]> {
    const childGroups = await super.getGroups();
    const factories = childGroups.filter(Factory.instanceOf);
    return factories;
  }

  public static instanceOf(value: unknown): value is Customer {
    return value instanceof Customer;
  }
}
