/* Copyright */
import { DeviceStateProperties } from "../../../data/device/DeviceStateProperties";
import { Nullable } from "../../../types/aliases";

export type SignDirection = "A" | "B";

export function isSignDirection(signDirection: string): signDirection is SignDirection {
  return signDirection === "A" || signDirection === "B";
}

export type Binary = 0 | 1;

export function isBinary(binary: number): binary is Binary {
  return binary === 0 || binary === 1;
}

export const SIDES: [SignDirection, SignDirection] = ["A", "B"];

export class SpeedLimitSignHWStateProperties extends DeviceStateProperties {
  public monday: Nullable<string>;
  public tuesday: Nullable<string>;
  public wednesday: Nullable<string>;
  public thursday: Nullable<string>;
  public friday: Nullable<string>;
  public saturday: Nullable<string>;
  public sunday: Nullable<string>;
  public yearlyDay1: Nullable<string>;
  public yearlyDay2: Nullable<string>;
  public manualMode: Nullable<Binary>;
  public signSide: Nullable<SignDirection>;
  public sleepCycle: Nullable<number>;
  public sleepEnabled: Nullable<Binary>;
  public maxAngle: Nullable<number>;
  public lowBatLimit: Nullable<number>;

  public constructor(stateProperties?: Partial<SpeedLimitSignHWStateProperties>) {
    super(stateProperties);
    this.signSide = stateProperties?.signSide ?? null;
    this.monday = stateProperties?.monday ?? null;
    this.tuesday = stateProperties?.tuesday ?? null;
    this.wednesday = stateProperties?.wednesday ?? null;
    this.thursday = stateProperties?.thursday ?? null;
    this.friday = stateProperties?.friday ?? null;
    this.saturday = stateProperties?.saturday ?? null;
    this.sunday = stateProperties?.sunday ?? null;
    this.yearlyDay1 = stateProperties?.yearlyDay1 ?? null;
    this.yearlyDay2 = stateProperties?.yearlyDay2 ?? null;
    this.sleepEnabled = stateProperties?.sleepEnabled ?? null;
    this.maxAngle = stateProperties?.maxAngle ?? null;
    this.lowBatLimit = stateProperties?.lowBatLimit ?? null;
    this.manualMode = stateProperties?.manualMode ?? null;
    this.sleepCycle = stateProperties?.sleepCycle ?? null;
  }
}
