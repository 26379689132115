/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { HubCapsule } from "@aws-amplify/core/lib/Hub";
import { Hub } from "aws-amplify";
import AuthWrapper from "./AuthWrapper";

export type AuthEvent = "SignedIn" | "SignedUp" | "SignedOut" | "SignInFailed";
export type AuthCallback = (event: AuthEvent) => void;

export default class AuthListener {
  private removeHandle?: () => void;

  public constructor(callback: AuthCallback, notifyRetroactively?: boolean) {
    this.removeHandle = Hub.listen("auth", (data: HubCapsule) => AuthListener.listener(data, callback));

    if (notifyRetroactively) {
      // if the has authenticated before the listener is registered,
      // retroactively notify the listener creator about the situation
      AuthWrapper.isCurrentUserAuthenticated().then(authenticated => {
        if (authenticated) {
          callback("SignedIn");
        }
      });
    }
  }

  public removeListener(): void {
    this.removeHandle?.();
    this.removeHandle = undefined;
  }

  private static listener(data: HubCapsule, callback: AuthCallback): void {
    switch (data.payload.event) {
      case "signIn":
        callback("SignedIn");
        break;
      case "signUp":
        callback("SignedUp");
        break;
      case "signOut":
        callback("SignedOut");
        break;
      case "signIn_failure":
        callback("SignInFailed");
        break;
    }
  }
}
