/* Copyright */
import { Grid } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";

import { TableContent } from "..";
import { MainUnitHW } from "../../../client/devices/MainUnitHW/MainUnitHW";
import { RailroadDevice } from "../../../client/devices/RailroadDevice/RailroadDevice";
import Crossing from "../../../client/groups/Crossing/Crossing";
import Factory from "../../../client/groups/Factory/Factory";
import { RailroadGroup } from "../../../client/groups/RailroadGroup";
import { LatestData, LatestDataObserver } from "../../../data/data/LatestData";
import Device, { DeviceObserver } from "../../../data/device/Device";
import EventSet, { EventObserver } from "../../../data/events/EventSet";
import { translations } from "../../../generated/translationHelper";
import { StatusDataService } from "../../../services/StatusDataService";
import { Maybe } from "../../../types/aliases";
import CustomTable from "../../ui/custom-table";
import Loader from "../../ui/loader";

interface Props {
  resource: RailroadDevice | RailroadGroup;
}

export default function RailroadContent(props: Props): JSX.Element {
  const [mainUnit, setMainUnit] = useState<MainUnitHW | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventSets, setEventSets] = useState<EventSet[]>();
  const [tableData, setTableData] = useState<[TableContent, TableContent | undefined]>();
  const loadingTasks = useRef<number>(0);

  const deviceObserver = useRef<DeviceObserver>({
    onDeviceStateUpdated: (device: Device) => {
      console.log("Device updated", device);
      if (MainUnitHW.instanceOf(device)) setMainUnit(device);
    },
  });

  const addToLoadingQueue = (): void => {
    loadingTasks.current++;
    setIsLoading(loadingTasks.current > 0);
  };

  const removeFromLoadingQueue = (): void => {
    loadingTasks.current--;
    setIsLoading(loadingTasks.current > 0);
  };

  const resolveMainUnit = async (resource: RailroadDevice | RailroadGroup): Promise<Maybe<MainUnitHW>> => {
    if (MainUnitHW.instanceOf(resource)) {
      return resource;
    } else if (RailroadDevice.instanceOf(resource)) {
      return (await resource.getParentGroup())?.getMainUnit();
    } else if (Crossing.instanceOf(resource)) {
      return await resource.getMainUnit(); 
    }
    return undefined;
  };

  const setMainUnitData = async (resource: RailroadDevice | RailroadGroup): Promise<void> => {
    addToLoadingQueue();
    const mainUnit = await resolveMainUnit(resource);
    setMainUnit(mainUnit);
    removeFromLoadingQueue();
  };
  
  const setTableContent = async (resource: RailroadDevice | RailroadGroup): Promise<void> => {
    addToLoadingQueue();

    if (Factory.instanceOf(resource)) {
      const data = await StatusDataService.getFactoryData(resource);
      setTableData([{
        data,
        title: translations.status.texts.crossingDevices(),
        header: StatusDataService.getFactoryDataHeader(),
      }, undefined]);
    } else if (Crossing.instanceOf(resource)) {
      const { summaryRows, deviceRows } = await StatusDataService.getCrossingData(resource);      
      setTableData([
        {
          data: summaryRows,
          title: resource.getName(),
        },
        {
          data: deviceRows,
          header: StatusDataService.getCrossingDeviceDataHeader(),
          title: translations.status.texts.devices(),
        },
      ]);
    } else if (RailroadDevice.instanceOf(resource)) {
      const data = await StatusDataService.getDeviceData(resource);
      setTableData([{
        data,
        title: resource.getState()?.displayName ?? undefined,
      }, undefined]);
    }
    removeFromLoadingQueue();
  };

  const dataObserver = useRef<LatestDataObserver>({
    onDataUpdate: (latestData: LatestData): void => {
      console.log("LatestData updated", latestData);
      setTableContent(props.resource);
    },
  });

  const eventObserver = useRef<EventObserver>({
    onEventSetUpdate: (eventSet: EventSet) => {
      console.log("EventSet updated", eventSet);
      setTableContent(props.resource);
    },
  });

  const resolveEventSets = async (resource: RailroadDevice | RailroadGroup): Promise<void> => {
    addToLoadingQueue();
    const eventSets: EventSet[] = [];

    if (Factory.instanceOf(resource)) {
      const crossings = await resource.getGroups();

      for (const crossing of crossings) {
        const crossingEventSet = crossing.getEventSet();

        if (!crossingEventSet.getData()) {
          await crossingEventSet?.fetch();
        }
        eventSets.push(crossingEventSet);
      }
    } else if (Crossing.instanceOf(resource)) {
      const devices = await resource.getDevices();

      for (const device of devices) {
        const deviceEventSet = device.getEventSet();

        if (!deviceEventSet.getData()) await deviceEventSet?.fetch();
        eventSets.push(deviceEventSet);
      }
    }
    setEventSets(eventSets);
    removeFromLoadingQueue();
  };

  useEffect(() => {
    const currentEventObserver = eventObserver.current;
    eventSets?.forEach((eventSet) => eventSet.addObserver(eventObserver.current));

    return (): void => {
      eventSets?.forEach((eventSet) => eventSet.removeObserver(currentEventObserver));
    };
  }, [eventSets]);

  useEffect(() => {
    const currentDeviceObserver = deviceObserver.current;
    const currentDataObserver = dataObserver.current;
    mainUnit?.addObserver(deviceObserver.current);
    mainUnit?.getLatestData().addObserver(dataObserver.current);

    return (): void => {
      mainUnit?.removeObserver(currentDeviceObserver);
      mainUnit?.getLatestData().removeObserver(currentDataObserver);
    };
  }, [mainUnit]);

  useEffect(() => {
    setMainUnitData(props.resource);
    setTableContent(props.resource);
    resolveEventSets(props.resource);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resource]);

  const renderLoader = (): Maybe<JSX.Element> => {
    if (isLoading) {
      return <Loader />;
    }
  };

  return renderLoader() ?? (
    <Grid container direction="column" spacing={3}>
      {tableData && tableData[0]?.data.length &&
        <Grid item>
          <CustomTable
            header={tableData[0].header ?? []}
            body={tableData[0].data ?? []}
            title={tableData[0].title ?? ""}
            emptyTableText={translations.common.texts.noDataAvailable()}
          />
        </Grid>
      }
      {tableData && tableData[1]?.data.length &&
        <Grid item>
          <CustomTable
            header={tableData[1].header ?? []}
            body={tableData[1].data ?? []}
            title={tableData[1].title ?? ""}
            emptyTableText={translations.common.texts.noDataAvailable()}
          />
        </Grid>
      }
    </Grid>
  );
}
