/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ReferenceHWState } from "../ReferenceHW/ReferenceHWState";
import { SuperHWStateProperties } from "./SuperHWStateProperties";
import { Nullable } from "../../../types/aliases";

export class SuperHWState extends ReferenceHWState<SuperHWStateProperties> {
  public get ledEnabled(): Nullable<boolean> {
    return (this.deltaExists("ledEnabled") ? this.desired.ledEnabled : this.reported.ledEnabled);
  }

  public set ledEnabled(enabled: Nullable<boolean>) {
    this.desired.ledEnabled = enabled;
    this.changedValues.ledEnabled = enabled;
  }

  public get ledColor(): Nullable<string> {
    return this.deltaExists("ledColor") ? this.desired.ledColor : this.reported.ledColor;
  }

  public set ledColor(color: Nullable<string>) {
    this.desired.ledColor = color;
    this.changedValues.ledColor = color;
  }

  public static instanceOf(state: unknown): state is SuperHWState {
    return state instanceof SuperHWState;
  }
}
