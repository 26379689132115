/* Copyright */
import { Maybe } from "../../../types/aliases";
import Data from "../../../data/data/Data";
import AWSThing from "../../../data/device/AWSThing";
import DeviceState from "../../../data/device/DeviceState";
import { EventSeverity, EventState, EventType } from "../../../generated/gqlEvents";
import { ResourceStatus } from "../../ResourceStatus";
import ServiceEvent, { ServiceEventFragment } from "../../events/ServiceEvent";
import Crossing from "../../groups/Crossing/Crossing";
import TimePeriod from "../../../data/utils/TimePeriod";

// Coordinate as [latitude, longitude]
export type CoordinateLocation = [number, number];

const LOCATION_KEY = "w_location";
const BATTERY_KEY = "batt";

export enum BatteryOperated {
  True = "1",
  False = "0"
}

export abstract class RailroadDevice<TData extends Data = Data, TState extends DeviceState = DeviceState> extends AWSThing<TData, TState> {
  public getLocation(): Maybe<CoordinateLocation> {
    return this.stringToCoordinate(this.getAttribute(LOCATION_KEY));
  }

  public getNumericId(): Maybe<number> {
    const id = this.getId();
    const numericId = Number(id.substring(id.lastIndexOf("-") + 1));
    return Number.isNaN(numericId) ? undefined : numericId;
  }

  public async updateLocation(coordinate: Maybe<CoordinateLocation>): Promise<void> {
    if (coordinate) {
      await this.updateAttributes([{
        key: LOCATION_KEY,
        value: coordinate?.toString(),
      }]);
    }
  }

  public getBattery(): Maybe<string> {
    return this.getAttribute(BATTERY_KEY);
  }

  public async submitService(service: ServiceEventFragment): Promise<void> {
    try {
      const serviceEvent = new ServiceEvent(service);
      await serviceEvent.submitService();
    } catch (error) {
      console.error("submitService", error);
    }
  }

  public async getParentGroup(): Promise<Maybe<Crossing>> {
    const parent = await super.getParentGroup();
    return Crossing.instanceOf(parent) ? parent : undefined;
  }

  public async getStatus(timePeriod?: TimePeriod): Promise<ResourceStatus> {
    const eventSet = this.getEventSet(timePeriod?.startTimestamp, timePeriod?.endTimestamp);
    if (!eventSet?.getData()) await eventSet?.fetch();
    const events = eventSet?.getData();
    const error = events?.some(e => e.eventState === EventState.Active && e.severity === EventSeverity.High && e.type === EventType.Diagnostics);
    const warning = events?.some(e => e.eventState === EventState.Active && e.severity === EventSeverity.Medium && e.type === EventType.Diagnostics);

    if (error) return ResourceStatus.ERROR;
    if (warning) return ResourceStatus.WARNING;
    return ResourceStatus.NONE;
  }

  public async isInServiceMode(): Promise<boolean> {
    return (await (await this.getParentGroup())?.getMainUnit())?.getState()?.isServiceMode ?? false;
  }

  public static instanceOf(value: unknown): value is RailroadDevice {
    return value instanceof RailroadDevice;
  }

  private stringToCoordinate(input?: string): Maybe<CoordinateLocation> {
    const coordinates = input?.split(",");

    if (coordinates?.length === 2) {
      return [+coordinates[0], +coordinates[1]];
    }
  }

  public abstract getStatusIcon(timePeriod: TimePeriod): Promise<string>;
}
