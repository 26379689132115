/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { FormControl, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { ChangeEvent, Component, Fragment, ReactElement } from "react";
import { Maybe } from "../../../../types/aliases";
import Customer from "../../../../client/groups/Customer/Customer";
import Factory from "../../../../client/groups/Factory/Factory";
import Crossing from "../../../../client/groups/Crossing/Crossing";
import { Attribute } from "../../../../data/device/Attribute";
import AttributesBuilder from "../../../../data/device/AttributesBuilder";
import DeviceGroup from "../../../../data/device/DeviceGroup";
import Loader from "../../../ui/loader";
import BackendFactory from "../../../../data/BackendFactory";
import { translations } from "../../../../generated/translationHelper";
import ErrorDialog from "../../../ui/error-dialog";
import FormDialog from "../../../ui/form-dialog";

interface Props {
  parentGroup?: DeviceGroup;
  open: boolean;
  onClose: (success: boolean) => void;
}

interface State {
  isLoading: boolean;
  name?: string;
  groupType?: GroupType;
  latitude?: number;
  longitude?: number;
  crossingId?: string;
  errorMessage?: string;
}

enum GroupType {
  None,
  DeviceGroup,
  Customer,
  Factory,
  Crossing,
}

enum InputSource {
  Name,
  GroupType,
  Latitude,
  Longitude,
  CrossingId,
}

export default class AddGroupPopup extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  private isMenuItemEnabled = (type: GroupType): boolean => {
    switch (type) {
      case GroupType.DeviceGroup:
      case GroupType.Customer:
        return !Customer.instanceOf(this.props.parentGroup) && !Factory.instanceOf(this.props.parentGroup) && !Crossing.instanceOf(this.props.parentGroup);
      case GroupType.Factory:
        return Customer.instanceOf(this.props.parentGroup);
      case GroupType.Crossing:
        return Factory.instanceOf(this.props.parentGroup);
      default:
        return false;
    }
  };

  private isApplyButtonDisabled = (): boolean => {
    let disableButton = false;

    switch (this.state.groupType) {
      case GroupType.Factory:
        disableButton = !this.state.longitude || !this.state.latitude;
        break;
      case GroupType.Crossing:
        disableButton = !this.state.crossingId;
        break;
    }

    return this.state.isLoading || !this.state.name || disableButton;
  };

  private handleSubmitNewGroup = async (): Promise<void> => {
    this.setState({ isLoading: true });

    try {
      if (!this.state.name) {
        throw new Error("Missing group name");
      }
      await BackendFactory.getBackend().createDeviceGroup({
        displayName: this.state.name,
        parentGroup: this.props.parentGroup,
        attributes: this.getAttributes(),
      });
      this.props.onClose(true);
    } catch (error) {
      console.error("Failed to create group", error);
      this.setState({
        errorMessage: translations.admin.texts.failedToAddGroup(),
      });
      this.props.onClose(false);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private getAttributes(): Maybe<Required<Attribute>[]> {
    const attributesBuilder = new AttributesBuilder();

    switch (this.state.groupType) {
      case GroupType.Customer:
        attributesBuilder.setAttribute("type", Customer.type);
        break;
      case GroupType.Factory:
        attributesBuilder
          .setAttribute("type", Factory.type)
          .setAttribute("location", [this.state.latitude, this.state.longitude]);
        break;
      case GroupType.Crossing:
        attributesBuilder
          .setAttribute("type", Crossing.type)
          .setAttribute("id", this.state.crossingId);
        break;
      default:
        break;
    }

    return attributesBuilder.getAttributes();
  }

  private handleSelectionChange = (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
    const number = Number(event.target.value);

    switch (number) {
      case GroupType.DeviceGroup:
        this.setState({ groupType: GroupType.DeviceGroup });
        break;
      case GroupType.Customer:
        this.setState({ groupType: GroupType.Customer });
        break;
      case GroupType.Factory:
        this.setState({ groupType: GroupType.Factory });
        break;
      case GroupType.Crossing:
        this.setState({ groupType: GroupType.Crossing });
        break;
      default:
        this.setState({ groupType: GroupType.None });
        break;
    }
  };

  private handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, source: InputSource): void => {
    const value = event.currentTarget.value;

    switch (source) {
      case InputSource.Latitude:
        if (!isNaN(+value)) {
          this.setState({ latitude: +value });
        }
        break;
      case InputSource.Longitude:
        if (!isNaN(+value)) {
          this.setState({ longitude: +value });
        }
        break;
      case InputSource.Name:
        this.setState({ name: value });
        break;
      case InputSource.CrossingId:
        this.setState({ crossingId: value });
        break;
    }
  };

  private renderFactoryFields(): ReactElement {
    return (
      <Grid container item justifyContent="space-around" direction="column" spacing={1}>
        <Grid item container justifyContent="center"><FormLabel>{translations.common.texts.location()}</FormLabel></Grid>
        <Grid item>
          <FormGroup>
            <FormControl component="fieldset" margin="normal" fullWidth>
              <TextField
                type="number"
                variant="outlined"
                label={translations.common.data.latitude()}
                value={this.state.latitude ?? ""}
                onChange={(event): void => this.handleInputChange(event, InputSource.Latitude)}
              />
            </FormControl>
            <FormControl>
              <TextField
                type="number"
                variant="outlined"
                label={translations.common.data.longitude()}
                value={this.state.longitude ?? ""}
                onChange={(event): void => this.handleInputChange(event, InputSource.Longitude)}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>
    );
  }

  private renderCrossingFields(): ReactElement {
    return (
      <Grid item container justifyContent="space-around" direction="column" spacing={1}>
        <Grid item container justifyContent="center"><FormLabel>{translations.common.texts.crossing()}</FormLabel></Grid>
        <Grid item>
          <FormGroup>
            <FormControl>
              <TextField
                variant="outlined"
                label={translations.admin.texts.crossingId()}
                value={this.state.crossingId ?? ""}
                onChange={(event): void => this.handleInputChange(event, InputSource.CrossingId)}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>
    );
  }

  private renderGroupFields(): Maybe<ReactElement> {
    switch (this.state.groupType) {
      case GroupType.Factory:
        return this.renderFactoryFields();
      case GroupType.Crossing:
        return this.renderCrossingFields();
      default:
        return undefined;
    }
  }

  public render(): ReactElement {
    return (
      <Fragment>
        <ErrorDialog
          onClose={(): void => {
            this.setState({ errorMessage: undefined });
            this.props.onClose(false);
          }}
          errorMsg={this.state.errorMessage}
        />
        <FormDialog 
          title={translations.admin.texts.addNewGroup()}
          acceptButtonText={translations.common.buttons.add()}
          isOpen={this.props.open}
          onAccept={this.handleSubmitNewGroup}
          onCancel={(): void => this.props.onClose(false)}
          disableAccept={this.isApplyButtonDisabled()}
          maxWidth={"xs"}
        >
          <Grid container item direction="column" justifyContent="space-around" spacing={2}>
            <Grid item container justifyContent="center"><FormLabel>{translations.admin.texts.generalSettings()}</FormLabel></Grid>
            <Grid item>
              <FormGroup>
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <TextField
                    variant="outlined"
                    value={this.state.name}
                    label={translations.admin.texts.groupName()}
                    onChange={(event): void =>
                      this.setState({ name: event.currentTarget.value })}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel id="group-type-select-label">{translations.admin.texts.groupType()}</InputLabel>
                  <Select
                    labelId="group-type-select-label"
                    value={this.state.groupType != null ? this.state.groupType : GroupType.None}
                    onChange={this.handleSelectionChange}
                    variant="outlined"
                  >
                    <MenuItem value={GroupType.None} disabled><em>{translations.admin.texts.groupType()}</em></MenuItem>
                    <MenuItem value={GroupType.DeviceGroup} disabled={!this.isMenuItemEnabled(GroupType.DeviceGroup)}>{translations.common.texts.normal()}</MenuItem>
                    <MenuItem value={GroupType.Customer} disabled={!this.isMenuItemEnabled(GroupType.Customer)}>{translations.common.texts.customer()}</MenuItem>
                    <MenuItem value={GroupType.Factory} disabled={!this.isMenuItemEnabled(GroupType.Factory)}>{translations.common.texts.factory()}</MenuItem>
                    <MenuItem value={GroupType.Crossing} disabled={!this.isMenuItemEnabled(GroupType.Crossing)}>{translations.common.texts.crossing()}</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item>
              {this.renderGroupFields()}
            </Grid>
          </Grid>
          <Loader show={this.state.isLoading}/>
        </FormDialog>
      </Fragment>
    );
  }
}
