/* Copyright */
import HyperIcon from "../../../assets/router-24px.svg";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";
import { RoadDevice } from "../RoadDevice/RoadDevice";
import { SpeedLimitSignHWData } from "./SpeedLimitSignHWData";
import { SpeedLimitSignHWState } from "./SpeedLimitSignHWState";
import { SpeedLimitSignHWStateProperties } from "./SpeedLimitSignHWStateProperties";

export class SpeedLimitSignHW extends RoadDevice<SpeedLimitSignHWData, SpeedLimitSignHWState> {

  public static type = "SpeedLimitSign";
  
  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(SpeedLimitSignHW.type, backend, params);
  }
  
  public getIcon(): string {
    return HyperIcon;
  }
  
  public createState(timestamp?: number, reported?: Partial<SpeedLimitSignHWStateProperties>, desired?: Partial<SpeedLimitSignHWStateProperties>): SpeedLimitSignHWState {
    return new SpeedLimitSignHWState(this.getId(), new SpeedLimitSignHWStateProperties(reported), new SpeedLimitSignHWStateProperties(desired), timestamp);
  }

  public static instanceOf(value: unknown): value is SpeedLimitSignHW {
    return value instanceof SpeedLimitSignHW;
  }
}
